#adservioBenefitsContainer {
  box-sizing: border-box;
  margin-bottom: 92.7px;
  margin-top: 50px;
  /* display: flex;
    flex-direction: column; */
}

#adservioBenefitsContainer h1,
.adsBenefitsTitle {
  font-family: "Manrope";
  font-size: 50px !important;
  font-weight: bold;
  line-height: 1.2;
  color: var(--secondary-heavy);
  text-align: center;
  margin-top: 25px;
}

section.benefit_shapes {
  order: 0;
  position: relative;
  z-index: -1;
}

section.benefit_content {
  display: flex;
  flex-direction: column;
  flex: 1;
  order: 1;
}

section.benefit_content h3 {
  font-family: "Manrope";
  font-size: 34px;
  font-weight: bold;
  line-height: 1.2;
  color: var(--tertiary-heavy);
  white-space: pre-line;
  max-width: 700px;
}

.roleSelector {
  position: relative;
  width: 100vw;
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.roleSelector .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 70px;
  cursor: pointer;
  width: 100px;
  box-sizing: border-box;
}

.roleSelector .card:nth-of-type(4) {
  margin-right: 0;
}

.roleSelector .card .imgContainer {
  height: 110px;
}

.roleSelector .card .imgContainer img {
  height: 100%;
}

.roleSelector .card span {
  font-family: "Manrope";
  font-size: 20px;
  font-weight: bold;
  line-height: 1.56;
  text-align: center;
  color: var(--secondary-heavy);
  margin-top: 10px;
}

.benefit_container {
  position: relative;
  display: flex;
  height: 317.6px;
}

.benefit_container:nth-of-type(odd) {
  margin-top: 69px;
  width: calc(100% - 727px);
}

.benefit_container:nth-of-type(odd) .benefit_content {
  margin-left: 25.7px;
}

.benefit_container:nth-of-type(even) {
  margin-top: 85px;
  width: calc(100% - 850px);
  margin-left: 850px;
}

.benefit_container:nth-of-type(even) .benefit_content {
  order: -1;
  margin-right: -225px;
}

.benefit_container:nth-of-type(even) .benefit_content h3 {
  text-align: left;
}

.benefit_container:nth-of-type(even) .benefit_content ul li {
  margin-left: 18.2;
  padding-left: 18.2px;
  float: left;
  width: auto;
}

.benefit_container:nth-of-type(even) .benefit_content button {
  align-self: flex-start;
}

.benefit_container button {
  width: 152px;
  margin-top: 39px;
}

.benefit_content ul {
  list-style-type: disc;
  width: 100%;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
}

.benefit_content ul li {
  font-family: "Manrope";
  font-size: 20px;
  line-height: 0.96;
  text-align: left;
  color: var(--secondary-heavy);
  margin-top: 26px;
  padding-left: 30px;
  margin-left: 25.7px;
}

.benefit_content ul li::marker {
  color: var(--secondary-default);
  font-size: 25px;
  vertical-align: middle;
}

.benefit_container:nth-of-type(1) .benefit_shapes {
  height: 602.2px;
  margin-left: calc(-1 * (676.547px - 439px));
  margin-top: -138.8px;
}

.benefit_container:nth-of-type(1) .benefit_shapes img:nth-of-type(2) {
  position: absolute;
  right: 45.4px;
  margin-top: 138.8px;
  height: 270px;
  width: 233.8px;
}

.benefit_container:nth-of-type(2) .benefit_shapes {
  height: 678.2px;
  margin-right: calc(-1 * (676.547px - 612px));
  margin-top: -324.4px;
}

.benefit_container:nth-of-type(2) .benefit_shapes img:nth-of-type(2) {
  position: absolute;
  right: 152.6px;
  bottom: 84.6px;
  height: 280px;
  width: auto;
}

.benefit_container:nth-of-type(3) .benefit_shapes {
  height: 813.1px;
  margin-left: calc(-1 * (699.281px - 439px));
  margin-top: -31px;
}

.benefit_container:nth-of-type(3) .benefit_shapes img:nth-of-type(2) {
  position: absolute;
  right: 10px;
  margin-top: 73.4px;
  height: 280px;
  width: auto;
}

.benefit_container:nth-of-type(3) .benefit_shapes img:nth-of-type(3) {
  position: absolute;
  height: 245px;
  width: auto;
  left: 200px;
  top: 30px;
}

.benefit_container:nth-of-type(4) .benefit_content {
  margin-right: -100px;
}

.benefit_container:nth-of-type(4) .benefit_shapes {
  height: 679px;
  margin-right: calc(-1 * (665.067px - 498.2px));
  margin-top: -174px;
}

.benefit_container:nth-of-type(4) .benefit_shapes img:nth-of-type(2) {
  position: absolute;
  left: 118.8px;
  bottom: 272.4px;
  height: 260px;
  width: auto;
}

@media only screen and (max-width: 1750px) {
  .benefit_container:nth-child(even) {
    margin-top: 100px;
    width: 100%;
  }
  .benefit_container:nth-child(odd) {
    margin-top: 125px;
    width: calc(100% - 500px);
    margin-left: 500px;
  }
}

@media only screen and (max-width: 1500px) {
  /* .benefit_container:nth-of-type(1) {
        width: calc(100% - 439px);
    } */
  .benefit_container:nth-of-type(2),
  .benefit_container:nth-of-type(4) {
    width: calc(100% - 600px);
    margin-left: 600px;
  }
  .benefit_container:nth-of-type(1) .benefit_shapes {
    margin-left: calc(-1 * (676.547px - 239px));
  }
  .benefit_container:nth-of-type(1) .benefit_shapes img:nth-of-type(2) {
    height: 200px;
    width: auto;
  }
  .benefit_container:nth-of-type(2) .benefit_shapes {
    margin-right: calc(-1 * (676.547px - 412px));
  }
  .benefit_container:nth-of-type(2) .benefit_shapes img:nth-of-type(2) {
    position: absolute;
    right: 232.6px;
    bottom: 124.6px;
    height: 220px;
    width: auto;
  }
  .benefit_container:nth-of-type(3) .benefit_shapes {
    margin-top: -150px;
    margin-left: calc(-1 * (699.281px - 239px));
  }
  .benefit_container:nth-of-type(3) .benefit_shapes img:nth-of-type(2) {
    margin-top: 173.4px;
    height: 180px;
    width: auto;
  }
  .benefit_container:nth-of-type(3) .benefit_shapes img:nth-of-type(3) {
    height: 145px;
    left: 400px;
    top: 170px;
  }
  .benefit_container:nth-of-type(4) .benefit_shapes {
    margin-right: calc(-1 * (665.067px - 298.2px));
  }
  .benefit_container:nth-of-type(4) .benefit_shapes img:nth-of-type(2) {
    position: absolute;
    left: 118.8px;
    bottom: 272.4px;
    height: 190px;
    width: auto;
  }
}

@media only screen and (max-width: 1350px) {
  .benefit_container:nth-child(even) {
    width: calc(100% - 127px);
  }
  .benefit_container:nth-child(odd) {
    width: calc(100% - 500px);
    margin-left: 500px;
  }
  .benefit_container:nth-of-type(1) {
    width: calc(100% - 350px);
  }
}

@media only screen and (max-width: 1250px) {
  .benefit_container {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .benefit_container:nth-of-type(2n + 0) {
    width: calc(100% - 350px) !important;
    margin-left: 350px !important;
  }
  .benefit_container .benefit_content h3 {
    font-size: 34px;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
  }
  .benefit_container:nth-of-type(2n + 1) .benefit_content {
    margin-right: 100px;
  }
  /* .benefit_container:nth-of-type(2n + 0) .benefit_content {
    margin-left: 30px;
  }
  .benefit_container:nth-of-type(2n + 0) .benefit_content h3 {
    text-align: left;
    width: fit-content;
    align-self: flex-end;
  } */
  .benefit_container:nth-of-type(2) .benefit_content {
    margin-right: -200px;
  }
  .benefit_container:nth-of-type(4) .benefit_content {
    margin-right: -50px;
  }
  .benefit_container:nth-of-type(4) .benefit_content br {
    display: block !important;
  }
  .benefit_container:nth-of-type(2n + 1) .benefit_content ul {
    margin-top: 23.6px;
  }
  .benefit_container:nth-of-type(2) .benefit_content ul {
    margin-top: 19.5px;
  }
  .benefit_container:nth-of-type(4) .benefit_content ul {
    margin-top: 5.9px;
  }
  /* .benefit_container:nth-of-type(2n + 0) .benefit_content ul {
    list-style-position: inside;
  } */
  /* .benefit_container:nth-of-type(2n + 0) .benefit_content ul li {
    margin-top: 21px;
    width: 100%;
    text-align: right;
  } */
  .benefit_container .benefit_content ul li:nth-of-type(1) {
    margin-top: 0;
  }
  .benefit_container .benefit_content:nth-of-type(2n + 1) ul li:nth-of-type(3) {
    margin-top: 21px;
  }
  .benefit_container .benefit_content button {
    margin-top: 39px;
  }
  .benefit_container:nth-of-type(1) .benefit_shapes {
    margin-left: calc(-1 * (676.547px - 233.3px));
  }
  .benefit_container:nth-of-type(1) .benefit_shapes img:nth-of-type(2) {
    height: 270px;
    width: auto;
    margin-right: 71.1px;
    right: 0;
  }
  .benefit_container:nth-of-type(2) .benefit_shapes {
    margin-right: calc(-1 * (676.547px - 432.5px));
  }
  .benefit_container:nth-of-type(2) .benefit_shapes img:nth-of-type(2) {
    width: auto;
    height: 267.8;
  }
  .benefit_container:nth-of-type(3) .benefit_shapes {
    margin-left: calc(-1 * (699.281px - 210px));
  }
  .benefit_container:nth-of-type(3) .benefit_shapes img:nth-of-type(2) {
    width: auto;
    height: 271.7px;
    right: 25.5px;
  }
  .benefit_container:nth-of-type(3) .benefit_shapes img:nth-of-type(3) {
    display: none;
  }
  .benefit_container:nth-of-type(4) .benefit_shapes {
    margin-right: calc(-1 * (665.067px - 319px));
    margin-top: -250px;
  }
  .benefit_container:nth-of-type(4) .benefit_shapes img:nth-of-type(2) {
    width: auto;
    height: 260px;
    left: 100px;
  }
}

@media only screen and (max-width: 1150px) {
  .benefit_container:nth-of-type(2n + 0) {
    width: calc(100% - 200px) !important;
    margin-left: 200px !important;
  }
}

@media only screen and (max-width: 1050px) {
  .benefit_container:nth-of-type(2n + 0) {
    width: calc(100% - 100px) !important;
    margin-left: 100px !important;
  }
}

@media only screen and (max-width: 965px) {
  h3 br {
    display: none;
  }
  .benefit_container:nth-of-type(2n + 0) .benefit_content {
    width: calc(100% - 0px) !important;
    margin-left: 0px !important;
  }
  .benefit_container:nth-of-type(3) .benefit_content {
    margin-right: 50px;
  }
}

@media only screen and (max-width: 860px) {
  .benefit_container {
    display: flex;
    height: fit-content !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 97.6px 0 0 0 !important;
    width: 100% !important;
  }
  .benefit_container:nth-of-type(2n + 0) {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .benefit_container .benefit_shapes {
    position: static;
    width: fit-content;
    z-index: 0;
    order: -2;
    height: fit-content !important;
    margin: 0 !important;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .benefit_container .benefit_shapes img:nth-of-type(1) {
    display: none;
  }
  .benefit_container .benefit_shapes img:nth-of-type(2),
  .benefit_container .benefit_shapes img:nth-of-type(3) {
    position: static !important;
    margin: 0 !important;
  }
  .benefit_container .benefit_shapes img:nth-of-type(3) {
    order: -1;
    margin-top: 10px !important;
    margin-right: -40px !important;
  }
  .benefit_container .benefit_content,
  .benefit_container:nth-of-type(2n + 0) .benefit_content {
    order: 0;
    margin: 25.2px 0 0 0 !important;
    width: calc(100% - 80px) !important;
    justify-content: center;
    align-items: center;
  }
  .benefit_container .benefit_content h3 {
    text-align: center !important;
    margin-left: 0 !important;
    width: 100% !important;
  }
  .benefit_container .benefit_content h3 span {
    color: var(--dark-slate-blue);
  }
  .benefit_container .benefit_content h3 br {
    display: none;
  }
  .benefit_container .benefit_content ul {
    list-style-position: outside;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    float: left !important;
    width: 100%;
  }
  .benefit_container .benefit_content ul li {
    width: 80% !important;
    float: left !important;
    margin-left: 10% !important;
    padding: 0 !important;
    text-align: left !important;
  }
  .benefit_container .benefit_content button {
    align-self: center !important;
  }
}

@media only screen and (max-width: 700px) {
  #adservioBenefitsContainer h1,
  .adsBenefitsTitle {
    font-size: 36px !important;
    line-height: 1.54;
  }
  .benefit_container:nth-of-type(1) {
    margin: 31.9px 0 0 0 !important;
  }
  .benefit_container:nth-of-type(1) .benefit_shapes img:nth-of-type(2) {
    height: 164px !important;
  }
  .benefit_container:nth-of-type(2) .benefit_shapes img:nth-of-type(2) {
    height: 149.7px !important;
  }
  .benefit_container:nth-of-type(3) .benefit_shapes img:nth-of-type(2) {
    height: 115px !important;
  }
  .benefit_container .benefit_shapes img:nth-of-type(3) {
    display: block !important;
    height: 96.3px !important;
    margin-right: -20px !important;
    margin-top: -15px !important;
  }
  .benefit_container:nth-of-type(4) .benefit_shapes img:nth-of-type(2) {
    height: 137px !important;
  }
  .benefit_container .benefit_content,
  .benefit_container:nth-of-type(2n + 0) .benefit_content {
    width: calc(100% - 40px) !important;
  }
  .benefit_container .benefit_content h3 {
    text-align: center !important;
    font-size: 26px;
    font-weight: bold;
    line-height: 1.15;
    text-align: center;
    color: var(--tertiary-heavy);
  }
  .benefit_container .benefit_content ul {
    width: 100% !important;
  }
  .benefit_container .benefit_content ul li::marker {
    padding-left: 0 !important;
    font-size: 16px;
  }
  .benefit_container .benefit_content ul li {
    font-size: 16px;
    line-height: 1.5;
  }
}

@media only screen and (max-width: 620px) {
  .roleSelector::-webkit-scrollbar {
    display: none;
  }
  .roleSelector {
    -ms-overflow-style: none;
    scrollbar-width: none;
    justify-content: flex-start;
    overflow-x: scroll;
  }
  .roleSelector .card:nth-of-type(1) {
    margin-left: calc(50vw - 50px);
  }
  .roleSelector .card:nth-of-type(4) {
    border-right: calc(50vw - 50px) solid transparent;
    width: max-content;
  }
}

@media only screen and (max-width: 500px) {
  #adservioBenefitsContainer h1,
  .adsBenefitsTitle {
    font-size: 26px !important;
    line-height: 1.54;
  }
}
