#schoolDetails {
  width: 800px;
  height: 700px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 120px;
}
#schoolDetails img:nth-of-type(1) {
  position: absolute;
  width: 800px;
  margin: 0;
  z-index: -1;
}
#schoolDetails img:nth-of-type(2) {
  height: 146px;
  width: 800px;
  margin: 0;
  z-index: -1;
  margin-top: 68px;
}
#schoolDetails h1 {
  margin: 0 auto;
  margin-top: 18px;
  max-width: 600px;
  font-family: "Manrope";
  font-size: 34px;
  line-height: 1.2;
  text-align: center;
  color: var(--tertiary-heavy);
  width: 100%;
}
#schoolDetails form {
  width: 300px;
  margin: 45px auto 5px auto;
  display: flex;
  flex-direction: column;
}

#schoolDetails form label {
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--secondary-heavy);
  margin-bottom: 3px;
}
#schoolDetails form .form-row {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  margin-bottom: 16px;
}

#schoolDetails form input {
  outline: none;
  border: solid 1px var(--secondary-light);
  box-sizing: border-box;
  font-family: "Open Sans", "Lucida Sans Unicode", Helvetica, sans-serif;
  font-size: 12px;
}
#schoolDetails form .form-row input {
  width: 136.8px;
  padding-left: 10px;
}
 #schoolDetails form .comp-select {
  background-color: var(--tertiary-ultralight);
  font-family: "Open Sans", "Lucida Sans Unicode", Helvetica, sans-serif;
  font-size: 12px;
}

#schoolDetails form .comp-select[class$="-container"] {
  font-size: 12px;
}
#schoolDetails form .comp-select[class$="-container"] > div[class$="-control"] {
  padding-left: 4px;
}
#schoolDetails
  form
  .comp-select[class$="-container"]
  > div[class$="-control"]
  > div:first-child {
  font-size: 12px;  
}

#schoolDetails form .comp-select.select-country {
  width: 140px;
}
#schoolDetails form .comp-select.select-city {
  width: 140px;
}
#schoolDetails form .comp-select.select-school {
  width: 287px;
}

#schoolDetails form .form__input-error {
  text-align: left;
  font-size: 12px;
  color: var(--destructive-accent);
  margin-top: 3px;
  font-family: "Open Sans";
}
#schoolDetails form button.primary {
  z-index: 0;
}

#schoolDetails form > input {
  width: 288px;
}
#schoolDetails form > p {
  width: calc(100% - 10.5px);
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 1.2;
  text-align: right;
  color: var(--main-default);
  margin-top: 10px;
  cursor: pointer;
}
#schoolDetails form button {
  align-self: center;
  margin-top: 25px;
}
#schoolDetails > p:nth-of-type(1) {
  margin-left: 50%;
  transform: translateX(-50%);
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 1.4;
  text-align: left;
  color: var(--tertiary-heavy);
  width: 300px;
  margin-top: 24px;
}
#schoolDetails > p:nth-of-type(1) span {
  font-size: 14px;
  font-weight: bold;
}
#schoolDetails form.secondForm {
  margin-top: 5px;
}
#schoolDetails form.secondForm .form-row {
  margin-top: 24px;
  margin-bottom: 0;
}
@media only screen and (max-width: 900px) {
  #schoolDetails {
    width: 600px;
    height: 600px;
    margin-top: 50px;
    margin-bottom: 80px;
  }
  #schoolDetails img:nth-of-type(1) {
    width: 600px;
  }
  #schoolDetails img:nth-of-type(2) {
    height: 120px;
    width: 600px;
    margin-top: 38px;
  }
  #schoolDetails h1 {
    margin: 0 auto;
    margin-top: 18px;
    font-size: 30px;
    width: 410px;
  }
  #schoolDetails h1 br {
    display: none;
  }
  #schoolDetails form {
    width: 300px;
    margin: 25px auto 5px auto;
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 610px) {
  #schoolDetails {
    width: 100vw;
    height: max-content;
    margin-top: 30px;
  }
  #schoolDetails img:nth-of-type(1) {
    display: none;
  }
  #schoolDetails img:nth-of-type(2) {
    height: 120px;
    width: 100vw;
    margin-top: 10px;
  }
  #schoolDetails h1 {
    margin: 0 auto;
    margin-top: 18px;
    font-size: 30px;
    width: 92%;
  }
  #schoolDetails h1 br {
    display: none;
  }
  #schoolDetails form {
    width: 300px;
    margin: 25px auto 5px auto;
    display: flex;
    flex-direction: column;
  }
}
