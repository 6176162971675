.packages {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.packages__title {
    padding: 0px 60px;
    margin: 32px 0px 41.2px 0px;
    font-family: "Manrope-Bold";
    font-size: 50px;
    line-height: 1.2;
    text-align: center;
    color: var(--secondary-heavy);
}


.packages__scrollable__div {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 330px;
}

.packages__scrollable__div::after {
    content: "";
    position: absolute;
    bottom: 0px;
    background-color: var(--secondary-light);
    height: 1px;
    width: 100%;
}

.packages__scrollable__div>div {
    cursor: pointer;
    height: 125px;
    width: 121.8px;
}

.packages__selector {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.packages__selector img {
    height: 60.3px;
}

.packages__selector h2 {
    margin: 11.7px 0px 25px 0px;
    font-family: "Manrope-Bold";
    font-size: 20px;
    line-height: 1.56;
    text-align: center;
    color: var(--main-default);
}

.packages__selector--active h2 {
    color: var(--secondary-heavy) !important;
}

.packages__selector--active::after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 4px;
    background-color: var(--secondary-heavy);
}

.packages__cards__container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.packages__cards {
    display: flex;
    gap: 20px;
}

.packages__cards__bg {
    z-index: -2;
    position: absolute;
    bottom: 0px;
}

.packages__card {
    position: relative;
    width: 331px;
    min-height: 511px;
    padding: 40px 26px 111.7px 26px;
    box-sizing: border-box;
    border-radius: 15px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    background-color: var(--tertiary-ultralight);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.packages__card__arrow {
    display: none;
}

.packages__card__bottom {
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.packages__card__squaredots {
    position: absolute;
    z-index: -1;
    left: -165px;
    bottom: -21px;
}

.packages__card h3 {
    font-family: "Manrope-Bold";
    font-size: 20px;
    line-height: 1.56;
    text-align: center;
    color: var(--tertiary-heavy);
}

.packages__card p {
    font-family: "Manrope";
    font-size: 14px;
    text-align: left;
    color: var(--tertiary-heavy);
}

.packages__card h4 {
    font-family: "Manrope-Bold";
    font-size: 26px;
    line-height: 1.35;
    text-align: center;
    color: var(--tertiary-heavy);
}

.packages__card h4 span {
    font-size: 34px;
}

.packages__card h5 {
    font-family: "Manrope-SemiBold";
    font-size: 14px;
    text-align: center;
    color: var(--tertiary-heavy);
}

.packages__card::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 15px;
    border-radius: 15px 15px 0px 0px;
    background-color: var(--theme-color, var(--cyan-500));
}

.packages__card__benefits {
    width: 279px;
    display: flex;
    flex-direction: column;
    gap: 12.1px;
}

.packages__card__bottom__gradient {
    display: none;
}

.packages__card__benefit {
    display: flex;
    gap: 7.8px;
}

.packages__card__benefit p {
    font-family: "Manrope";
    font-size: 14px;
    color: var(--tertiary-heavy);
    line-height: normal;
}

.packages__card__benefit img {
    margin-top: 4.9px;
    width: 11.1px;
    height: 9.7px;
}


.packages__card__separator {
    width: 48px;
    height: 0.5px;
    background-color: var(--secondary-default);
}

.packages__card__bottom__separator {
    position: absolute;
    bottom: 89.3px;
}


.packages__card__button {
    cursor: pointer;
    width: 160px;
    height: 40px;
    padding: 9px 41px;
    background-color: var(--main-default);
    border: none;
    border-radius: 24px;
    box-sizing: border-box;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    color: var(--tertiary-ultralight);
    position: absolute;
    bottom: 26px;
}

.txt-center {
    text-align: center !important;
}


.packages__card--light--purple::before {
    background-color: #9b8afb;
}

.packages__card--dark--purple::before {
    background-color: #7a5af8;
}

.packages__card--light--blue::before {
    background-color: #528bff;
}

.packages__card--dark--blue::before {
    background-color: #2970ff;
}

.packages__card--light--green::before {
    background-color: #2ed3b7;
}

.packages__card--dark--green::before {
    background-color: #15b79e;
}

.packages__subtitle {
    padding: 0px 20px;
    margin: 20.6px 0px 25px;
    font-family: "Manrope";
    font-size: 26px;
    text-align: center;
    color: var(--tertiary-heavy);
}

.packages__subtitle span {
    font-family: "Manrope-Bold";
}

.packages__bottom__link {
    margin: 26px 0px 34px 0px;
    font-family: "Manrope-Bold";
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: var(--dark-slate-blue);
    padding: 0px 60px;
}

.packages__bottom__link span,
.packages__bottom__link a {
    color: var(--main-default);
    text-decoration: underline;
    cursor: pointer;
}

.packages__card__amount__container {
    display: flex;
    align-items: center;
    gap: 13px;
}

.packages__card__amount__container>p:nth-of-type(1) {
    width: 37px;
}

.packages__card__amount__selectors {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 2px;
    border-radius: 20px;
    border: 1px solid #707070;
}

.packages__card__amount__selector {
    width: 69px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30.6px;
    border-radius: 20px;
}

.packages__card__amount__selector p {
    color: var(--tertiary-heavy);
}

.schools__card--2 .packages__card__amount__selector--active {
    background-color: #7a5af8;
}

.teachers__card--1 .packages__card__amount__selector--active {
    background-color: #528bff;
}

.packages__card__amount__selector--active p {
    color: var(--tertiary-ultralight);
}

.solutions h1 {
    font-family: "Manrope-Bold";
    font-size: 34px;
    text-align: center;
    color: var(--tertiary-heavy);
    margin: 43px 0px;
}

.solutions {
    margin: 0px 0px 83.7px 0px;
}



/* CARDS MARGINS */

.vat-included--1 p {
    text-align: center;
    margin-bottom: -35px;
    color: gray;
    font-size: 14px;
}

.vat-included--2 p {
    text-align: center;
    color: gray;
    font-size: 14px;
}


.packages__card>p:nth-of-type(1) {
    margin-bottom: 18px;
}

.packages__card h3 {
    margin-bottom: 2px;
}

.packages__card h4 {
    margin-bottom: 9px;
}

.packages__card h5 {
    width: 210px;
    font-family: "Manrope-Bold";
    margin-bottom: 20px;

}

@media only screen and (max-width:1340px) {
    .packages__title {
        padding: 0px 40px;
        margin: 25.9px 0px 26.4px 0px;
        font-size: 34px;
        line-height: 1.32;
    }

    .packages__subtitle {
        margin: 25.5px 0px 25px 0px;
    }

    .packages__cards__bg {
        width: 1340px;
    }

    .packages__card__squaredots {
        width: 232.3px;
        left: -117px;
        bottom: 20px;
    }

    .packages__bottom__link {
        padding: 0px 40px;
    }
}

@media only screen and (max-width:750px) {
    .packages__title {
        padding: 0px 20px;
        margin: 21.6px 0px 17.4px 0px;
        font-size: 26px;
        line-height: 1.23;
    }

    .packages__subtitle {
        margin: 22px 0px 22.5px 0px;
    }

    .packages__card__squaredots {
        display: none;
    }

    .packages__cards {
        gap: 21.7px;
        flex-direction: column;
    }

    .packages__bottom__link {
        font-size: 16px;
        line-height: 1.38;
        padding: 0px 20px;
        margin: 38.9px 0px 36.2px 0px;
    }

    .packages__cards__bg {
        width: auto;
        height: 728.3px !important;
    }

    .packages__card {
        min-height: 510;
    }

    .packages__card--closed-350 {
        overflow: hidden !important;
        min-height: 350px;
        max-height: 350px;
    }

    .packages__card--closed-310 {
        overflow: hidden !important;
        min-height: 310px;
        max-height: 310px;
    }

    .packages__card--closed-350 .packages__card__arrow,
    .packages__card--closed-310 .packages__card__arrow {
        display: block;
        position: absolute;
        bottom: 84.3px;
    }

    .packages__card--closed-350 .packages__card__bottom__separator,
    .packages__card--closed-310 .packages__card__bottom__separator {
        display: none;
    }

    .packages__card--closed-350 .packages__card__bottom,
    .packages__card--closed-310 .packages__card__bottom {
        background-color: var(--tertiary-ultralight);
        height: 106px;
        width: 100%;
    }

    .packages__card--closed-350 .packages__card__bottom__gradient,
    .packages__card--closed-310 .packages__card__bottom__gradient {
        display: block;
        position: absolute;
        bottom: 96px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) -32%, var(--tertiary-ultralight) 50%);
        height: 33px;
        width: 100%;
    }

    .packages__card--closed-310 h3 {
        margin-bottom: 20px;
    }

}

@media only screen and (max-width:538px) {

    .scrollContainer {
        width: 70vw;
        overflow-x: scroll;
        display: flex;
        justify-content: center;
    }

    .scrollContainer::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

}