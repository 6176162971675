/*********** colors ***************/

:root {
  --robins-egg-blue: #98dbf9;
  --dark-slate-blue: #34296e;
  --tertiary-ultralight: #fff;
  --destructive-default: #ff6c6c;
  --destructive-accent: #f94848;
  --destructive-light: #efafaf;
  --destructive-background: #f7e8e8;
  --confirmation-accent: #8bc53f;
  --action-default: #ffb000;
  --confirmation-light: #e0f4c1;
  --action-accent: #ff9000;
  --action-light: #efbc7f;
  --action-background: #f6e8d9;
  --main-light: #7bc6db;
  --main-accent: #0f92ba;
  --main-heavy: #044458;
  --main-default: #2ea5dc;
  --main-ultralight: #c9e9f2;
  --main-background: #f5f7ff;
  --secondary-accent: #777;
  --secondary-default: #999;
  --secondary-heavy: #333;
  --secondary-light: #ccc;
  --secondary-ultralight: #efefef;
  --tertiary-heavy: #000;
  --secondary-background: #f9f9f9;
  --pumpkin-orange: #f97c00;
  --macaroni-and-cheese: #f7c52f;
  --turquoise-blue: #05a7d2;
  --turquoise-blue-two: #00a4d0;
  --turquoise-blue-three: #00adef;
  --purpley-pink: #c63fcd;

  --gray-50: #F2F4F7;
  --gray-100: #EAECF0;
  --gray-200: #D0D5DD;
  --gray-300: #98A2B3;
  --gray-400: #667085;
  --gray-500: #475467;
  --gray-600: #344054;
  --gray-700: #1D2939;
  --gray-800: #101828;
  --gray-900: #101828;

  --red-50: #FFF5F5;
  --red-100: #FED7D7;
  --red-200: #FEB2B2;
  --red-300: #FC8181;
  --red-400: #F56565;
  --red-500: #E53E3E;
  --red-600: #C53030;
  --red-700: #9B2C2C;
  --red-800: #822727;
  --red-900: #63171B;

  --orange-50: #FFFAF0;
  --orange-100: #FEEBC8;
  --orange-200: #FBD38D;
  --orange-300: #F6AD55;
  --orange-400: #ED8936;
  --orange-500: #DD6B20;
  --orange-600: #C05621;
  --orange-700: #9C4221;
  --orange-800: #7B341E;
  --orange-900: #652B19;

  --yellow-50: #FFFFF0;
  --yellow-100: #FEFCBF;
  --yellow-200: #FAF089;
  --yellow-300: #F6E05E;
  --yellow-400: #ECC94B;
  --yellow-500: #D69E2E;
  --yellow-600: #B7791F;
  --yellow-700: #975A16;
  --yellow-800: #744210;
  --yellow-900: #5F370E;

  --green-50: #F0FFF4;
  --green-100: #C6F6D5;
  --green-200: #9AE6B4;
  --green-300: #68D391;
  --green-400: #48BB78;
  --green-500: #38A169;
  --green-600: #2F855A;
  --green-700: #276749;
  --green-800: #22543D;
  --green-900: #1C4532;

  --neueGreen-50: #F0FFF4;
  --neueGreen-100: #C6F6D5;
  --neueGreen-200: #9AE6B4;
  --neueGreen-300: #68D391;
  --neueGreen-400: #48BB78;
  --neueGreen-500: #38A169;
  --neueGreen-600: #2F855A;
  --neueGreen-700: #276749;
  --neueGreen-800: #22543D;
  --neueGreen-900: #1C4532;

  --teal-50: #E6FFFA;
  --teal-100: #B2F5EA;
  --teal-200: #81E6D9;
  --teal-300: #4FD1C5;
  --teal-400: #38B2AC;
  --teal-500: #319795;
  --teal-600: #2C7A7B;
  --teal-700: #285E61;
  --teal-800: #234E52;
  --teal-900: #1D4044;

  --blue-50: #F0F9FF;
  --blue-100: #E0F2FE;
  --blue-200: #B9E6FE;
  --blue-300: #7CD4FD;
  --blue-400: #36BFFA;
  --blue-500: #0BA5EC;
  --blue-600: #0086C9;
  --blue-700: #026AA2;
  --blue-800: #065986;
  --blue-900: #0B4A6F;

  --cyan-50: #EDFDFD;
  --cyan-100: #C4F1F9;
  --cyan-200: #9DECF9;
  --cyan-300: #76E4F7;
  --cyan-400: #0BC5EA;
  --cyan-500: #00B5D8;
  --cyan-600: #00A3C4;
  --cyan-700: #0987A0;
  --cyan-800: #086F83;
  --cyan-900: #065666;

  --purple-50: #FAF5FF;
  --purple-100: #E9D8FD;
  --purple-200: #D6BCFA;
  --purple-300: #B794F4;
  --purple-400: #9F7AEA;
  --purple-500: #805AD5;
  --purple-600: #6B46C1;
  --purple-700: #553C9A;
  --purple-800: #44337A;
  --purple-900: #322659;

  --pink-50: #FFF5F7;
  --pink-100: #FED7E2;
  --pink-200: #FBB6CE;
  --pink-300: #F687B3;
  --pink-400: #ED64A6;
  --pink-500: #D53F8C;
  --pink-600: #B83280;
  --pink-700: #97266D;
  --pink-800: #702459;
  --pink-900: #521B41;

  --moss-50: #FAFDF7;
  --moss-100: #E6F4D7;
  --moss-200: #CEEAB0;
  --moss-300: #ACDC79;
  --moss-400: #86CB3C;
  --moss-500: #669F2A;
  --moss-600: #4F7A21;
  --moss-700: #3F621A;
  --moss-800: #335015;
  --moss-900: #2B4212;

  --lightGreen-50: #F3FEE7;
  --lightGreen-100: #E3FBCC;
  --lightGreen-200: #D0F8AB;
  --lightGreen-300: #A6EF67;
  --lightGreen-400: #85E13A;
  --lightGreen-500: #66C61C;
  --lightGreen-600: #4BA30D;
  --lightGreen-700: #3B7C0F;
  --lightGreen-800: #326212;
  --lightGreen-900: #2B5314;

  --neueGreen-50: #EDFCF2;
  --neueGreen-100: #D3F8DF;
  --neueGreen-200: #AAF0C4;
  --neueGreen-300: #73E2A3;
  --neueGreen-400: #3CCB7F;
  --neueGreen-500: #16B364;
  --neueGreen-600: #099250;
  --neueGreen-700: #087443;
  --neueGreen-800: #095C37;
  --neueGreen-900: #084C2E;

  --neueBlue-50: #EFF8FF;
  --neueBlue-100: #D1E9FF;
  --neueBlue-200: #B2DDFF;
  --neueBlue-300: #84CAFF;
  --neueBlue-400: #53B1FD;
  --neueBlue-500: #2E90FA;
  --neueBlue-600: #1570EF;
  --neueBlue-700: #175CD3;
  --neueBlue-800: #1849A9;
  --neueBlue-900: #194185;

  --darkBlue-50: #EFF4FF;
  --darkBlue-100: #D1E0FF;
  --darkBlue-200: #B2CCFF;
  --darkBlue-300: #84ADFF;
  --darkBlue-400: #528BFF;
  --darkBlue-500: #2970FF;
  --darkBlue-600: #155EEF;
  --darkBlue-700: #004EEB;
  --darkBlue-800: #0040C1;
  --darkBlue-900: #00359E;

  --indigo-50: #EEF4FF;
  --indigo-100: #E0EAFF;
  --indigo-200: #C7D7FE;
  --indigo-300: #A4BCFD;
  --indigo-400: #8098F9;
  --indigo-500: #6172F3;
  --indigo-600: #444CE7;
  --indigo-700: #3538CD;
  --indigo-800: #2D31A6;
  --indigo-900: #2D3282;

  --violet-50: #F5F3FF;
  --violet-100: #ECE9FE;
  --violet-200: #DDD6FE;
  --violet-300: #C3B5FD;
  --violet-400: #A48AFB;
  --violet-500: #875BF7;
  --violet-600: #7839EE;
  --violet-700: #6927DA;
  --violet-800: #5720B7;
  --violet-900: #491C96;

  --fuchsia-50: #FDF4FF;
  --fuchsia-100: #FBE8FF;
  --fuchsia-200: #F6D0FE;
  --fuchsia-300: #EEAAFD;
  --fuchsia-400: #E478FA;
  --fuchsia-500: #D444F1;
  --fuchsia-600: #BA24D5;
  --fuchsia-700: #9F1AB1;
  --fuchsia-800: #821890;
  --fuchsia-900: #6F1877;

  --rose-50: #FFF5F7;
  --rose-100: #FED7E2;
  --rose-200: #FBB6CE;
  --rose-300: #F687B3;
  --rose-400: #ED64A6;
  --rose-500: #D53F8C;
  --rose-600: #B83280;
  --rose-700: #97266D;
  --rose-800: #702459;
  --rose-900: #521B41;

  --darkOrange-50: #FFF4ED;
  --darkOrange-100: #FFE6D5;
  --darkOrange-200: #FFD6AE;
  --darkOrange-300: #FF9C66;
  --darkOrange-400: #FF692E;
  --darkOrange-500: #FF4405;
  --darkOrange-600: #E62E05;
  --darkOrange-700: #BC1B06;
  --darkOrange-800: #97180C;
  --darkOrange-900: #771A0D;

  --neueOrange-50: #FEF6EE;
  --neueOrange-100: #FDEAD7;
  --neueOrange-200: #F9DBAF;
  --neueOrange-300: #F7B27A;
  --neueOrange-400: #F38744;
  --neueOrange-500: #EF6820;
  --neueOrange-600: #E04F16;
  --neueOrange-700: #B93815;
  --neueOrange-800: #932F19;
  --neueOrange-900: #772917;

  --warning-500: #F79009;
  --warning-600: #DC6803;
  --warning-700: #B54708;
}

/*********** fonts ************************/

@font-face {
  font-family: 'Manrope';
  src: url(./assets/fonts/Manrope-VariableFont_wght.ttf);
}

@font-face {
  font-family: 'Manrope-Bold';
  src: url(./assets/fonts/static/Manrope-Bold.ttf);
}

@font-face {
  font-family: 'Manrope-ExtraBold';
  src: url(./assets/fonts/static/Manrope-ExtraBold.ttf);
}

@font-face {
  font-family: 'Manrope-ExtraLight';
  src: url(./assets/fonts/static/Manrope-ExtraLight.ttf);
}

@font-face {
  font-family: 'Manrope-Light';
  src: url(./assets/fonts/static/Manrope-Light.ttf);
}

@font-face {
  font-family: 'Manrope-Medium';
  src: url(./assets/fonts/static/Manrope-Medium.ttf);
}

@font-face {
  font-family: 'Manrope-Regular';
  src: url(./assets/fonts/static/Manrope-Regular.ttf);
}

@font-face {
  font-family: 'Manrope-SemiBold';
  src: url(./assets/fonts/static/Manrope-SemiBold.ttf);
}

@font-face {
  font-family: 'Open Sans';
  src: url(./assets/fonts/OpenSans.ttf);
}

@font-face {
  font-family: 'Open Sans-Bold';
  src: url(./assets/fonts/static/OpenSans-Bold.ttf);
}

@font-face {
  font-family: 'Open Sans-ExtraBold';
  src: url(./assets/fonts/static/OpenSans-ExtraBold.ttf);
}

@font-face {
  font-family: 'Open Sans-Light';
  src: url(./assets/fonts/static/OpenSans-Light.ttf);
}

@font-face {
  font-family: 'Open Sans-Medium';
  src: url(./assets/fonts/static/OpenSans-Medium.ttf);
}

@font-face {
  font-family: 'Open Sans-Regular';
  src: url(./assets/fonts/static/OpenSans-Regular.ttf);
}

@font-face {
  font-family: 'Open Sans-SemiBold';
  src: url(./assets/fonts/static/OpenSans-SemiBold.ttf);
}

.grecaptcha-badge {
  display: none !important;
}

/*************** animation ***********************/
.fadeIn0_5 {
  animation: fadeIn 1.5s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideLeft20 {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(-20px);
  }
}

@keyframes slideLeft10 {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(-10px);
  }
}

/** COOKIE BOT overrides **/
#CybotCookiebotDialogHeader {
  display: none !important;
}

#CookiebotWidget {
  display: none !important;
}

.white-space-pre-line {
  white-space: pre-line;
}