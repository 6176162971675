.adservioMicrosoft__wrapper {
    width: 100%;
    height: fit-content;
    position: relative;
    margin-top: 90px;
}

.adservioMicrosoft__logos {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.adservioMicrosoft__logos__wrapper {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 130px;
    margin-left: 105px;
}

.adservioMicrosoft__logos__wrapper img {
    height: 51px;
}

.adservioMicrosoft__logos__wrapper span {
    font-family: 'Manrope-ExtraBold';
    font-size: 40px;
    color: #34296E;
}

.adservioMicrosoft__logos__microsoft__icon {
    width: 565px;
    aspect-ratio: 1/1;
}


.adservioMicrosoft__content {
    display: flex;
    align-items: center;
    position: absolute;
    width: calc(100% - 595px);
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    height: 268px;
    background-color: #00ADEF;
    margin-right: 500px;
}

.adservioMicrosoft__text {
    margin-left: 105px;
    width: 50%;
    min-width: 600px;
    font-family: 'Manrope-Regular';
    font-size: 24px;
    line-height: 1.5;
    color: #fff;
}


.adservioMicrosoft__video__wrapper {
    position: absolute;
    right: 45px;
    bottom: 45px;
    z-index: 1;
    height: 460px;
    width: 460px;
    border-radius: 50%;
    overflow: hidden;
}

.adservioMicrosoft__video__wrapper .video {
    width: 100% !important;
    height: 100% !important;
}

.adservioMicrosoft__video__wrapper .video img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.adservioMicrosoft__video__wrapper .video img.active {
    opacity: 0.5;
}

.adservioMicrosoft__video__wrapper .video .videoAnimation .playBtn {
    margin-top: 50%;
    margin-left: 50%;
    transform: translate(-30%, -50%);
}

.adservioMicrosoft__video__wrapper .video .videoAnimation .overflowContainer:hover .wave1 {
    animation-name: wave_1;
}

.adservioMicrosoft__video__wrapper .video .videoAnimation .overflowContainer:hover .wave2 {
    animation-name: wave_2;
}

.adservioMicrosoft__video__wrapper .video .videoAnimation .overflowContainer:hover .wave3 {
    animation-name: wave_3;
}



@keyframes wave_1 {
    from {
        width: 150px;
        height: 150px;
        margin-top: -150px;
        margin-left: 150px;
        border-width: 3px;
    }

    to {
        width: 220px;
        height: 220px;
        margin-top: -185px;
        margin-left: 115px;
        border-width: 5px;
    }
}

@keyframes wave_2 {
    from {
        width: 220px;
        height: 220px;
        margin-top: -185px;
        margin-left: 115px;
        border-width: 5px;
    }

    to {
        width: 350px;
        height: 350px;
        margin-top: -250px;
        margin-left: 50px;
        border-width: 10px;
    }
}

@keyframes wave_3 {
    from {
        width: 350px;
        height: 350px;
        margin-top: -250px;
        margin-left: 50px;
        border-width: 10px;
    }

    to {
        width: 460px;
        height: 460px;
        margin-top: -310px;
        margin-left: 0px;
        border-width: 13px;
    }
}


@media screen and (max-width: 1850px) {

    .adservioMicrosoft__logos__wrapper,
    .adservioMicrosoft__text {
        margin-left: 50px;
    }

}

@media screen and (max-width:1780px) {
    .adservioMicrosoft__video__wrapper {
        height: 360px;
        width: 360px;
        right: 35px;
        bottom: 80px;
    }


    @keyframes wave_1 {
        from {
            width: 150px;
            height: 150px;
            margin-top: -150px;
            margin-left: 100px;
            border-width: 3px;
        }

        to {
            width: 220px;
            height: 220px;
            margin-top: -185px;
            margin-left: 65px;
            border-width: 5px;
        }
    }

    @keyframes wave_2 {
        from {
            width: 220px;
            height: 220px;
            margin-top: -185px;
            margin-left: 65px;
            border-width: 5px;
        }

        to {
            width: 300px;
            height: 300px;
            margin-top: -225px;
            margin-left: 40px;
            border-width: 10px;
        }
    }

    @keyframes wave_3 {
        from {
            width: 300px;
            height: 300px;
            margin-top: -225px;
            margin-left: 40px;
            border-width: 10px;
        }

        to {
            width: 360px;
            height: 360px;
            margin-top: -255px;
            margin-left: 0px;
            border-width: 13px;
        }
    }
}

@media screen and (max-width:1660px) {
    .adservioMicrosoft__logos__microsoft__icon {
        margin-right: -150px;
    }

    .adservioMicrosoft__content {
        width: calc(100% - 445px);
    }
}

@media screen and (max-width:1660px) {
    .adservioMicrosoft__logos__microsoft__icon {
        margin-right: -150px;
    }

    .adservioMicrosoft__content {
        width: calc(100% - 445px);
    }
}

@media screen and (max-width:1500px) {
    .adservioMicrosoft__logos__microsoft__icon {
        width: 450px;
    }

    .adservioMicrosoft__content {
        width: calc(100% - 327px);
        height: 213px;
    }

    .adservioMicrosoft__text {
        min-width: 520px;
        font-size: 21px;
    }

    .adservioMicrosoft__logos__wrapper {
        gap: 15px;
        margin-bottom: 90px;
    }

    .adservioMicrosoft__logos__wrapper img {
        height: 45px;
    }

    .adservioMicrosoft__logos__wrapper span {
        font-size: 32px;
    }

    .adservioMicrosoft__video__wrapper {
        right: 40px;
        bottom: 40px;
    }
}

@media screen and (max-width:1350px) {
    .adservioMicrosoft__logos__microsoft__icon {
        margin-right: 0px;
    }

    .adservioMicrosoft__content {
        width: calc(100% - 477px);
    }

    .adservioMicrosoft__video__wrapper {
        right: -190px;
        bottom: 40px;
    }
}

@media screen and (max-width:1250px) {
    .adservioMicrosoft__logos__microsoft__icon {
        margin-right: -75px;
    }

    .adservioMicrosoft__content {
        width: calc(100% - 402px);
    }

}

@media screen and (max-width:1160px) {

    .adservioMicrosoft__logos__wrapper,
    .adservioMicrosoft__text {
        margin-left: 35px;
    }

    .adservioMicrosoft__video__wrapper {
        height: 330px;
        width: 330px;
        right: -180px;
        bottom: 50px;
    }


    @keyframes wave_1 {
        from {
            width: 130px;
            height: 130px;
            margin-top: -143px;
            margin-left: 93px;
            border-width: 3px;
        }

        to {
            width: 200px;
            height: 200px;
            margin-top: -179px;
            margin-left: 59px;
            border-width: 5px;
        }
    }

    @keyframes wave_2 {
        from {
            width: 200px;
            height: 200px;
            margin-top: -179px;
            margin-left: 59px;
            border-width: 5px;
        }

        to {
            width: 260px;
            height: 260px;
            margin-top: -210px;
            margin-left: 30px;
            border-width: 10px;
        }
    }

    @keyframes wave_3 {
        from {
            width: 260px;
            height: 260px;
            margin-top: -210px;
            margin-left: 30px;
            border-width: 10px;
        }

        to {
            width: 330px;
            height: 330px;
            margin-top: -240px;
            margin-left: 0px;
            border-width: 13px;
        }
    }
}

@media screen and (max-width:1120px) {
    .adservioMicrosoft__content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 32px;
        width: 100%;
        margin-right: 0px;
    }

    .adservioMicrosoft__text {
        margin-left: 0px;
        width: 60%;
        min-width: 500px;
    }

    .adservioMicrosoft__video__wrapper {
        right: 10px;
        bottom: 55px;
    }

    .adservioMicrosoft__logos {
        margin-left: -100px;
        margin-bottom: 250px;
        justify-content: normal;
        align-items: center;
    }

    .adservioMicrosoft__logos__wrapper {
        margin-left: 0px;
        margin-bottom: 0px;
        transform: rotate(-90deg);
        gap: 10px;
    }

    .adservioMicrosoft__logos__wrapper img {
        height: 30px;
    }

    .adservioMicrosoft__logos__wrapper span {
        font-size: 21px;
    }

    .adservioMicrosoft__logos__microsoft__icon {
        margin-left: -90px;
        margin-right: 0px;
        width: 335px;
    }
}

@media screen and (max-width:900px) {
    .adservioMicrosoft__text {
        font-size: 16px;
        width: 50%;
        min-width: 400px;
    }

    .adservioMicrosoft__wrapper {
        margin-top: 45px;
    }
}


@media screen and (max-width:850px) {
    .adservioMicrosoft__text {
        width: 100%;
        min-width: fit-content;
    }

    .adservioMicrosoft__content {
        padding-top: 180px;
        justify-content: flex-end;
        height: max-content;
    }

    .adservioMicrosoft__video__wrapper {
        left: 50%;
        transform: translateX(-50%);
        top: -170px;
    }

    .adservioMicrosoft__logos {
        margin-left: -110px;
        margin-bottom: 500px;
        justify-content: center;
    }
}

@media screen and (max-width:760px) {
    .adservioMicrosoft__wrapper {
        margin-bottom: 50px;
    }
}

@media screen and (max-width:580px) {
    .adservioMicrosoft__logos {
        margin-left: -50px;
    }
}

@media screen and (max-width:500px) {
    .adservioMicrosoft__logos {
        margin-left: 0px;
    }
}

@media screen and (max-width:470px) {
    .adservioMicrosoft__logos {
        margin-bottom: 530px;
    }
}