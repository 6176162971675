#schoolRegister {
  display: flex;
  flex-direction: column;
  width: max-content;
  margin: 73px auto 32px auto;
  align-items: center;
}
#schoolRegister .topSection {
  display: flex;
  max-width: 90vw;
  width: 450px;
  left: 0px;
  position: relative;
  align-items: center;
}
#schoolRegister .topSection img {
  height: 54px;
  margin-right: 22.4px;
}
#schoolRegister .topSection div h1 {
  font-family: "Manrope";
  font-size: 20px;
  line-height: 1.2;
  text-align: left;
  color: var(--tertiary-heavy);
}

#schoolRegister .topSection div p {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  color: var(--tertiary-heavy);
  margin-top: 5px;
}
#schoolRegister form {
  width: 459px;
  margin-top: 51px;
}
#schoolRegister form p.title {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--tertiary-heavy);
}
#schoolRegister form p.title:nth-of-type(1) {
  margin-bottom: 7.8px;
}
#schoolRegister form p.title:nth-of-type(1) {
  margin-bottom: 25.7px;
}
#schoolRegister form label {
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--secondary-heavy);
}
#schoolRegister form > div.schoolInfo,
#schoolRegister form > div.schoolManagerInfo {
  display: flex;
  flex-direction: column;
}
#schoolRegister form > div.schoolInfo > label,
#schoolRegister form > div.schoolManagerInfo > label {
  width: 300px !important  ;
  display: flex;
  flex-direction: column;
}
#schoolRegister form > div.schoolInfo input {
  margin-bottom: 15px;
  padding-left: 15px;
}
#schoolRegister form > div.schoolManagerInfo input {
  margin-bottom: 15px;
  padding-left: 10px;
}
#schoolRegister form > div.schoolInfo div.row {
  display: grid;
  grid-template-columns: repeat(3, 143px);
  column-gap: 15px;
}
#schoolRegister form>div.schoolManagerInfo>div.row {
  display: grid;
  grid-template-columns: repeat(2, 223px);
  column-gap: 15px;

}
#schoolRegister form > div.schoolInfo div.row
{
 margin-bottom: 15px;
}
#schoolRegister form p.form__input-error,
#schoolRegister form p.form__input-error {
  font-size: 12px;
  color: var(--destructive-accent);
  font-family: "Manrope";
  line-height: 1.2;
  text-align: left;
  font-weight: 100;
  min-width: 146px;
  position: relative;
  top: -11px;
}
#schoolRegister form > div.schoolInfo div.row input,
#schoolRegister form > div.schoolManagerInfo > div.row input {
  width: 223px;
}
#schoolRegister form > div.schoolManagerInfo .PhoneInputInput {
  margin-bottom: 0;
}
#schoolRegister form > div.schoolManagerInfo .wrapper {
  margin-bottom: 15px;
}
#schoolRegister form > div.schoolInfo div.row input {
  margin-bottom: 15px;
}

#schoolRegister form > div.schoolInfo {
  margin-bottom: 40px;
}
#schoolRegister form input {
  height: 40px;
  outline: none;
  border: 1px solid #ccc;
  padding-left: 10px;
  box-sizing: border-box;
  font-family: "Open Sans",
  "Lucida Sans Unicode",
  Helvetica,
  sans-serif;
  font-size: 12px;
}
#schoolRegister .captcha {
  width: 226px;
  height: 58px;
}
#schoolRegister .checkBoxes {
  display: flex;
  flex-direction: column;
}
#schoolRegister .checkBoxes input {
  flex-shrink: 0;
  margin-right: 20px;
  height: 23px;
}
#schoolRegister .checkBoxes label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}
#schoolRegister .checkBoxes label a {
  color: var(--main-default);
}
#schoolRegister button.prymary,
#schoolRegister button.primary-invert {
  margin-top: 30px;
  width: 120px !important;
}
#schoolRegister button:disabled {
  background-color: var(--secondary-light);
}
#schoolRegister button:nth-of-type(2) {
  margin-left: 40px;
}

#schoolRegister form .comp-select.select-country,
#schoolRegister form .comp-select.select-city,
#schoolRegister form input#town {
  width: 143px;
  background-color: var(--tertiary-ultralight);
  font-family: "Open Sans",
  "Lucida Sans Unicode",
  Helvetica,
  sans-serif;
  font-size: 12px;
}

#schoolRegister form .comp-select {
  background-color: var(--tertiary-ultralight);
  font-family: "Open Sans", "Lucida Sans Unicode", Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 100;
}

#schoolRegister form .comp-select input{
  height: auto;
 }

#schoolRegister form .comp-select[class$="-container"] {
  font-size: 12px;
}
#schoolRegister
  form
  .comp-select[class$="-container"]
  > div[class$="-control"] {
  padding-left: 4px;
}

#schoolRegister
  form
  .comp-select[class$="-container"]
  > div[class$="-control"]
  > div:first-child {
  font-size: 12px;
}


@media only screen and (max-width: 510px) {
  
  #schoolRegister form {
    width: 300px;
  }

  #schoolRegister form>div.schoolInfo div.row {
   grid-template-columns: repeat(2, 143px);
  row-gap: 17px;
  }

  #schoolRegister form>div.schoolInfo div.row label:first-child {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  }

  #schoolRegister form .comp-select.select-country {
  width: 100%;
  }

  #schoolRegister form>div.schoolManagerInfo>div.row:first-of-type {
    grid-template-columns: repeat(2, 143px);

  }
  
  #schoolRegister form>div.schoolManagerInfo>div.row:first-of-type input{
    width: 143px;
  }
  
  #schoolRegister form>div.schoolManagerInfo>div.row:nth-of-type(2) {
    grid-template-columns: auto;
  }
  
  #schoolRegister form>div.schoolManagerInfo>div.row:nth-of-type(2) label:nth-of-type(2){
    grid-row-start: 3;
  }

  #schoolRegister form>div.schoolManagerInfo>div.row:nth-of-type(2) input{
    width: 100%;
  }
}