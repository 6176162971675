#bcrDigitalization {
    width: 1276px;
    background-color: var(--main-default);
    margin-left: 285px;
    display: flex;
    border-radius: 12px;
    margin-bottom: 110px;
    align-items: center;
}

#bcrDigitalization>img {
    margin-top: 97.4px;
    width: 432px;
    margin-left: -285px;
}

#bcrDigitalization .content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#bcrDigitalization .content h3 {
    margin-left: 30px;
    font-family: "Manrope";
    font-size: 20px;
    line-height: 1.2;
    text-align: left;
    color: var(--tertiary-ultralight);
}

#bcrDigitalization .content h3 span {
    font-weight: bold;
}

#bcrDigitalization .content .testimonial {
    flex: 1;
    margin-right: 95.5px;
    margin-left: -40px;
}

#bcrDigitalization .content .testimonial h1 {
    font-family: "Manrope";
    font-size: 34px;
    font-weight: bold;
    line-height: 1.76;
    text-align: left;
    color: var(--tertiary-ultralight);
    margin-left: 81px;
    margin-bottom: 43.4px;
    margin-top: 20px;
}

#bcrDigitalization .content .testimonial p {
    font-family: "Manrope";
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: var(--tertiary-ultralight);
    margin-left: 81px;
}

#bcrDigitalization .content .testimonial p:nth-of-type(1) {
    margin-bottom: 30px;
}

#bcrDigitalization .content .testimonial p:nth-of-type(2) {
    margin-bottom: 20px;
}

#bcrDigitalization .content .testimonial img:last-child {
    float: right;
    transform: translateX(100%);
}

@media screen and (max-width: 1560px) {
    #bcrDigitalization {
        width: calc(100vw - 285px);
    }
}

@media screen and (max-width: 1200px) {
    #bcrDigitalization {
        width: 100%;
        margin-left: 0;
        flex-direction: column;
        position: relative;
        border-radius: 0;
    }
    #bcrDigitalization>img {
        position: absolute;
        top: -90px;
        margin-top: 0;
        margin-left: 0;
        left: 0;
        width: 270px;
    }
    #bcrDigitalization .content {
        flex-direction: column;
        margin-top: 110px;
    }
    #bcrDigitalization .content h3 {
        align-self: flex-start;
        margin-left: 20px;
        margin-left: 176px;
        margin-bottom: 30px;
    }
    #bcrDigitalization .content .testimonial {
        margin-left: 0 !important;
        margin-right: 0 !important;
        order: -1;
    }
    #bcrDigitalization .content .testimonial h1 {
        margin-left: 248px;
        margin-bottom: 39.3px;
        margin-top: -10px;
    }
    #bcrDigitalization .content .testimonial p {
        margin-left: 176px;
        margin-right: 86px;
    }
    #bcrDigitalization .content .testimonial p:nth-of-type(1) {
        margin-bottom: 30px;
    }
    #bcrDigitalization .content .testimonial p:nth-of-type(2) {
        margin-bottom: 20px;
    }
    #bcrDigitalization .content .testimonial img:last-child {
        float: right;
        transform: translateX(0);
    }
    #bcrDigitalization .content .testimonial img {
        position: absolute;
        right: 76px;
    }
    #bcrDigitalization .content .testimonial img:first-child {
        left: 51px;
    }
}

@media screen and (max-width: 750px) {
    #bcrDigitalization>img {
        position: absolute;
        top: -90px;
        margin-top: 0;
        margin-left: 0;
        left: 0;
        width: 270px;
    }
    #bcrDigitalization .content {
        margin-top: 200px;
        flex-direction: column;
    }
    #bcrDigitalization .content h3 {
        align-self: flex-start;
        margin-left: 20px;
        margin-bottom: 97px;
    }
    #bcrDigitalization .content .testimonial h1 {
        font-size: 26px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: center;
        margin: 0 auto 27px auto !important;
    }
    #bcrDigitalization .content .testimonial img:first-child {
        left: 32px;
        top: 110px;
    }
    #bcrDigitalization .content .testimonial img:last-child {
        right: 38px;
        bottom: 50px;
    }
    #bcrDigitalization .content .testimonial p {
        margin-left: 20px;
        margin-right: 20px;
        width: calc(1005 - 40px);
    }
}