#registerSucceeded {
  margin-top: 140px;
  width: max-content;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
}
#registerSucceeded img {
  height: 53.9px;
}
#registerSucceeded h1 {
  font-family: "Open Sans";
  font-size: 34px;
  line-height: 1.2;
  text-align: center;
  color: var(--tertiary-heavy);
  margin-bottom: 55px;
}
#registerSucceeded h3 {
  font-family: "Open Sans";
  max-width: 600px;
  font-size: 20px;
  line-height: 1.27;
  text-align: center;
  color: var(--tertiary-heavy);
  margin-bottom: 75px;
}
#registerSucceeded h6 {
  font-family: "Open Sans";
  max-width: 600px;
  font-size: 15px;
  line-height: 1.27;
  text-align: center;
  color: var(--tertiary-heavy);
  margin-bottom: 75px;
}
#registerSucceeded h1.hasAdservio {
  margin-bottom: 6px;
}
#registerSucceeded h3.hasAdservio {
  margin-bottom: 6px;
}
#registerSucceeded h3.hasAdservio:last-of-type {
  margin-bottom: 36px;
}

#registerSucceeded h6.hasAdservio {
  position: relative;
  top: -30px;
}

#registerSucceeded .btns {
  display: flex;
  justify-content: space-between;
}
#registerSucceeded .btns button:last-child {
  margin-left: 25px;
}
#registerSucceeded div.hasAdservio {
  margin-top: 43px;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  color: var(--tertiary-heavy);
}
#registerSucceeded div.hasAdservio span {
  cursor: pointer;
  color: var(--main-default);
}
