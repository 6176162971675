.intro {
    position: relative;
    width: 100%;
    height: calc(100vw / (1920 / 824));
    background-image: url(../../assets/home/intro/introBg.png);
    background-size: cover;
    background-position: right;
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 104px;
}

.intro::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 60%);
}

.intro .container_column {
    z-index: 1;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 80px;
    max-width: 780px;
}

.intro .container_column_info {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.intro .container_column_info_text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: var(--tertiary-ultralight);
    align-items: flex-start;
}

.intro .container_column_info_text h1 {
    font-size: 60px;
    line-height: 1.2;
    font-family: 'Manrope-ExtraBold';

}

.intro .container_column_info_text p {
    font-family: 'Manrope-Medium';
    font-size: 32px;
    line-height: 1.3;
}

.intro .container_column_info button {
    width: fit-content;
}

.intro .ai_section {
    position: absolute;
    width: 360px;
    height: 360px;
    z-index: 100;
    top: 70px;
    right: 135px;
    overflow: allow;
    transform: rotate(12deg);
    background-image: url(../../assets/home/intro/badgeAI.svg);
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.intro .ai_section img {
    width: 72px;
    height: 72px;
}

.intro .ai_section p {
    font-family: 'Manrope-Regular';
    width: 155px;
    color: var(--tertiary-ultralight);
    font-size: 24px;
    text-align: center;
    font-weight: 500;
}




.intro_logos {
    display: flex;
    gap: 32px;
    height: 120px;
}

.intro_logos img {
    max-height: 100%;
}

.intro_logos_column {
    display: flex;
    flex-direction: column;
    gap: 36px;
    align-items: center;
    align-items: flex-start;
    height: 100%;
    position: relative;
}

.intro_logos_row {
    display: flex;
    gap: 32px;
    flex: 1;
    height: 40px;
}

.intro_logos_row .imgContainer {
    height: auto;
    max-height: 100%;
    position: relative;
}

.intro_logos_row .imgContainer img {
    max-height: 40px;
}

.intro_logos_logoContainer {
    display: flex;
    gap: 12px;
    align-items: center;
    max-width: 270px;
}

.intro_logos_logoContainer p.text {
    font-size: 14px;
    font-family: 'Manrope-Regular';
    color: var(--tertiary-ultralight);
}

@media screen and (max-width: 1750px) {
    .intro .ai_section {
        top: 50px;
        right: 100px;
    }

}

@media screen and (max-width: 1500px) {
    .intro .container_column {
        max-width: 640px;

    }

    .intro .container_column h1 {
        font-size: 50px;

    }

    .intro .ai_section {
        top: 40px;
        right: 80px;
    }

}

@media screen and (max-width: 1350px) {
    .intro {
        padding-left: 50px;
    }

    .intro .ai_section {
        top: 30px;
        right: 50px;
        width: 280px;
        height: 280px;
    }

    .intro .ai_section img {
        width: 48px;
        height: 48px;
    }

    .intro .ai_section p {
        font-size: 20px;
        width: 130px;
    }
}


@media screen and (max-width: 1280px) {
    .intro {
        align-items: flex-start;
    }

    #intro_logos_outside.scroll div.intro_logos_logoContainer {
        width: 130px !important;
        max-width: 130px;
        min-width: 130px;
        height: 102px;
        gap: 12px;
    }

    #intro_logos_outside.scroll div.intro_logos_logoContainer:nth-of-type(3) {
        width: 150px !important;
        max-width: 150px;
        min-width: 150px;
        padding: 0;
    }

    #intro_logos_outside.scroll div.intro_logos_logoContainer:nth-last-of-type(1) {
        margin-right: 32px;
    }


    #intro_logos_outside.scroll::-webkit-scrollbar {
        display: none;
    }

    #intro_logos_outside.scroll {
        padding: 0;
        width: 100vw;
        position: relative;
        overflow-x: scroll !important;
        -ms-overflow-style: none;
        scrollbar-width: none;
        justify-content: space-between;
        gap: 0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 32px;
    }

    .intro .ai_section {
        top: auto;
        bottom: 10px;
        left: 30%;
    }

    .intro_logos_column {
        flex-direction: row;
        gap: 0px;
        align-items: center;
        align-items: flex-start;
        position: relative;
        height: 102px;
        width: 100%;
    }

    .intro_logos_row {
        display: flex;
        gap: 32px;
        flex: 1;
        height: 102px;
    }

    .intro_logos_row .imgContainer {
        max-height: 48px;
    }

    .intro_logos_row .imgContainer {
        height: 48px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .intro_logos_row .imgContainer img {
        max-height: 48px;
    }

    .intro_logos_logoContainer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
    }

    .intro_logos_logoContainer p.text {
        font-size: 14px;
        color: var(--gary-500);
        text-align: center;
    }

    #intro_logos_inside {
        display: none !important;
    }

    #intro_logos_outside {
        display: flex !important;
    }
}

@media screen and (max-width: 1200px) {
    .intro {
        padding-left: 40px;
    }

    .intro .container_column {
        max-width: 560px;
    }

    .intro .container_column h1 {
        font-size: 45px;

    }
}

@media screen and (max-width: 1024px) {

    .intro .ai_section {
        top: auto;
        bottom: 10px;
        left: 25%;
    }

    .intro .container_column {
        padding-top: 40px;
        gap: 40px;
        max-width: 520px;
    }

    .intro .container_column h1 {
        font-size: 40px;
    }

    .intro .container_column_info_text p {
        font-size: 24px;
    }
}


#intro_logos_outside {
    display: none;
}


/* #intro_logos_outside {
    display: none;
}

.intro_logos .intro_logos_logoContainer:nth-of-type(5) {
    width: 170%;
} */

/* .intro_logos_logoContainer {
    display: flex;
    gap: 16px;
    align-items: center;
}

.intro_logos_logoContainer div.imgContainer {
    height: 34px;

    display: flex;
    align-items: center;
    justify-content: center;
}



.intro_logos_logoContainer div.imgContainer img {
    max-height: 100%;
}

.intro_logos_logoContainer p.text {
    font-family: 'Manrope-Regular';
    font-size: 12px;
    line-height: 1.17;
    color: var(--tertiary-ultralight);
}

.intro_logos_logoContainer p.text br {
    display: none;
}

.EAF_logo {
    width: 70px;
    margin-top: -5px;
} */


@media only screen and (max-width: 1400px) {
    .intro {
        height: 598px;
        margin-bottom: 43px;
        background-position: 0px 0px;
    }

    /* .container_intro {
        margin-left: 80px;
    } */
}


@media only screen and (max-width: 1200px) {
    .intro {
        background-position: -100px 0px;
    }

    .intro::after {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 70%);
    }


    /* .container_intro {
        margin-left: 40px;
    } */
}

@media only screen and (max-width: 1100px) {
    .intro {
        background-position: right;
        background-image: url(../../assets/home/intro/introBgTablet.png);
    }


    /* .container_intro h1 {
        font-size: 40px;
    }

    .intro_logos {
        margin-top: 60px;
        align-items: flex-start;
        gap: 10px;
        row-gap: 25px;
    }

    .intro_logos_logoContainer {
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        max-width: 150px;
    }

    .intro_logos_logoContainer p.text {
        text-align: center;
        font-size: 10px;
    }

    .intro_logos_logoContainer p.text br {
        display: block;
    } */
}

@media only screen and (max-width: 1000px) {
    .intro {
        background-position: -20px 0px;
    }
}

@media only screen and (max-width: 850px) {



    .intro .container_column {
        max-width: 450px;
        background-color: transparent;
    }

    .intro .container_column_info {
        gap: 40px;
    }


    /* .container_intro {
        margin: 100px 0px 0px 50px;
    } */

    /* .container_intro h1 {
        font-family: "Manrope-Bold";
    }

    .intro_logos {
        flex-wrap: nowrap;
    }

    #intro_logos_inside {
        display: none;
    }

    #intro_logos_outside {
        display: flex;
        margin: 47px auto 59px auto;

        gap: 35px;
        width: fit-content;

        padding: 0 22px;
        box-sizing: border-box;


    } */




}


@media only screen and (max-width: 800px) {
    .intro {
        background-position: -80px 0px;
    }

    .intro::after {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 90%);
    }
}

@media only screen and (max-width: 750px) {
    .intro {
        background-position: -120px 0px;
    }

    .intro .ai_section {
        width: 200px;
        height: 200px;
        bottom: 20px;
        right: 135px;
        gap: 12px;
    }

    .intro .ai_section img {
        width: 36px;
        height: 36px;
    }

    .intro .ai_section p {
        width: 90px;
        font-size: 14px;
    }
}

@media only screen and (max-width: 700px) {
    .intro {
        background-position: -180px 0px;
    }

    /* .container_intro h1 {
        font-size: 34px;
        line-height: 1.18;
    }

    .container_intro {
        width: 370px;
    }

    .container_intro_p {
        margin: 22px 0px 34px 0px;
    }

    .container_intro p {
        font-size: 16px;
    } */
}

@media only screen and (max-width: 650px) {
    .intro {
        background-position: -210px 0px;
    }
}

@media only screen and (max-width: 600px) {
    .intro {
        background-position: -280px 0px;
    }

    /* .container_intro {
        width: 330px;
        margin: 80px 0px 0px 30px;
    } */
}

@media only screen and (max-width: 560px) {
    .intro {
        background-position: -340px 0px;

    }


}

@media only screen and (max-width: 510px) {
    /* #intro_logos_outside.scroll {
        justify-content: flex-start;
    } */

    .intro {
        background-position: -380px 0px;
    }
}

@media only screen and (max-width: 450px) {
    /* .container_intro {
        margin: 48.5px 20px 0px 20px;
        width: 100%;
    } */

    .intro {
        background-position: right;
        background-image: url(../../assets/home/intro/introBgMobile.png);
        padding-left: 0px;
    }

    .intro::after {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    }

    .intro .container_column {
        width: 100%;
        justify-content: center;
    }

    .intro .container_column_info {
        flex: 1;
        padding: 35px 54px;
        justify-content: center;
        align-items: center;
        gap: 32px;
    }

    .intro .container_column_info_text {
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .intro .container_column_info_text h1 {
        font-size: 36px;
    }

    .intro .container_column_info_text p {
        font-size: 20px;
    }
}