.bubbles_section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

.bubbles_container {
  display: flex;
  align-items: center;
}

.bubbles_container div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: 20px;
  width: 250px;
  box-sizing: border-box;
  padding: 35px;
  aspect-ratio: 1/1;
}

.bubbles_container div:not(.bubbles-primary):after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('../../../assets/general/semicircle.svg');
  background-size: 139%;
  background-position: 48% 48%;
  background-repeat: no-repeat;
}

.bubbles-reverse::after {
  transform: rotate(180deg);
}

.bubbles-primary {
  width: 280px !important;
  background-color: #54A3D7;
  border-radius: 100%;
  margin: 0px -40px;
}

.bubbles_container div:nth-of-type(1) {
  margin-right: -40px;
}

.bubbles_container div:nth-of-type(5) {
  margin-left: -40px;
}

.bubbles_container img {
  width: 48px;
}

.bubbles_container p {
  font-family: "Manrope-Medium";
  font-size: 24px;
  color: #54A3D7;
  text-align: center;
  line-height: 1.3;
}

.bubbles-primary p {
  color: white;
}



@media only screen and (max-width:1200px) {
  .bubbles_container div {
    width: 180px;
    gap: 12px;
    padding: 30px;
  }

  .bubbles-primary {
    width: 200px !important;
    margin: 0px -25px;
  }

  .bubbles_container p {
    font-size: 16px;
  }

  .bubbles_container img {
    width: 32px;
  }

  .bubbles_container div:nth-of-type(1) {
    margin-right: -25px;
  }

  .bubbles_container div:nth-of-type(5) {
    margin-left: -25px;
  }

}

@media only screen and (max-width:850px) {

  .bubbles_container {
    flex-direction: column;
  }

  .bubbles-primary {
    margin: -25px 0px;
  }

  .bubbles_container div {
    padding: 20px;
  }

  .bubbles_container div:not(.bubbles-reverse):after {
    transform: rotate(90deg);
  }

  .bubbles-reverse::after {
    transform: rotate(270deg);
  }

  .bubbles_container div:nth-of-type(1) {
    margin-right: 0px;
    margin-bottom: -25px;
  }

  .bubbles_container div:nth-of-type(5) {
    margin-left: 0px;
    margin-top: -25px;
  }

}