footer {
  width: 100%;
  box-sizing: border-box;
  font-family: "Manrope";
}

footer section.top {
  width: 100%;
  height: 150px;
  padding: 10px 0;
  background-color: var(--main-default);
  display: flex;
  justify-content: center;
  align-items: center;
}

section.top > section {
  height: calc(100% - 40px);
  border-right: solid 1px var(--main-accent);
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  color: var(--tertiary-ultralight);
  font-family: "Manrope";
}

section.top > section:last-child {
  border-right: none;
}

section.top > section:nth-of-type(1) {
  justify-content: center;
}

section.top > section:nth-of-type(1) section {
  display: none;
}

section.top > section > h3 {
  font-weight: bold;
  color: var(--main-heavy);
  font-size: 12px;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 15px;
}

section.top > section > .top_footer_section {
  display: flex;
}

.column {
  font-size: 12px;
  flex-direction: column;
}

.column > p {
  margin-top: 20px;
}

.column > div > p {
  margin-left: 8.4px;
}

section.top > section > .top_footer_section ul:nth-of-type(2),
section.top > section > .top_footer_section ul:nth-of-type(3) {
  margin-left: 31px;
}

section.top > section > .top_footer_section ul li {
  font-size: 12px;
  color: var(--tertiary-ultralight);
  margin-bottom: 8px;
  text-transform: uppercase;
  height: 18px;
}

section.top > section > .top_footer_section ul li a:hover {
  text-decoration: underline;
}

section.top > section > .top_footer_section ul li button {
  padding: 2.5px 8px 3.5px 7px;
  font-size: 10px;
  border: none;
  outline: none;
  color: var(--main-default);
  background-color: var(--tertiary-ultralight);
  margin-left: 15px;
  cursor: pointer;
}

.footer_login {
  color: var(--tertiary-ultralight);
  display: flex;
  align-items: center;
}

section.top > section:nth-of-type(5) p:hover {
  text-decoration: underline;
  cursor: pointer;
}

section.top > section:nth-of-type(6) > p {
  margin-top: 13.5px;
}

section.top > section:nth-of-type(6) > p:hover {
  text-decoration: underline;
}

section.top > section:nth-of-type(6) .social_media {
  justify-content: space-between;
}

section.top > section:nth-of-type(6) .social_media a:not(:nth-of-type(1)) {
  margin-left: 13.5px;
}

section.bottom {
  background-color: var(--main-heavy);
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
}

section.bottom > section {
  font-family: "Manrope";
  margin-top: 5px;
  height: 50px;
}

section.bottom section:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;
  color: var(--tertiary-ultralight);
  font-size: 14px;
  padding-right: 30px;
  border-right: 2px solid var(--main-accent);
  cursor: pointer;
  margin-left: 30px;
}

section.bottom section:nth-of-type(1) img {
  width: 22px;
  height: 16px;
  margin-right: 5px;
}

section.bottom section:nth-of-type(2) {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 12px;
  color: var(--secondary-light);
  margin-left: 20px;
}

section.bottom section:nth-of-type(2) p {
  margin-bottom: 5px;
}

section.bottom section:nth-of-type(3) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  color: var(--secondary-light);
  margin-right: 20px;
}

section.bottom section:nth-of-type(3) p {
  margin-left: 25px;
  cursor: pointer;
}

#changeLanguageModal {
  cursor: pointer;
  backface-visibility: 0.75;
  position: absolute;
  margin-bottom: 75px;
  width: fit-content;
  margin-right: -8px;
  background-color: var(--tertiary-ultralight);
  color: #000;
}

#changeLanguageModal div.language {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px;
  cursor: pointer;
}

#changeLanguageModal div.language:hover {
  background-color: rgba(3, 178, 203, 0.5);
}

@media screen and (max-width: 1550px) {
  section.top {
    height: max-content;
  }
  section.top > section:nth-of-type(2) {
    width: 250px;
  }
  section.top > section:nth-of-type(2) > div {
    flex-wrap: wrap;
  }
  section.top > section:nth-of-type(2) > .top_footer_section ul:nth-of-type(1),
  section.top > section:nth-of-type(2) > .top_footer_section ul:nth-of-type(2) {
    width: 50%;
    margin-left: 0;
  }
  section.top > section > .top_footer_section ul:nth-of-type(3) {
    margin-left: 0px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  section.top > section > .top_footer_section ul li {
    height: 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  section.top > section > .top_footer_section ul:nth-of-type(3) li {
    width: 50%;
  }
  section.top
    > section
    > .top_footer_section
    ul:nth-of-type(3)
    li:nth-of-type(3) {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 1380px) {
  section.top > section {
    padding: 20px 15px;
  }
}

@media only screen and (max-width: 1205px) {
  footer section.top {
    flex-wrap: wrap;
    align-items: flex-start;
    height: fit-content;
  }
  footer section.top > section {
    order: 3;
  }
  footer section.top > section:nth-of-type(1) {
    width: 40% !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px !important;
    order: 1;
    border-right: none;
    padding-left: 40px;
    margin-top: 0;
  }
  section.top > section:nth-of-type(1) section {
    margin-left: 40px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--tertiary-ultralight);
    cursor: pointer;
  }
  section.top > section:nth-of-type(1) section img:first-child {
    width: 22px;
    margin-right: 8px;
    margin-left: 0;
  }
  section.top > section:nth-of-type(1) section img {
    width: 10px;
    margin-left: 8px;
  }
  section.top > section:nth-of-type(4) {
    margin-top: 10px;
  }
  footer section.top > section:nth-of-type(6) {
    order: 2;
    width: 60%;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
  }
  footer section.top > section:nth-of-type(6) h3 {
    width: auto;
    margin: 0;
  }
  footer section.top > section:nth-of-type(6) div {
    flex: 1;
    margin-left: 30px;
  }
  footer section.top > section:nth-of-type(6) p {
    display: none;
  }
  footer section.top > section:nth-of-type(6),
  footer section.top > section:nth-of-type(5) {
    border-right: none;
  }
  footer section.top > section:nth-of-type(2) {
    flex: 2;
  }
  footer section.top > section:nth-of-type(3),
  footer section.top > section:nth-of-type(4),
  footer section.top > section:nth-of-type(5) {
    flex: 1;
  }
  section.bottom section:nth-of-type(1) {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  section.top {
    height: fit-content;
    flex-wrap: wrap;
    margin: 0;
  }
  section.top > section {
    width: calc(100% - 60px);
    margin-left: 30px;
    margin-right: 30px;
    padding: 0;
    border-right: none;
    order: 1;
    font-size: 14.4px !important;
  }
  footer section.top > section:nth-of-type(1) {
    margin-top: 50px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
    order: -1;
    width: 100vw !important;
    padding: 0;
    margin-left: 18px;
  }
  section.top > section:nth-of-type(1) section {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--tertiary-ultralight);
  }
  section.top > section:nth-of-type(1) section img {
    width: 10px;
    margin-left: 8px;
  }
  section.top > section:nth-of-type(1) > img {
    width: 150px !important;
  }
  footer section.top > section:nth-of-type(6) {
    order: 10 !important;
    border-top: 2px solid var(--main-accent);
    margin-top: 20px;
    padding-top: 20px;
    width: 100% !important;
    flex-direction: column !important;
    align-items: flex-start;
  }
  footer section.top > section:nth-of-type(6) > div {
    margin: 0;
    width: 100%;
  }
  footer section.top > section:nth-of-type(6) .social_media {
    margin-top: 18px;
    display: flex;
    justify-content: space-evenly;
    width: 100% !important;
    margin-left: 0;
    flex: 1;
    margin-bottom: 18px;
  }
  section.top > section > .top_footer_section ul li {
    font-size: 14.4px;
    line-height: 20px;
  }
  section.top
    > section
    > .top_footer_section
    ul:nth-of-type(3)
    li:nth-of-type(3) {
    margin-top: 0px;
  }
  section.top > section > h3 {
    font-size: 16px;
  }
  footer section.top > section:nth-of-type(2) {
    margin-top: 20px;
    border-bottom: 2px solid var(--main-accent);
    padding-bottom: 20px;
    width: 100%;
    flex: auto;
  }
  section.top > section:nth-of-type(2) div {
    justify-content: space-between;
  }
  section.top > section:nth-of-type(2) div ul {
    width: fit-content !important;
  }
  section.top > section:nth-of-type(2) div ul:nth-of-type(3) {
    width: 100% !important;
    justify-content: space-between !important;
  }
  footer section.top > section:nth-of-type(2) div ul:nth-of-type(1),
  footer section.top > section:nth-of-type(2) div ul:nth-of-type(2) {
    width: 50% !important;
  }
  section.top > section:nth-of-type(2) div ul:nth-of-type(3) li:last-child {
    width: 50%;
  }
  section.top > section:nth-of-type(5) {
    order: 0;
    padding-bottom: 30px;
    border-bottom: 2px solid var(--main-accent);
    justify-content: space-between;
  }
  section.top > section:nth-of-type(5) p {
    margin-top: 0;
    font-size: 14.4px;
    width: fit-content !important;
  }
  section.top > section:nth-of-type(5) > div > p:last-child {
    width: 50% !important;
    padding-left: 50px;
  }
  section.top > section:nth-of-type(5) div.column {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14.4px;
  }
  section.top > section:nth-of-type(5) span {
    display: none;
  }
  section.top > section:nth-of-type(3) {
    margin-right: 0;
    margin-top: 10px;
  }
  section.top > section:nth-of-type(4) {
    margin-left: 0;
  }
  section.bottom {
    height: fit-content;
    flex-direction: column;
    align-items: center;
  }
  section.bottom section:nth-of-type(1) {
    display: none;
  }
  footer section.bottom section:nth-of-type(2) {
    margin-top: 30px;
    margin-left: 0;
  }
  section.bottom section:nth-of-type(2) p {
    width: 100%;
    text-align: center;
  }
  section.bottom section:nth-of-type(3) {
    margin-top: 50px;
    margin-bottom: 24px;
    justify-content: center;
    text-align: center;
    padding-left: 0;
  }
  section.bottom section:nth-of-type(3) a:nth-of-type(1) p {
    margin-left: 0;
  }
  #changeLanguageModal {
    backface-visibility: 0.75;
    position: absolute;
    margin-bottom: 75px;
    width: 75px;
    margin-left: -25px;
    background-color: var(--tertiary-ultralight);
    color: #000;
  }
}
