#intro_virtual{
    margin-top: 100px;
}
#intro_virtual img{
    position: absolute;
    z-index: -1;
    top:-30px;
    right:30px;
}
#intro_virtual>.intro_container>p {
    display: block;
    margin:0px 0px 28px 0px;
}
#intro_virtual>.intro_container>p:nth-last-of-type(1) {
    margin:0px 0px 45.7px 0px;
}
#intro_virtual h1 {
    margin:0px 0px 32.5px 0px;
}
#intro_virtual .intro_buttons_container{
    margin-bottom: 220px;
}
#intro_virtual .intro_buttons_container>div {
    display:flex;
    gap:20px;
    margin-bottom: 30px;
}

@media only screen and (max-width:1900px){
    #intro_virtual .intro_container{
        margin-left:11.5%;
    }
}
@media only screen and (max-width:1860px){
    #intro_virtual .intro_container{
        margin-left:9.5%;
    }
}
@media only screen and (max-width:1810px){
    #intro_virtual .intro_container{
        margin-left:7.5%;
    }
}
@media only screen and (max-width:1770px){
    #intro_virtual .intro_container{
        margin-left:5.5%;
    }
}
@media only screen and (max-width:1730px){
    #intro_virtual .intro_container{
        margin-left:3.7%;
    }
}
@media only screen and (max-width:1700px){
    #intro_virtual .intro_container{
        width:800px;
    }
    #intro_virtual .intro_buttons_container{
        margin-bottom: 100px;
    }
}
@media only screen and (max-width:1570px){
    #intro_virtual .intro_container{
        width:700px;
    }
    #intro_virtual h1{
        width:500px;
    }
    #intro_virtual img{
        top:20px;
    }
}
@media only screen and (max-width:1470px){
    #intro_virtual img{
        right:-190px !important;
    }  
}
@media only screen and (max-width:1350px){
    #intro_virtual{
        margin-top: 50px;
    }
}
@media only screen and (max-width:1270px){
    #intro_virtual .intro_container{
        width:595px;
    }
    #intro_virtual img{
        top:0px !important;
    }
}
@media only screen and (max-width:1150px){
    #intro_virtual .intro_container{
        width:495px;
    }
    #intro_virtual h1{
        width:300px;
        font-size: 34px;
    }
    #intro_virtual p{
        font-size: 16px;
    }
    #intro_virtual img{
        top:70px;
    }
}
@media only screen and (max-width:1000px){
    #intro_virtual .intro_buttons_container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 45px;
    }
    #intro_virtual .intro_buttons_container>div{
        gap:10px;
        margin-bottom: 15px;
    }
    #intro_virtual{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }
    #intro_virtual img{
        height:400px;
        top:0px;
        left:0px;
        position: relative;
        margin-bottom: 30px;
    }
    #intro_virtual .intro_container{
        margin:0px 0px 0px 0px;
        width:90%;
    }
    #intro_virtual h1{
        text-align: center;
        width: auto;
    }
    #intro_virtual .intro_buttons_container{
        display: flex;
        justify-content: center;
        margin-bottom: 55px;
    }

}

@media only screen and (max-width:700px){
    #intro_virtual img{
        height:350px;
    }
}
@media only screen and (max-width:600px){
    #intro_virtual img{
        height:300px;
    }
}
@media only screen and (max-width:500px){
    #intro_virtual img{
        height:250px;
    }
}
@media only screen and (max-width:400px){
    #intro_virtual img{
        height:230px;
    }
}