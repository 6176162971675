.registerBenefits {
  background-color: var(--macaroni-and-cheese);
  display: flex;
  height: 303px;
  margin-bottom: 0.1px;
}
.registerBenefits > img:first-child {
  height: 255px;
  margin-top: -127.3px;
}
.registerBenefits h3 {
  font-family: "Manrope";
  font-size: 34px;
  font-weight: bold;
  line-height: 1.29;
  text-align: right;
  color: var(--tertiary-heavy);
  align-self: center;
  margin-top: -35px;
  width: 400px;
  margin-left: 95px;
}
.registerBenefits .benefitsContainer {
  flex: 1;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.registerBenefits .benefits {
  display: flex;
}
.registerBenefits .benefits .benefit {
  box-sizing: border-box;
  border-left: 2px solid #807a68;
  padding: 0 25px;
  flex: 1;
}
.registerBenefits .benefits .benefit span {
  font-family: "Manrope";
  font-size: 50px;
  font-weight: 800;

  line-height: 1.2;

  text-align: left;
  color: var(--main-heavy);
  margin-bottom: 4px;
}
.registerBenefits .benefits .benefit p {
  font-family: "Manrope";
  font-size: 16px;

  line-height: 1.2;

  text-align: left;
  color: var(--tertiary-heavy);
}
.registerBenefits button {
  margin-top: 35px;
  margin-bottom: 40px;
  transform: translateX(calc(-1 * (95px + 125.5px + 400px) / 2));
}
.registerBenefits > img:last-child {
  height: 303px;
  margin-left: 25px;
}
@media only screen and (max-width: 1700px) {
  .registerBenefits h3 {
    margin-left: -50px;
  }
  .registerBenefits button {
    transform: translateX(calc(-1 * (-50px + 125.5px + 400px) / 2));
  }
}
@media only screen and (max-width: 1550px) {
  .registerBenefits {
    flex-direction: column;
    height: max-content;
  }
  .registerBenefits button {
    transform: translateX(0);
  }
  .registerBenefits > img {
    align-self: flex-start;
  }
  .registerBenefits h3 {
    width: 100%;
    margin-left: 0;
    align-self: center;
    text-align: center;
  }
  .registerBenefits .benefitsContainer {
    margin: 0;
    margin-top: 42px;
  }
  .registerBenefits .benefitsContainer .benefit:first-child {
    border-left: none;
  }
  .registerBenefits img:last-child {
    display: none;
  }
}
@media only screen and (max-width: 750px) {
  .registerBenefits {
    height: max-content;
  }
  .registerBenefits > img:first-child {
    height: 132px;
    margin-top: -66px;
  }
  .registerBenefits h3 {
    font-size: 26px;
    line-height: 1.23;
    text-align: center;
    margin-top: -35px;
    width: 80%;
    margin-left: 0;
  }
  .registerBenefits .benefitsContainer {
    flex: 1;
    margin-left: 25px;
    margin-right: 25px;
  }
  .registerBenefits .benefits {
    flex-direction: column;
  }
  .registerBenefits .benefits .benefit {
    border-left: none;
    padding: 0 0;
    margin-bottom: 22px;
  }
  .registerBenefits .benefits .benefit span {
    margin-bottom: 6px;
  }

  .registerBenefits button {
    margin-top: 35px;
    margin-bottom: 55px;
  }
}
