.calculator {
  position: relative;
  height: 700px;
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.background_stars {
  position: absolute;
  width: 1745.7px;
  left: 113.4px;
  top: -63.7px;
  z-index: 2;
}

.calculator>h1 {
  margin-bottom: 34px;
  display: none;
  font-family: "Manrope-Bold";
  font-size: 36px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray-700);
}

.calculator .horizontal-banner {
  box-sizing: border-box;
  width: 87.5%;
  height: 239px;
  background-color: var(--main-heavy);
  margin-top: 95px;
  display: flex;
  align-items: flex-start;
  padding-left: 113px;
}

.calculator .horizontal-banner div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.calculator .horizontal-banner div h3 {
  width: 360px;
  font-family: "Manrope-Bold";
  font-size: 50px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--tertiary-ultralight);
}

.calculator_container {
  position: absolute;
  /* align-self: center; */
  left: 50%;
  transform: translate(-50%);
  z-index: 5;
  width: 577px;
  background-color: var(--macaroni-and-cheese);
  font-family: "Manrope-Medium";
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary-heavy);
}

.calculator_container form {
  width: calc(100% - 150px);
  margin: 0 auto;
}

.calculator_container .form__input-error {
  color: var(--destructive-accent);
  margin-top: 5px;
  display: block;
}

.calculator_container .offerCheck {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calculator_container .offerCheck img {
  margin-top: 51px;
  width: 120px;
}

.calculator_container .offerCheck h2 {
  font-family: "Manrope";
  font-size: 27px;
  font-weight: bold;
  line-height: 1.18;
  text-align: center;
  color: var(--tertiary-heavy);
  margin: 50px;
  margin-bottom: 70px;
}

.or {
  font-family: 'Manrope-Regular';
  width: 100%;
  text-align: center;
  margin-top: 12px;
  color: var(--gray-700);
  font-size: 14px;
}

.calculator_container .offerCheck button {
  width: 120px !important;
  margin-bottom: 50px;
}

.calculator_container h3 {
  width: 100%;
  margin-bottom: 19.1px;
  margin-top: 34.4px;
  font-family: "Manrope-Bold";
  font-size: 34px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.76;
  letter-spacing: normal;
  text-align: center;
  color: var(--tertiary-heavy);
}

.calculator_container div.range-selector {
  display: grid;
  grid-template-columns: auto 60px;
}

.calculator_container div.range-selector label {
  width: max-content;
  display: block;
  width: 100%;
  grid-row: 1;
  grid-column: 1/3;
  font-size: 12px;
  color: var(--gray-700);
  font-family: 'Manrope-Regular';
}

.calculator_container div.range-selector label::first-letter {
  text-transform: capitalize;
}

.calculator_container div.range-selector input[type="text"] {
  outline: none;
  width: 60px;
  height: 40px;
  box-sizing: border-box;
  background-color: var(--tertiary-ultralight);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid var(--gray-200);
  font-family: "Manrope-Regular";
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray-700);
  border-radius: 8px;
}

.calculator_container div.range-selector input[type="range"] {
  -webkit-appearance: none;
  width: calc(100% - 26px);
  height: 4px;
  background: var(secondary-ultralight);
  border-radius: 4px;
  align-self: center;
}

.calculator_container div.range-selector input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
  background-color: var(--tertiary-ultralight);
}

.calculator_container div.range-selector input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.calculator_container>form>p {
  margin-top: 8px;
  font-size: 12px;
  font-family: 'Manrope-Regular';
  color: var(--gray-700);
}

.calculator_container>form>p:not(:nth-of-type(3)) {
  margin-top: 20px;
  margin-bottom: 10px;
}

.calculator_container div.school-type,
.calculator_container div.classes-type {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  justify-content: flex-start;
}

.calculator_container div.school-type label,
.calculator_container div.classes-type label {
  font-size: 16px;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 28px;
  align-items: flex-end;
}

.calculator_container div.school-type label:last-child(),
.calculator_container div.classes-type label:last-child() {
  margin-right: 0;
}

.calculator_container .offer-contact {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 26px;
}

.calculator_container .offer-contact .offer-contact-phone,
.calculator_container .offer-contact .offer-contact-email {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: 49%;
}

.calculator_container .offer-contact .offer-contact-phone label,
.calculator_container .offer-contact .offer-contact-email label {
  font-size: 12px;
  font-family: 'Manrope-Regular';
  margin-bottom: 8px;
  color: var(--gray-700);
}

.calculator_container .offer-contact .offer-contact-email input[type="email"] {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  border: solid 1px var(--gray-200);
  /* border-radius: 10px; */
  padding: 10px 16px;
  /* box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); */
  font-family: "Open Sans-Regular";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.23;
  letter-spacing: normal;
  text-align: left;
  color: var(--gray-700);
  border-color: transparent;
}

.calculator_container .offer-contact .offer-contact-email input[type="email"]:hover {
  border: none;
  /* border: 1px solid var(--blue-300); */
  /* box-shadow: 0px 0px 0px 4px #E0F2FE, 0px 1px 2px 0px rgba(16, 24, 40, 0.05); */
}

.calculator_container .offer-contact .offer-contact-email input[type="email"]:focus,
.calculator_container .offer-contact .offer-contact-email input[type="email"]:active {
  outline: none;
  /* border: 1px solid var(--blue-500); */
  /* box-shadow: 0px 0px 0px 4px #E0F2FE, 0px 1px 2px 0px rgba(16, 24, 40, 0.05); */
}


.calculator_container .offer-contact .offer-contact-phone .comp__phone-input-with-select {
  width: 100%;
}

.calculator_container .offer-contact .offer-contact-phone .phone-number__country-select {
  padding-left: 8px;
}


.classes-type,
.school-type {
  display: flex;
  width: calc(100% - 76px -72.5px);
  justify-content: space-between;
}

.school-type {
  width: calc((100% - 76px -72.5px) / (3 / 1.75));
  justify-content: space-between;
}

.classes-type label,
.school-type label {
  display: flex;
  align-items: center;
}

.school-type label span {
  font-family: 'Manrope-Regular';
  font-size: 18px;
  color: var(--gray-700);
}

input[type="checkbox"],
input[type="radio"] {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--gray-200, #D0D5DD);
}



.calculator_container>form>button {
  margin-top: 36px;

  margin-left: 50%;
  transform: translateX(-50%);
}

.calculator_packages-link {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px;
}

p.calculator_packages-link:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 1700px) {
  .calculator .horizontal-banner {
    padding-left: 80px;
  }

  .background_stars {
    left: 80px;
  }

}

@media only screen and (max-width: 1450px) {
  .calculator .horizontal-banner {
    padding-left: 60px;
  }

  .background_stars {
    left: -5px;
  }


  .calculator .horizontal-banner div h3 {
    font-size: 40px;
    width: 290px;
  }
}

@media only screen and (max-width: 1340px) {
  .calculator .horizontal-banner {
    padding-left: 30px;
  }

  .background_stars {
    left: -105px;
  }

  .calculator .horizontal-banner div h3 {
    font-size: 30px;
    width: 240px;
  }

}

@media only screen and (max-width: 1200px) {
  .background_stars {
    display: none;
  }

  .horizontal-banner {
    display: none !important;
  }

  .calculator>h1 {
    display: block;
  }

  .calculator_container {
    position: relative;
    left: auto;
    transform: translate(0);
  }

  .calculator {
    height: auto;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 760px) {
  .calculator>h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .calculator {
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .horizontal-banner {
    margin: 0;
    padding-left: 0 !important;
  }

  .calculator_container {
    width: 334px;
    margin-left: 0 !important;
    background-color: var(--macaroni-and-cheese);
    font-family: "Manrope";
    overflow: hidden;
  }

  .calculator_container h3 {
    font-family: "Manrope-Bold";
    font-size: 20px;
    width: 100%;
    text-align: center;
    line-height: 2;
    color: var(--tertiary-heavy);
    margin-bottom: 19.1px;
    margin-top: 15px;
  }

  .calculator_container form {
    width: calc(100% - 30px);
  }

  .calculator_container div.school-type,
  .calculator_container div.classes-type {
    flex-direction: column;
  }

  .calculator_container div.school-type label,
  .calculator_container div.classes-type label {
    margin-left: 0;
    margin-bottom: 15px;
  }

  .calculator_container div.school-type label:last-child,
  .calculator_container div.classes-type label:last-child {
    margin-bottom: 0px;
  }

  .calculator_container .offer-contact {
    flex-direction: column;
  }

  .calculator_container .offer-contact .offer-contact-phone,
  .calculator_container .offer-contact .offer-contact-email {
    width: 100%;
  }

  .calculator h1 {
    font-size: 26px;
  }

  .classes-type,
  .school-type {
    justify-content: space-between;
  }

  .school-type {
    justify-content: space-between;
  }

  .classes-type label {
    width: 50%;
  }
}