.mechanical_counters {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.counter>div:first-child {
    font-family: "Manrope-ExtraBold";
    font-size: 100px;
    line-height: 0.6;
    color: var(--warning-500);
}

.counter_container {
    height: fit-content;
    display: grid;
    column-gap: 32px;
}

.counter_container p {
    display: flex;
    justify-self: flex-start;
    align-items: center;
    font-family: 'Manrope-Regular';
    font-size: 24px;
    line-height: 1.2;
    color: var(--gray-700);
}

.counter_container>div:nth-of-type(1) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.counter>div:nth-of-type(1) {
    display: flex;
    justify-content: flex-end;
}

.mechanical_counters_wrapper {
    display: grid;
    grid-template-columns: 530px 390px 540px;
    row-gap: 60px;
    column-gap: 60px;
}

.counter_container:nth-of-type(1) {
    grid-template-columns: 290px auto;
}

.counter_container:nth-of-type(2) {
    grid-template-columns: 130px auto;
}

.counter_container:nth-of-type(3) {
    grid-template-columns: 240px auto;
}

.counter_container:nth-of-type(4) {
    position: relative;
    grid-template-columns: 382px auto;
    grid-row: 2;
    grid-column: 1/3;
    left: 100px;
}

.counter_container:nth-of-type(5) {
    position: relative;
    grid-template-columns: 324px auto;
    grid-row: 2;
    grid-column: 2/4;
    right: -200px;
}

.counter_container:nth-of-type(4)>p {
    width: 190px;
}

.counter_container:nth-of-type(5)>p {
    width: 370px;
}

@media only screen and (max-width:1700px) {
    .counter>div:first-child {
        font-size: 75px !important;
    }

    .mechanical_counters_wrapper {
        grid-template-columns: 530px 390px 500px;
        row-gap: 40px;
        column-gap: 60px;
    }

    .counter_container:nth-of-type(1) {
        grid-template-columns: 220px auto;
    }

    .counter_container:nth-of-type(2) {
        grid-template-columns: 90px auto;
    }

    .counter_container:nth-of-type(3) {
        grid-template-columns: 180px auto;
    }

    .counter_container:nth-of-type(4) {
        grid-template-columns: 290px auto;
    }

    .counter_container:nth-of-type(5) {
        grid-template-columns: 245px auto;
    }
}

@media only screen and (max-width:1600px) {
    .counter_container {
        column-gap: 0px;
        row-gap: 10px;
    }

    .counter_container>p {
        justify-self: center !important;
        text-align: center;
    }

    .counter_container>div:nth-of-type(1) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mechanical_counters_wrapper {
        grid-template-columns: 270px 272px 292px;
        column-gap: 160px;
    }

    .counter_container:nth-of-type(1) {
        grid-template-columns: 270px;
    }

    .counter_container:nth-of-type(2) {
        grid-template-columns: 272px;
    }

    .counter_container:nth-of-type(3) {
        grid-template-columns: 292px;
    }

    .counter_container:nth-of-type(4) {
        grid-template-columns: 290px;
        left: 180px;
    }

    .counter_container:nth-of-type(5) {
        grid-template-columns: 370px;
    }
}

@media only screen and (max-width:1300px) {
    .mechanical_counters_wrapper {
        column-gap: 40px;
    }

    .counter_container:nth-of-type(4) {
        left: 100px;
    }

    .counter_container:nth-of-type(5) {
        right: -150px;
    }
}

@media only screen and (max-width:950px) {
    .counter_container p {
        font-size: 16px;
    }

    .counter>div:first-child {
        font-family: "Manrope-Bold";
        font-size: 50px !important;
    }

    .counter_container {
        row-gap: 5px;
    }

    .mechanical_counters_wrapper {
        grid-template-columns: 200px 200px 200px;
        column-gap: 60px;
    }

    .counter_container:nth-of-type(1) {
        grid-template-columns: 200px;
    }

    .counter_container:nth-of-type(2) {
        grid-template-columns: 200px;
    }

    .counter_container:nth-of-type(3) {
        grid-template-columns: 200px;
    }

    .counter_container:nth-of-type(4) {
        grid-template-columns: 200px;
        left: 110px;
    }

    .counter_container:nth-of-type(5) {
        grid-template-columns: 230px;
    }

    .counter_container:nth-of-type(4)>p {
        width: auto;
    }

    .counter_container:nth-of-type(5)>p {
        width: auto;
    }
}

@media only screen and (max-width:750px) {
    .mechanical_counters_wrapper {
        column-gap: 30px;
    }
}

@media only screen and (max-width:700px) {
    .mechanical_counters_wrapper {
        grid-template-columns: 150px 170px !important;
        column-gap: 20px;
        row-gap: 20px;
    }

    .counter_container:nth-of-type(1) {
        grid-template-columns: 150px;
    }

    .counter_container:nth-of-type(2) {
        grid-template-columns: 150px;
    }

    .counter_container:nth-of-type(3) {
        grid-template-columns: 170px;
        grid-row: 1;
        grid-column: 2/3;
    }

    .counter_container:nth-of-type(4) {
        grid-row: 3;
        grid-column: 1/3;
        left: 60px;
    }

    .counter_container:nth-of-type(5) {
        grid-template-columns: 170px;
        grid-row: 2;
        grid-column: 2/3;
        right: 0;
    }
}