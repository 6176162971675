#csrIntro {
    margin-top: 62px;
    margin-bottom: 88px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#csrIntro img {
    width: 615px;
}

#csrIntro div {
    margin-top: -50px;
    width: 90%;
    max-width: 1309px;
    background-color: var(--dark-slate-blue);
    border-radius: 12px;
}

#csrIntro div h1 {
    font-family: "Manrope";
    font-size: 50px;
    font-weight: bold;
    line-height: 1.24;
    text-align: center;
    color: var(--tertiary-ultralight);
    margin-top: 48.4px;
    margin-bottom: 66.7px;
    margin-right: 50px;
    margin-left: 50px;
}

@media screen and (max-width: 1300px) {
    #csrIntro {
        margin-top: 30.6px;
        margin-bottom: 58px;
    }
    #csrIntro img {
        width: 456px;
    }
    #csrIntro div {
        margin-top: -30px;
        width: calc(100% - 100px);
    }
    #csrIntro div h1 {
        font-size: 34px;
        line-height: 1.18;
        margin-top: 41.4px;
        margin-bottom: 41.4px;
        margin-right: 50px;
        margin-left: 50px;
    }
}

@media screen and (max-width: 800px) {
    #csrIntro {
        margin-top: 54.3px;
        margin-bottom: 28.8px;
    }
    #csrIntro img {
        width: 270px;
    }
    #csrIntro div {
        margin-top: -20px;
        width: calc(100% - 40px);
    }
    #csrIntro div h1 {
        font-size: 20px;
        line-height: 1.3;
        margin-top: 21.8px;
        margin-bottom: 24px;
        margin-right: 25px;
        margin-left: 25px;
    }
}