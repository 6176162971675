.parents_education {
  position: relative;
  margin: 0px 0px 165px 0px;
}

.parents_education_content {
  width: 1050px;
  margin: 0px 0px 0px 618px;
}

.parents_education img {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: -45px;
}

.parents_education h1 {
  margin-bottom: 12px;
  font-family: "Manrope-Bold";
  font-size: 50px;
  line-height: 1.24;
  color: var(--dark-slate-blue);
}

.parents_education h2 {
  margin-bottom: 41px;
  font-family: "Manrope-Bold";
  font-size: 34px;
  line-height: 1.24;
  color: var(--dark-slate-blue);
}

.parents_education h3 {
  width: 1000px;
  margin-bottom: 57px;
  font-family: "Manrope-Regular";
  font-size: 20px;
  line-height: 1.3;
  color: var(--secondary-default);
}

.parents_education_wrapper p {
  font-family: "Manrope-Medium";
  font-size: 16px;
  line-height: 1.5;
  color: var(--secondary-heavy);
}

.parents_education_wrapper p.link {
  font-family: "Manrope-Medium";
  font-size: 16px;
  line-height: 1.5;
  color: var(--main-default);
  cursor: pointer;

}

.parents_education_wrapper p.link:hover {
  text-decoration: underline;
}

.parents_education_wrapper {
  font-family: "Manrope-Medium";
  display: grid;
  grid-template-columns: repeat(3, 32%);
  gap: 20px;
}

.parents_education_wrapper>div {
  display: flex;
}

.parents_education_wrapper>div>p:nth-of-type(1) {
  margin-right: 10px;
}

.parents_education_wrapper>div:nth-of-type(3)>div>p,
.parents_education_wrapper>div:nth-of-type(3)>div>p.link {
  margin: 0;
  display: inline;
}

@media only screen and (max-width: 1750px) {
  .parents_education_content {
    margin: 0px 0px 0px 518px;
  }

  .parents_education img {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    top: -70px;
    left: -218px;
  }
}

@media only screen and (max-width: 1620px) {
  .parents_education_content {
    margin: 0px 0px 0px 468px;
  }
}

@media only screen and (max-width: 1560px) {
  .parents_education_content {
    margin: 0px 0px 0px 598px;
    width: 850px;
  }

  .parents_education img {
    top: -60px;
    left: -118px;
  }

  .parents_education h3 {
    width: auto;
  }

  .parents_education h1 {
    width: 750px;
  }
}

@media only screen and (max-width: 1530px) {
  .parents_education_content {
    margin: 0px 0px 0px 498px;
  }

  .parents_education img {
    top: -60px;
    left: -218px;
  }
}

@media only screen and (max-width: 1400px) {
  .parents_education_content {
    margin: 0px 0px 0px 398px;
  }

  .parents_education img {
    left: -288px;
  }
}

@media only screen and (max-width: 1300px) {
  .parents_education_content {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .parents_education img {
    top: -125px;
    left: -268px;
  }

  .parents_education h1 {
    width: 650px;
    margin-top: 0px;
    margin-bottom: 30px;
  }

  .parents_education_upper_text {
    margin-left: 150px;
    width: 590px;
  }

  .parents_education_wrapper {
    width: 90%;
  }

  .parents_education {
    margin: 0px 0px 90px 0px;
  }

  .parents_education h3 {
    margin-bottom: 130px;
  }
}

@media only screen and (max-width: 1150px) {
  .parents_education_upper_text {
    margin-left: 180px;
    width: 533px;
  }
}

@media only screen and (max-width: 999px) {
  .parents_education {
    margin: 0px 0px 105px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .parents_education h1 {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 30px;
  }

  .parents_education_upper_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
    width: 90%;
  }

  .parents_education_upper_text h3 {
    margin-bottom: 50px;
  }

  .parents_education img {
    width: 360px;
    top: auto;
    left: auto;
    position: relative;
    /* transform: translateX(-50%) !important; */
    margin-bottom: 40px;
    margin-top: -50px;
  }
}

@media only screen and (max-width: 770px) {
  .parents_education_wrapper {
    grid-template-columns: repeat(2, 47%);
  }
}

@media only screen and (max-width: 700px) {
  .parents_education img {
    width: 300px;
  }

  .parents_education h1 {
    width: 100%;
  }

  .parents_education h1 {
    margin-bottom: 32px;
    font-size: 26px;
    color: var(--tertiary-heavy);
  }

  .parents_education h2 {
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 2.1;
    text-align: center;
  }

  .parents_education h3 {
    margin-bottom: 65px;
    font-size: 16px;
    line-height: 1.5;
    color: var(--secondary-heavy);
  }
}

@media only screen and (max-width: 500px) {
  .parents_education_wrapper {
    grid-template-columns: repeat(1, 100%);
  }
}