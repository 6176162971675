section.recommendations {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 120px;
}

section.recommendations div.BUBBLE:nth-of-type(1) {
  margin-left: 25%;
}

section.recommendations div.BUBBLE:last-child {
  margin-right: 25%;
}

section.recommendations h1 {
  margin-bottom: 38.5px;
  width: 100%;
  font-family: "Manrope-Bold";
  font-size: 36px;
  line-height: 1.1;
  text-align: center;
  color: var(--tertiary-heavy);
}

section.carousel {
  width: 100%;
  height: 25px;
  content: "sads";
  display: flex;
  align-items: center;
  justify-content: center;
}

section.carousel>div {
  margin-top: 20px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--secondary-light);
  content: "";
}

section.carousel>div.active {
  width: 10px;
  height: 10px;
  background-color: var(--secondary-default);
}

section.carousel>div:not(:nth-of-type(1)) {
  margin-left: 8px;
}

#scrollContainer {
  height: fit-content;
  display: flex;
  overflow-x: scroll;

  margin-left: 0 !important;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  justify-content: flex-start;
}

#scrollContainer::-webkit-scrollbar {
  display: none;
}

#scrollContainer .text_card {
  cursor: pointer;
  margin-right: 42.3px;
  display: flex;
  align-items: center;
}

div.BUBBLE .img_hover {
  margin-right: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 189px;
  width: 189px;
}

div.BUBBLE .img_hover img:nth-of-type(1) {
  position: absolute;
  height: 140px;
  width: 140px;
  filter: drop-shadow(-1px 1px 5px rgba(0, 0, 0, 0.22));
}

div.BUBBLE .img_hover img:nth-of-type(2) {
  display: none;
  position: absolute;
  width: 175px;
  height: 175px;
}

div.BUBBLE .img_hover img:nth-of-type(3) {
  display: none;
  position: absolute;
}

div.BUBBLE.active .img_hover img:nth-of-type(2),
div.BUBBLE.active .img_hover img:nth-of-type(3),
div.BUBBLE:hover .img_hover img:nth-of-type(2),
div.BUBBLE:hover .img_hover img:nth-of-type(3) {
  display: block;
  animation: fadeIn ease 1s;
}

div.BUBBLE .text_bubble {
  width: 400px;
  height: 160px;
  position: relative;
  filter: drop-shadow(-2px 2px 2.6px rgba(0, 0, 0, 0.22));
}

div.BUBBLE .text_bubble img {
  z-index: -1;
  position: absolute;
  top: 0px;
}

div.BUBBLE .text_bubble img:nth-of-type(2) {
  display: none;
}

div.BUBBLE .text_bubble p {
  position: absolute;
  font-family: "Manrope-Regular";
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: var(--tertiary-heavy);
}

div.BUBBLE .text_bubble p:nth-of-type(1) {
  top: 10px;
  left: 43px;
  width: 340px;
}

div.BUBBLE .text_bubble p:nth-of-type(2) {
  bottom: 7px;
  left: 43px;
}

div.BUBBLE .text_bubble span {
  font-family: "Manrope-Bold";
}

@media only screen and (max-width: 760px) {
  div.BUBBLE .text_card {
    cursor: default;
    margin-right: 20.3px;
    flex-direction: column !important;
  }

  div.BUBBLE .img_hover {
    margin: 26.9px 0px 0px 0px;
    order: 2;
  }

  div.BUBBLE .img_hover>img:nth-of-type(2),
  div.BUBBLE .img_hover>img:nth-of-type(3) {
    display: block;
  }

  div.BUBBLE .text_card:hover>.img_hover>img:nth-of-type(2),
  div.BUBBLE .text_card:hover>.img_hover>img:nth-of-type(3) {
    animation: none;
  }

  div.BUBBLE .text_bubble {
    width: 320.3px;
    height: 210px;
    margin-top: 0px;
  }

  div.BUBBLE .text_bubble>img:nth-of-type(1) {
    display: none;
  }

  div.BUBBLE .text_bubble>img:nth-of-type(2) {
    display: block;
  }

  div.BUBBLE .text_bubble>p {
    font-size: 14px;
    top: 18px;
    left: 10px !important;
    width: 290px !important;
  }

  div.BUBBLE .text_bubble>p:nth-of-type(2) {
    top: auto;
    bottom: 10px !important;
  }

  div.BUBBLE .text_card:nth-of-type(2n)>.img_hover {
    order: 2;
  }

  div.BUBBLE .text_card:nth-of-type(2n)>.text_bubble>img {
    transform: rotateY(180deg);
  }

  div.BUBBLE .text_card:nth-of-type(2n)>.img_hover {
    margin-left: -20px;
  }

  div.BUBBLE .text_card:nth-of-type(2n)>.text_bubble>p {
    left: 22px;
    width: 294px;
  }
}

@media only screen and (max-width: 760px) {
  section.recommendations {
    margin-bottom: 71.7px;
  }

  section.recommendations h1 {
    margin-bottom: 36.3px;
    font-size: 26px;
    line-height: 1.23;
  }

  section.recommendations .BUBBLE:nth-of-type(1) {
    margin-left: 10px;
  }

  section.recommendations .BUBBLE:last-child {
    margin-right: 10px;
  }
}