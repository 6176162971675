.projectsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 150px;
    gap: 37px;
}

.projectsContainer .title {
    font-size: 50px;
    font-family: "Manrope-Bold"
}

.projectsContainer .content {
    max-width: 1500px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
}

.projectsContainer .content .description {
    font-size: 18px;
    text-align: center;
    font-family: "Manrope";
    font-weight: 200;
    line-height: 27px;
    word-spacing: 0px;
}

.projectsContainer .content .link {
    color: #2EA5DC;
    font-family: "Manrope";
    cursor: pointer;
}

.projectsContainer .content .info {
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 200;
    line-height: 19px;
    text-align: center;
    margin-top: 40px;
}

.projectsContainer .content .info .link {
    color: #2EA5DC;
    cursor: pointer;
}

.projectsContainer>.parteners {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85px;
    gap: 75px;
    margin-bottom: 75px;
    overflow-x: scroll;
    width: 100vw;
    max-width: 100vw;
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;

}


.projectsContainer>.parteners>img {
    height: 100%;
}




.projectsContainer>.parteners::-webkit-scrollbar {
    display: none;
}


@media screen and (max-width: 1650px) {
    .projectsContainer {
        padding: 0 100px;
    }

}


@media screen and (max-width: 1120px) {
    .projectsContainer {
        padding: 0 75px;
    }

}

@media screen and (max-width: 1024px) {
    .projectsContainer {
        padding: 0 50px;
    }

    .projectsContainer .content .description {
        font-size: 16px;
    }

}

@media screen and (max-width: 760px) {
    .projectsContainer {
        padding: 0 32px;
    }

    .projectsContainer .title {
        font-size: 40px;
    }

    .projectsContainer>.parteners {
        justify-content: flex-start;
        gap: 50px;
    }


    .projectsContainer>.parteners>img:last-child {
        margin-right: 50px;
    }


    .projectsContainer>.parteners>img:first-child {
        margin-left: 50px;
    }


    .projectsContainer .content .link {
        text-align: center;
    }

}

@media screen and (max-width: 600px) {
    .projectsContainer .title {
        font-size: 26px;
    }
}

@media screen and (max-width: 520px) {
    .projectsContainer {
        padding: 0 26px;
    }
    .projectsContainer .content .description {
        font-size: 14px;
    }
    .projectsContainer .content .link {
        font-size: 14px;
    }
    .projectsContainer .content .info {
        font-size: 12px;
    }
}