.ministry_digitalization h1{
    width:820px;
    margin-left: 50%;
    transform: TranslateX(-50%);
    font-family: 'Manrope-Bold';
    font-size: 50px;
    line-height: 1.24;
    text-align: center;
    color: var(--dark-slate-blue);
    margin-bottom: 70px;
}
.ministry_digitalization_container{
    margin-bottom: 70px;
    padding: 34px 0px 120px 573px; 
    box-sizing: border-box;
    width: 1620.8px;
    border-radius:0px 20px 20px 0px;
    background-color: var(--dark-slate-blue);
}
.ministry_digitalization_container h2{
    margin: 0px 0px 84px 80px;
    width:625px;
    font-family: 'Manrope-Bold';
    font-size: 34px;
    line-height: 1.2;
    text-align: center;
    color: var(--tertiary-ultralight);
}
.ministry_digitalization_container p{
    font-family: 'Manrope-Regular';
    font-size: 16px;
    line-height: 1.63;
    color: var(--tertiary-ultralight);
}
.ministry_digitalization_container div{
    width:775px;
    margin-bottom: 55px;
    display:flex;
    align-items: center;
}
.ministry_digitalization_container div:nth-last-of-type(1){
    margin-bottom: 0px;
}
.ministry_digitalization_container div img{
    margin-right: 50px;
}

@media only screen and (max-width: 1900px){
    .ministry_digitalization_container{
        width: 1590px;
        padding: 34px 0px 120px 543px; 

    }
}
@media only screen and (max-width: 1850px){
    .ministry_digitalization_container{
        width: 1540px;
        padding: 34px 0px 120px 493px; 

    }
}
@media only screen and (max-width: 1750px){
    .ministry_digitalization_container{
        width: 1490px;
        padding: 34px 0px 120px 453px; 

    }
}
@media only screen and (max-width: 1650px){
    .ministry_digitalization_container{
        width: 1440px;
        padding: 34px 0px 120px 383px; 

    }
}
@media only screen and (max-width: 1550px){
    .ministry_digitalization_container{
        width: 1400px;
        padding: 34px 0px 120px 363px; 

    }
}
@media only screen and (max-width: 1480px){
    .ministry_digitalization_container{
        width: 1350px;
        padding: 34px 0px 120px 343px; 

    }
}
@media only screen and (max-width: 1440px){
    .ministry_digitalization_container{
        width: 1300px;
        padding: 34px 0px 120px 323px; 

    }
}
@media only screen and (max-width: 1400px){
    .ministry_digitalization_container{
        width: 1250px;
        padding: 34px 0px 120px 303px; 

    }
}
@media only screen and (max-width: 1350px){
    .ministry_digitalization_container{
        width: 1200px;
        padding: 34px 0px 120px 253px; 

    }
}
@media only screen and (max-width: 1250px){
    .ministry_digitalization{
        display:flex;
        flex-direction:column;
        align-items:center;
    }
    .ministry_digitalization_container h2{
        margin: 0px 0px 68px 0px;
    }
    .ministry_digitalization h1{
        margin-left: 0%;
        transform: TranslateX(0%);
    }
    .ministry_digitalization_container{
        border-radius:20px 20px 20px 20px;
        width: 95%;
        padding: 60px 0px 80px 0px; 
        display:flex;
        flex-direction: column;
        align-items: center;
    }
}
@media only screen and (max-width: 900px){
    .ministry_digitalization h1{
        font-size: 34px;
        width:90%;
        margin-bottom: 55px;
    }
    .ministry_digitalization_container h2{
        font-size: 26px;
        width:76%;
    }
    .ministry_digitalization_container div{
        width:85%;
    }
}
@media only screen and (max-width: 650px){
    .ministry_digitalization_container{
        padding: 34px 0px 80px 0px; 
        margin-bottom: 45px;
    }
    .ministry_digitalization h1{
        font-size: 32px;
        margin-bottom: 40px;
    }
    .ministry_digitalization_container h2{
        font-size: 26px;
        margin-bottom: 40px;
    }
    .ministry_digitalization_container p{
        font-size: 14px;
        line-height: 1.86;
    }
    .ministry_digitalization_container div{
        width:92%;
        margin-bottom: 55px;
        flex-direction: column;
    }
    .ministry_digitalization_container div img{
        margin:0px 0px 18px 0px;
    }
}
@media only screen and (max-width: 500px){
    .ministry_digitalization_container{
        width:100%;
        border-radius:0px 0px 0px 0px;
    }
}
