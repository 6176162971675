.parents_functionalities {
    height: auto;
    width: 100%;
    background-color: var(--main-default);
    margin-bottom: 106px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.parents_functionalities>.parents_video_container {
    width: 753px;
    height: 391px;
    background-color: red;
}

.parents_functionalities h1 {
    margin: 41px 0px 37.3px 0px;
    font-family: 'Manrope-Bold';
    font-size: 34px;
    line-height: 1.76;
    color: var(--tertiary-ultralight);
}

.parents_functionalities button {
    margin: 30.4px 29.5px 28.8px 0px;
}

@media only screen and (max-width: 900px) {

    .parents_functionalities>.parents_video_container {
        width: 481.45px;
        height: 250px;
    }

    .parents_functionalities h1 {
        font-size: 26px;
        line-height: 1.23;
        text-align: center;
    }

    .parents_functionalities {
        margin-bottom: 76px;
    }

}

@media only screen and (max-width: 520px) {
    .parents_functionalities>.parents_video_container {
        width: 350px;
        height: 175px;
    }

    .parents_functionalities h1 {
        margin: 34px 0px 24px 0px;
        max-width: 85%;
    }

    .parents_functionalities {
        margin-bottom: 44.5px;
    }

    .parents_functionalities button {
        margin-right: 22.5px;
    }
}