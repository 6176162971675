.adservio_mobile{
    width:100%;
    margin-bottom: 162px;
}
.adservio_mobile_container{
    position:relative;
    width: 1468.8px;
    background-color: var(--macaroni-and-cheese);
    border-radius: 0px 12px 12px 0px;
}
.adservio_mobile_container>img:nth-of-type(1){
    position: absolute;
    left:687px;
    top:-156px;
}
.adservio_mobile_content{
    overflow: auto;
    width:470px;
    margin: 0px 0px 0px 228.4px;
}
.adservio_mobile_content h1{
    margin:40px 0px 16px 0px;
    font-family: 'Manrope-Bold';
    font-size: 34px;
    line-height: 1.2;
    color: var(--secondary-heavy);
}
.adservio_mobile_content h2{
    margin-bottom: 47px;
    font-family: 'Manrope-Regular';
    font-size: 16px;
    line-height: 1.2;
    color: var(--tertiary-heavy);
}

.adservio_mobile_button>img:nth-of-type(2){
    display:none;
}
.adservio_mobile_button{
    cursor:pointer;
}

@media only screen and (min-width:1200px){
    .adservio_mobile_button{
        display:flex;
        align-items: center;
        box-sizing: border-box;
        height: 90px;
        width: 435px;
        margin-bottom: 10px;
        padding: 0px 28.5px 0px 33.9px; 
        border: solid 1px transparent;
    }
    .adservio_mobile_button:hover{
        justify-content: space-between;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px var(--tertiary-ultralight);
    }
    .adservio_mobile_button:hover > img:nth-of-type(2) {
        display: block !important;
    }
}
@media only screen and (max-width:1600px){
    .adservio_mobile_container{
        width: 1368.8px;
    }
    .adservio_mobile_container>img:nth-of-type(1){
        left:587px;
    }
    .adservio_mobile_content{
        margin: 0px 0px 0px 128.4px;
    }
}
@media only screen and (max-width:1450px){
    .adservio_mobile_container{
        width: 1268.8px;
    }
    .adservio_mobile_container>img:nth-of-type(1){
        left:487px;
    }
    .adservio_mobile_content{
        margin: 0px 0px 0px 29.4px;
    }
}
@media only screen and (max-width:1200px){
    .adservio_mobile{
        margin-bottom: 110px;
    }
    .adservio_mobile_container>img:nth-of-type(1){
        left:450px;
        top:-230px;
    }
    .adservio_mobile_content h1{
        margin:112.7px 0px 16px 0px;   
    }
    .adservio_mobile_content h2{
        margin-bottom: 84.3px;
    }
    .adservio_mobile_content{
        width:390px;
    }
    .adservio_mobile_button{
        width:fit-content;
        height: 47.8px;
    }
    .adservio_mobile_wrapper{
        margin-bottom: 68.7px;
        display:grid;
        grid-template-columns: repeat(2,161.2px);
        justify-content: space-between;
        width:355px;
        row-gap: 30px;
    }
}
@media only screen and (max-width:760px){
    .adservio_mobile{
        margin-bottom: 47px;
    }
}
@media only screen and (max-width:650px){
    .adservio_mobile_container{
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        border-radius:0px 0px 0px 0px;
    }
    .adservio_mobile_container>img:nth-of-type(1){
        position:relative;
        width: 375px;
        height: 478.7px;
        left:0;
        top:0;
        margin-top:-210px;
    }
    .adservio_mobile_content{
        display:flex;
        flex-direction: column;
        align-items: center;
        margin:0;
        width:370px;
    }
    .adservio_mobile_content h1{
        margin:0px 0px 19px 0px;
        font-size: 26px;
        text-align: center;
        width: 370px;
    }
    .adservio_mobile_content h2{
        margin-bottom: 35px;
    }
    .adservio_mobile_wrapper{
        margin-bottom: 42px;
    }
}
@media only screen and (max-width:450px){
    .adservio_mobile_content h1{
        width: 300px;
    }
    .adservio_mobile_content{
        width:330px;
    }
    .adservio_mobile_wrapper{
        grid-template-columns: repeat(2,149.3px);
        width:330px;
        row-gap: 18px;
    }
    .adservio_mobile_button>img:nth-of-type(1){
        width: 149.3px;
        height: 44.2px;
    }
}