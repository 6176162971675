nav {
    margin-left: 72px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: calc(100% - 30px);
    margin-top: 30px;
    position: relative;
    margin-bottom: 4px;
    margin-right: 20px;

}

nav .language-selector {
    position: absolute;
    top: -45px;
    right: 0;
    z-index: 10;
    display: none;
}

nav.add-border:after {
    content: "";
    border-bottom: 2px solid var(--secondary-light);
    position: absolute;
    width: calc(100vw - 72px - 54px - 140.1px);
    top: calc(100% - 2px);
    z-index: inherit;
}

nav ul {
    display: flex;
    height: calc(100% - 6px);
    position: relative;
}

nav ul li {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;


}

nav ul li:not(:nth-of-type(1)) {
    margin-left: 58px;
}

.tooltip-item-mobile-title {
    display: none;
}


nav ul li.active::after {
    content: "";
    background-color: var(--secondary-heavy);
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -4px;
    z-index: 1;
}

nav ul li.active {
    z-index: 1;
}

nav ul li a.with-left-divider::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    left: -29px;
    top: 0;
    background: var(--secondary-ultralight);
}

nav ul li a:active,
nav ul li a:focus,
nav ul li:focus,
nav ul li {
    outline: none !important;
    border: none;
    outline-style: none;
    -moz-outline-style: none;
    background-color: var(--tertiary-ultralight);
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

nav ul li:hover>h2,
nav ul li.active>h2 {
    color: var(--secondary-heavy);
    outline: none;
}

nav ul li h2 {
    font-family: "Manrope-Medium";
    font-size: 20px;
    line-height: 1.2;
    text-transform: uppercase;
    color: var(--main-default);
    position: relative;
}

a {
    text-decoration: none;
    color: inherit;
}

nav ul li h2 span {
    position: inherit;
    display: none;
}

nav ul li div.tooltip {
    position: absolute;
    background-color: var(--tertiary-ultralight);
    border: solid 1px var(--secondary-ultralight);
    box-shadow: 0 0 4px 0 rgba(153, 153, 153, 0.16);
    width: max-content;
    max-width: 301px;
    height: max-content;
    max-width: 240px;
    top: calc(50% + 28px);
    left: -20px;
    padding: 19px 23.3px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: none;
}

nav ul li div.tooltip:after {
    content: "";
    display: block;
    position: absolute;
    margin-left: 10px;
    top: -11.45px;
    width: 20px;
    height: 20px;
    background: var(--tertiary-ultralight);
    border: 1px solid var(--secondary-ultralight);
    border-bottom: none;
    border-right: none;
    transform: rotate(45deg);
    box-shadow: -2px -2px 4px 0 rgba(153, 153, 153, 0.16);
}

nav ul li div.tooltip section {
    font-family: "Manrope";
    margin-bottom: 16.3px;
    /* text-transform: lowercase; */
}

nav ul li div.tooltip>a:last-child section {
    margin-bottom: 0;
}

nav ul li div.tooltip section:hover p,
nav ul li div.tooltip section:hover h4 {
    color: var(--secondary-heavy) !important;
}

nav ul li div.tooltip section.active p,
nav ul li div.tooltip section.active h4 {
    color: var(--secondary-heavy) !important;
}

nav ul li div.tooltip section h4 {
    color: var(--turquoise-blue);
    font-size: 16px;
    line-height: 1.05;
}

nav ul li div.tooltip section p {
    color: var(--secondary-default);
    font-size: 12px;
    line-height: 1.4;
}




nav ul li:hover div.tooltip {
    display: flex;
}

@media only screen and (max-width: 1800px) {
    nav ul li:not(:nth-of-type(1)) {
        margin-left: 45px;
    }

    nav ul li a.with-left-divider::before {
        left: -22.5px;
    }
}

@media only screen and (max-width: 1600px) {
    nav {
        margin-left: 25px;
    }

    nav::after {
        width: calc(100vw - 25px - 54px - 140.1px);
    }

    nav ul li:not(:nth-of-type(1)) {
        margin-left: 28px;
    }

    nav ul li a.with-left-divider::before {
        left: -14px;
    }
}

@media only screen and (max-width: 1460px) {
    header {
        margin-bottom: 24px;
    }

    nav {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;


    }

    nav ul li {

        padding-bottom: 5px;

    }

    nav ul li div.tooltip {
        top: calc(50% + 24px);


    }

    nav ul li div.tooltip::before {
        content: " ";
        width: 100%;
        height: 25px;
        z-index: 3;
        background-color: transparent;
        position: absolute;
        top: -25px;
        left: -25px;
    }



    nav ul li.active::after {
        top: auto;
        bottom: auto;
        bottom: -20px;
        position: absolute;

    }



    nav.add-border::after {
        top: auto;
        bottom: -20px;
        width: 100vw !important;
    }

    nav ul li:not(:nth-of-type(1)) {
        margin-left: 32px;
    }

    nav ul li a.with-left-divider::before {
        left: -16px;
    }
}

@media only screen and (max-width: 1350px) {
    nav ul li div.tooltip {
        max-width: 200px;
    }


    nav ul li div.tooltip section h4 {
        font-size: 15px;
    }

    nav ul li div.tooltip section p {
        font-size: 11px;
    }

    nav ul li h2 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1250px) {
    nav ul li h2 {
        font-size: 17px;
    }
}

@media only screen and (max-width: 1100px) {
    nav ul li div.tooltip {
        max-width: 150px;
    }

    nav ul li div.tooltip section h4 {
        font-size: 14px;
    }

    nav ul li div.tooltip section p {
        font-size: 10px;
    }




    nav ul li h2 {
        font-family: "Manrope";
        font-size: 16px;
        font-weight: 500;
        line-height: 0.9;
        text-align: left;
    }
}



@media only screen and (max-width: 925px) {




    nav ul li:not(:nth-of-type(1)) {
        margin-left: 24px;
    }

    nav ul li a.with-left-divider::before {
        left: -12px;
    }
}

@media only screen and (max-width: 860px) {
    nav {
        background-color: var(--tertiary-ultralight);
        margin-left: 0;
        margin-top: 12.4px;
        margin-bottom: 50px;
        display: none;
        width: 100vw;
        border: none;
    }

    nav::after {
        display: none;
    }

    nav ul {
        width: calc(100vw - 64px);
        margin-top: 0;
        margin-left: 32px;
        height: 100%;
        flex-wrap: wrap;
    }

    nav ul li {
        margin-top: 50px;
        margin-left: 0 !important;
        margin-right: 0;
        margin-top: 32px;
        width: 50%;
        order: 0;
    }

    nav ul li a.with-left-divider::before {
        display: none;
    }

    nav ul li:nth-of-type(4n + 1) h2,
    nav ul li:nth-of-type(4) h2 {
        color: var(--secondary-default);
    }

    nav ul li h2 span {
        display: block;
    }

    nav ul li:nth-of-type(4n + 1),
    nav ul li:nth-of-type(4) {
        order: -1;
        width: 100%;
    }

    nav ul li:hover:after,
    nav ul li.active::after {
        display: none;
    }

    nav ul li h2 {
        width: 100%;
        font-size: 20px;
        line-height: 0.72;
    }

    nav ul li h2 span {
        display: inline-block;
    }

    nav ul li:nth-of-type(4) div.tooltip a:nth-of-type(2) h4 {
        display: none;
    }

    nav ul li:nth-of-type(4) div.tooltip a:nth-of-type(2) h4.tooltip-item-mobile-title {
        display: block;
    }

    nav ul li h4 {
        margin-bottom: 3px;
    }

    nav ul li div.tooltip {
        position: static;
        top: 0;
        left: 0;
        background-color: var(--tertiary-ultralight);
        border: none;
        box-shadow: none;
        width: 100%;
        max-width: 100%;
        height: max-content;
        margin-top: 16px;
        margin-left: 0px;
        padding: 0;
        z-index: 3;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
    }

    a:nth-of-type(odd) .subMenuItem {
        box-sizing: border-box;
        padding-right: 15px;
    }

    nav ul li div.tooltip:after {
        display: none;
    }

    nav ul li div.tooltip a {
        width: 50%;
        max-width: 50%;
    }

    nav ul li div.tooltip section {
        width: 100% !important;
        max-width: 100% !important;
        margin-bottom: 16.3px;
    }

    nav ul li div.tooltip section:nth-of-type(2n + 1) {
        width: calc(50% - 30px);
    }

    nav ul li div.tooltip section:nth-of-type(2n) {
        padding-left: 30px;
    }

    nav ul li div.tooltip section h4 {
        color: var(--turquoise-blue);
        font-size: 16px;
        line-height: 1.05;
    }

    nav ul li div.tooltip section p {
        color: var(--secondary-default);
        font-size: 12px;
        line-height: 1.4;
    }

    nav ul li div.tooltip section p::first-letter,
    nav ul li div.tooltip section h4::first-letter {
        text-transform: capitalize;
    }

    nav ul li:hover div.tooltip {
        display: flex;
    }

    header nav .language-selector {
        display: block;
    }
}

@media only screen and (max-width: 500px) {
    nav ul {
        width: calc(100vw - 22px);
        margin-top: 35px;
        margin-left: 22px;
        height: 100%;
        flex-wrap: wrap;
    }

    nav ul li {
        margin-left: 0 !important;
        margin-right: 0;
        margin-top: 10px;
        width: 100%;
        order: 0;
    }

    nav ul li:nth-of-type(4n + 1) h2,
    nav ul li:nth-of-type(4) h2 {
        color: var(--secondary-default);
        font-family: "Manrope";
        font-size: 12px;
        font-weight: 500;
        line-height: 1.2;
        text-align: left;
    }

    nav ul li:nth-of-type(5n + 1)>h2>a,
    nav ul li:nth-of-type(4)>h2>a {
        text-transform: lowercase;
        display: block;
    }

    nav ul li:nth-of-type(5n + 1)>h2>a::first-letter,
    nav ul li:nth-of-type(4)>h2>a::first-letter {
        text-transform: uppercase;
    }

    nav ul li:nth-of-type(4n + 1),
    nav ul li:nth-of-type(4) {
        margin-top: 0;
    }

    nav ul li h2 span {
        display: block;
    }

    nav ul li:nth-of-type(4n + 1),
    nav ul li:nth-of-type(4) {
        order: -1;
        width: 100%;
    }

    nav ul li:hover:after,
    nav ul li.active::after {
        display: none;
    }

    nav ul li h2 {
        width: 100%;
        font-size: 20px;
        line-height: 0.72;
    }

    nav ul li h2 a {
        text-transform: lowercase;
        display: block;
    }

    nav ul li h2 a::first-letter {
        text-transform: uppercase;
    }

    nav ul li h2 span {
        display: inline-block;
    }

    nav ul li:nth-of-type(4) div.tooltip a:nth-of-type(2) h4 {
        display: none;
    }

    nav ul li:nth-of-type(4) div.tooltip a:nth-of-type(2) h4.tooltip-item-mobile-title {
        display: block;
    }

    nav ul li h4 {
        margin-bottom: 3px;
    }

    nav ul li div.tooltip {
        position: static;
        top: 0;
        left: 0;
        background-color: var(--tertiary-ultralight);
        border: none;
        box-shadow: none;
        width: 100%;
        max-width: 100%;
        height: max-content;
        margin-top: 4px;
        margin-left: 0px;
        padding: 0;
        z-index: 3;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        margin-bottom: 15px;
    }

    a:nth-of-type(odd) .subMenuItem {
        padding-right: 0;
    }

    nav ul li div.tooltip:after {
        display: none;
    }

    nav ul li div.tooltip a {
        width: 100%;
        max-width: 100%;
    }

    nav ul li div.tooltip section {
        width: 100% !important;
        max-width: 100% !important;
        margin-bottom: 0;
    }

    nav ul li div.tooltip section:nth-of-type(2n + 1) {
        width: calc(50% - 30px);
    }

    nav ul li div.tooltip section:nth-of-type(2n) {
        padding-left: 30px;
    }

    nav ul li div.tooltip section h4 {
        font-family: "Manrope";
        font-size: 16px;
        line-height: 1.05;
        text-align: left;
        color: var(--turquoise-blue);
        margin-bottom: 8px;
    }

    nav ul li div.tooltip section p {
        display: none;
    }

    nav ul li div.tooltip section p::first-letter,
    nav ul li div.tooltip section h4::first-letter {
        text-transform: capitalize;
    }

    nav ul li:hover div.tooltip {
        display: flex;
    }
}