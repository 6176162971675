#shareModal {
  z-index: 1000;
  position: fixed;
  display: none;
}

#shareModal .content {
  position: fixed;
  z-index: 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: var(--tertiary-ultralight);
  width: max-content;
  height: max-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}

#shareModal .backgroundContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: rgba(51, 51, 51, 0.75);
  z-index: -1;
}

#shareModal h1 {
  font-family: "Manrope";
  font-size: 26px;
  font-weight: bold;
  line-height: 1.23;
  text-align: center;
  color: var(--tertiary-heavy);
  margin-top: 60px;
}

#shareModal p {
  font-family: "Manrope";
  font-size: 16px;
  line-height: 1.38;
  text-align: center;
  color: var(--main-heavy);
  margin-left: 125px;
  margin-right: 125px;
}

#shareModal p:first-child {
  margin-top: 16px;
}

#shareModal div.send {
  display: flex;
  margin-top: 70px;
  justify-content: center;
}

#shareModal div.send div.optionCard,
#shareModal div.send div.optionCard>.react-share__ShareButton {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

#shareModal div.send div.optionCard:not(:nth-of-type(1)) {
  margin-left: 90px;
}

#shareModal div.send div.optionCard:nth-of-type(1) img {
  width: 65.5px;
  height: 38.5px;
}

#shareModal div.send div.optionCard:nth-of-type(2) img {
  width: 48px;
  height: 48px;
}

#shareModal div.send div.optionCard:nth-of-type(3) img {
  width: 50px;
  height: 50px;
}

#shareModal div.send div.optionCard span {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: var(--tertiary-ultralight);
  padding: 8.7px 19px 9.3px;
  border-radius: 25px;
  cursor: pointer;
}

#shareModal div.send div.optionCard:nth-of-type(1) span {
  background-color: var(--action-accent);
  margin-top: 20px;
}

#shareModal div.send div.optionCard:nth-of-type(2) span {
  background-color: var(--confirmation-accent);
  margin-top: 13px;
}

#shareModal div.send div.optionCard:nth-of-type(3) span {
  background-color: var(--purpley-pink);
  margin-top: 12px;
}

#shareModal .horizontalLine {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

#shareModal .horizontalLine span {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 1.71;
  text-align: center;
  color: var(--secondary-default);
  background-color: var(--tertiary-ultralight);
  margin-top: 0;
  padding-left: 16.5px;
  padding-right: 23px;
}

#shareModal .horizontalLine::after {
  width: 100%;
  height: 1px;
  position: absolute;
  background-color: var(--secondary-light);
  content: "";
  left: 0;
  margin-top: 12px;
  z-index: -1;
}

#shareModal .share {
  margin-top: 32px;
  margin-bottom: 56px;
  display: flex;
  justify-content: center;
}

#shareModal .share img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

#shareModal .share button:not(:nth-of-type(1)) {
  margin-left: 45px;
}

#shareModal .close-modal {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}