.intro_schools {
    display: flex;
    position: relative;
    width: 100%;
    position: "fixed";
}

.intro_container {
    width: 965px;
    margin-left: 13.2%;
}

.intro_container h1 {
    font-family: "Manrope-Bold";
    font-size: 50px;
    line-height: 1.2;
    color: var(--dark-slate-blue);
}

.intro_container h2 {
    font-family: "Manrope-Bold";
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 1.2;
    color: var(--action-accent);
}

.intro_container p {
    display: inline;
    font-family: "Manrope-Medium";
    font-size: 20px;
    line-height: 1.3;
    color: var(--tertiary-heavy);
}

.advantages_box p {
    font-size: 16px;
    line-height: 1.5;
    color: var(--secondary-heavy);
}

.advantages {
    display: flex;
    justify-content: space-between;
}

.advantages_box {
    width: 31%;
}

.intro_buttons_container>button:nth-of-type(2) {
    margin-left: 30px;
}

p.intro_packages-link {
    font-family: 'Manrope-Regular';
    font-size: 16px;
    color: var(--main-default);
    cursor: pointer;
}

p.intro_packages-link:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 368px) {
    .intro_buttons_container>button {
        font-size: 12px;
    }
}