#adservio-brd {
  margin-bottom: 123px;
  display: flex;
}

#adservio-brd .titleContainer {
  background-color: var(--robins-egg-blue);
  border-radius: 0 12px 12px 0;
  max-height: 380px;
  width: 850px;
}

#adservio-brd .titleContainer h1 {
  font-family: "Manrope";
  font-size: 34px;
  font-weight: bold;
  line-height: 1.18;
  text-align: center;
  color: var(--dark-slate-blue);
  margin-left: 179px;
  margin-right: 69px;
  margin-top: 144px;
  margin-bottom: 146px;
}

#adservio-brd .content {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 850px - 30px);
  margin-left: 30px;
}

#adservio-brd .content .logos {
  display: flex;
  align-items: center;
  width: fit-content;
}

#adservio-brd .content .logos span {
  font-family: "Manrope";
  font-size: 40px;
  font-weight: bold;
  line-height: 0.6;
  text-align: left;
  color: var(--dark-slate-blue);
  width: 65px;
  text-align: center;
  transform: translateY(7px);
}

#adservio-brd .content .logos img:first-child {
  height: 68px;
  width: 256px;
}

#adservio-brd .content .logos img:last-child {
  height: 128.3px;
  width: 240.4px;
}

#adservio-brd .content .textContainer {
  max-width: 730px;
}

#adservio-brd .content .textContainer p {
  margin-top: 20px;
  font-family: "Manrope";
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--tertiary-heavy);
}

@media screen and (max-width: 1700px) {
  #adservio-brd .titleContainer {
    width: 669px;
  }
  #adservio-brd .titleContainer h1 {
    margin-left: 100px;
    margin-right: 69px;
    margin-top: 144px;
    margin-bottom: 146px;
  }
  #adservio-brd .content {
    width: calc(100vw - 669px - 30px);
  }
}

@media screen and (max-width: 1450px) {
  #adservio-brd {
    margin-bottom: 78.5px;
    flex-direction: column;
  }
  #adservio-brd .titleContainer {
    background-color: var(--robins-egg-blue);
    border-radius: 0 12px 12px 0;
    max-height: 380px;
    width: calc(100vw - 250px);
  }
  #adservio-brd .titleContainer h1 {
    font-size: 36px;
    line-height: 1.11;
    margin-left: 85px;
    margin-right: 40px;
    margin-top: 63px;
    margin-bottom: 63px;
  }
  #adservio-brd .content {
    width: calc(100% - 160px);
    margin-left: 80px;
  }
  #adservio-brd .content .logos {
    width: 100%;
    justify-content: center;
  }
  #adservio-brd .content .textContainer {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  #adservio-brd .content .textContainer p {
    margin-top: 20px;
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 800px) {
  #adservio-brd {
    margin-bottom: 39.2px;
  }
  #adservio-brd .titleContainer {
    max-height: 380px;
    width: calc(100vw - 49px);
  }
  #adservio-brd .titleContainer h1 {
    font-family: "Manrope";
    font-size: 20px;
    font-weight: bold;
    line-height: 1.05;
    margin-left: 22px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 26.5px;
    text-align: left;
  }
  #adservio-brd .content {
    width: calc(100% - 44px);
    margin-left: 22px;
  }
  #adservio-brd .content .logos span {
    font-family: "Manrope";
    font-size: 21px;
    font-weight: bold;
    line-height: 0.6;
    text-align: left;
    color: var(--dark-slate-blue);
    width: 35px;
    text-align: center;
  }
  #adservio-brd .content .logos img:first-child {
    height: 37.6px;
    width: 158.1px;
  }
  #adservio-brd .content .logos img:last-child {
    height: 70.9px;
    width: 132.9px;
  }
  #adservio-brd .content .textContainer {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  #adservio-brd .content .textContainer p {
    margin-top: 20px;
    width: 100%;
    font-size: 14px;
    line-height: 1.43;
  }
}
