header button {
  outline: none;
  border: none;
  cursor: pointer;
}

header a.loginMenuBtn {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 40px;
  width: max-content;
  color: var(--main-default);
  font-size: 20px;
  font-family: "Open Sans";
  line-height: 0.72;
  border: solid 1px var(--main-default);
  border-radius: 20px;
  margin-top: 66px;

  background-color: var(--tertiary-ultralight);
  box-sizing: border-box;
  padding: 0 10px;

}

header .authContainer {
  display: flex;
  justify-content: flex-start;
}


header a.loginMenuBtn:hover {
  color: var(--tertiary-ultralight);
}

header a.loginMenuBtn img {
  height: 26px;
}

header a.loginMenuBtn img.hoverImg {
  display: none;
}

header a.loginMenuBtn p {
  text-transform: lowercase !important;
  text-transform: capitalize !important;
  margin: 0 2px 0 6.4px;
}

header a.loginMenuBtn:hover img {
  display: none;
}

header a.loginMenuBtn:hover img.hoverImg {
  display: block;
}

header a.loginMenuBtn:hover {
  background-color: var(--main-default);
  color: var(--tertiary-ultralight);
}

header button#hamburgerMenuBtn {
  display: none;
}

#nav-icon {
  width: 25px;
  height: 100%;
  position: relative;
  margin: 0;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  border: none !important;
  outline: none !important;

}

#nav-icon span {
  display: block;
  position: absolute;
  height: 3.7px;
  width: 100%;
  background: #2ea5dc;
  border-radius: 3.7px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 5px;
  transform-origin: left center;
}

#nav-icon span:nth-child(2) {
  top: 13.7px;
  transform-origin: left center;
}

#nav-icon span:nth-child(3) {
  top: 22.4px;
  transform-origin: left center;
}

#nav-icon.open span:nth-child(1) {
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
}

@media only screen and (max-width: 1700px) {
  header a.loginMenuBtn {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1600px) {
  header a.loginMenuBtn {
    margin-right: 0;
    font-size: 18px;
    width: max-content;
  }
}

@media only screen and (max-width: 1350px) {
  header a.loginMenuBtn {
    font-size: 16px;
    height: 35px;
    width: max-content;
  }
}

@media only screen and (max-width: 1250px) {
  header a.loginMenuBtn {
    font-size: 14px;
    height: 30px;
    width: max-content;
  }

  header a.loginMenuBtn img,
  header a.loginMenuBtn img.hoverImg {
    height: 22px;
  }

  header .language-selector {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 1100px) {
  header a.loginMenuBtn {
    height: 30px;
    width: max-content;
    font-size: 14px;
    line-height: 1.03;
    margin-top: 61px;
    margin-right: 40px;
  }

  header a.loginMenuBtn p {
    margin-left: 2.4px;
  }
}

@media only screen and (max-width: 1050px) {
  header a.loginMenuBtn {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 925px) {
  header a.loginMenuBtn {
    width: max-content;
    margin-top: 51px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1460px) {
  header .authContainer {
    position: absolute;
    right: 0;
    top: 15px;
    gap: 8px;
  }


  header a.loginMenuBtn {
    font-size: 14px;
    font-family: "Open Sans";
    line-height: 1.03;
    margin-top: 12.5px;
    top: 0;
    right: 50.3px;
  }

  header a.loginMenuBtn img,
  header a.loginMenuBtn img.hoverImg {
    height: 20px;
  }


}

@media only screen and (max-width: 860px) {
  header .authContainer .language-selector {
    display: none;
  }

  header button#hamburgerMenuBtn {
    display: block;
    position: absolute;
    height: 30px !important;
    width: 25px !important;
    margin-top: 13px;
    padding: 0 !important;
    right: 18px;
    background-color: transparent;
  }
}