.billing_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 120px;
}

.billing_container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 40px;
  gap: 80px;
}

.billing_illustration_container {
  position: relative;
  height: 825px;
}

.billing_illustration_coins {
  position: absolute;
  top: -25px;
  left: -25px;
  width: 100px;
  height: 100px;
}

.billing_illustration {
  height: 100%;
}

.billing_title {
  font-family: 'Manrope-Bold';
  font-size: 48px;
  line-height: 1.4;
  color: #34296E;
  text-align: center;
  margin: 0px 20px 70px 20px;
}

.billing_container_benefits {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}


.billing_container_benefit {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: 840px;
  padding: 20px 36px;
  border-radius: 16px;
  background-color: #F0F9FF;
  gap: 6px;
}

.billing_container_benefit_title {
  font-family: 'Manrope-Bold';
  font-size: 20px;
  color: #2EA5DC;
  line-height: 1.5;
}

.billing_container_benefit_description {
  font-family: 'Manrope-Medium';
  font-size: 16px;
  color: #333333;
  line-height: 1.5;
}

@media only screen and (max-width:1300px) {
  .billing_illustration_container {
    margin-left: -200px;
  }
}

@media only screen and (max-width:1250px) {
  .billing_title {
    font-size: 32px;
  }
}

@media only screen and (max-width:1100px) {
  .billing_container {
    gap: 0px;
    padding: 0px 20px;
  }

  .billing_container_benefit {
    padding: 20px 20px;
  }
}

@media only screen and (max-width:800px) {
  .billing_section {
    margin-bottom: 60px;
  }

  .billing_title {
    margin: 0px 20px 30px 20px;
  }

  .billing_container {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .billing_illustration_container {
    margin-left: 0px;
    height: 350px;
  }

  .billing_illustration_coins {
    top: -15px;
    left: -15px;
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width:500px) {

  .billing_container {
    padding: 0px;
  }

  .billing_container_benefit {
    border-radius: 0px;
  }

}