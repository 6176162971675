#intro_chain {
    margin-top: 100px;
}

#intro_chain img {
    position: absolute;
    z-index: -1;
    top: 0px;
    right: -120px;
}

#intro_chain h1 {
    margin-bottom: 70.7px;
}

#intro_chain .intro_buttons_container {
    margin-bottom: 206.5px;
}

#intro_chain .intro_buttons_container>div {
    display:flex;
    gap:20px;
    margin-bottom: 30px;
}


#intro_chain .advantages {
    margin-bottom: 58px;
}

@media only screen and (max-width: 1900px) {
    #intro_chain .intro_container {
        margin-left: 11.5%;
    }
}

@media only screen and (max-width: 1850px) {
    #intro_chain .intro_container {
        margin-left: 9.5%;
    }
}

@media only screen and (max-width: 1790px) {
    #intro_chain .intro_container {
        margin-left: 7.5%;
    }
}

@media only screen and (max-width: 1730px) {
    #intro_chain .intro_container {
        margin-left: 5.5%;
    }
}

@media only screen and (max-width: 1690px) {
    #intro_chain .intro_container {
        margin-left: 3.7%;
    }
}

@media only screen and (max-width: 1650px) {
    #intro_chain img {
        height: 550px;
        top: 10px;
        right: -110px;
    }
}

@media only screen and (max-width: 1560px) {
    #intro_chain img {
        height: 500px;
        top: 20px;
        right: -110px;
    }

    #intro_chain .intro_container {
        width: 850px;
    }

    #intro_chain .intro_buttons_container {
        margin-bottom: 106.5px;
    }

    #intro_chain h1 {
        font-size: 44px;
    }
}

@media only screen and (max-width: 1450px) {
    #intro_chain img {
        height: 470px;
        top: 30px;
        right: -110px;
    }
}

@media only screen and (max-width: 1400px) {
    #intro_chain .intro_container {
        width: 690px;
    }

    #intro_chain .advantages {
        flex-direction: column;
    }

    #intro_chain .advantages_box {
        margin-bottom: 24px;
        width: 100%;
    }

    #intro_chain .advantages_box:nth-last-of-type(1) {
        margin-bottom: 0px;
    }

    #intro_chain .advantages_box br {
        display: none;
    }

    #intro_chain .advantages {
        margin-bottom: 38px;
    }

    #intro_chain h1 {
        margin-bottom: 40px;
        font-size: 34px;
        width: 393px;
    }

    #intro_chain {
        position: relative;
        margin-top: 50px;
    }

    #intro_chain img {
        height: 467.4px;
        top: 80px;
        right: -188px;
    }
}

@media only screen and (max-width: 1200px) {
    #intro_chain .intro_container {
        width: 590px;
    }
}

@media only screen and (max-width: 1140px) {
    #intro_chain .intro_container {
        width: 497px;
    }

}

@media only screen and (max-width: 1000px) {
    #intro_chain {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }

    #intro_chain img {
        height: 450px;
        position: relative;
        top: 0px;
        left: 0px;
        margin: 30px 0px 30px 0px;
    }

    #intro_chain .intro_container {
        margin: 0px 0px 0px 0px;
        width: 90%;
    }

    #intro_chain h1 {
        text-align: center;
        width: auto;
    }
    #intro_chain .intro_buttons_container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 45px;
    }
    #intro_chain .intro_buttons_container>div{
        gap:10px;
        margin-bottom: 15px;
    }

    #intro_chain .advantages {
        margin-bottom: 47px;
    }

    #intro_chain .advantages_box {
        width: 80%;
        margin-bottom: 47px;
    }

    #intro_chain .advantages_box:nth-of-type(2)>h2 {
        text-align: right;
    }

    #intro_chain .advantages_box:nth-of-type(2)>p {
        display: block;
        text-align: right;
    }

    #intro_chain .advantages_box:nth-of-type(2) {
        margin-left: 20%;
    }
}

@media only screen and (max-width: 700px) {
    #intro_chain .advantages_box {
        width: 87.25%;
    }

    #intro_chain .advantages_box:nth-of-type(2) {
        margin-left: 12.75%;
    }

    #intro_chain img {
        height: 250px;
    }
}