#videoModal {
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: rgba(51, 51, 51, 0.75);
}


#videoModal #videoContainer {
  position: fixed;
  z-index: 0;
  width: 800px;
  height: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}

/* 
#videoModal #videoContainer html,
#videoModal #videoContainer body,
iframe {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

#videoModal .backgroundContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: rgba(51, 51, 51, 0.75);
  z-index: -1;
}*/

#videoModal button {
  box-sizing: border-box;
  background-color: var(--tertiary-ultralight);
  border: none;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  font-size: 25px;
  width: 35px;
  height: 35px;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: -35px;
  right: -35px;
  color: black;
  padding: 0 !important;
}

/*
@media only screen and (max-width: 900px) {
  #videoModal button {
    top: calc(100% + 15px);
    right: 50%;
    transform: translateX(50%);
  }

  #videoModal #videoContainer {
    width: 100vw;
    height: 375px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
  }
} */