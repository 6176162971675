.intro_students{
    width: 100%;
    display:flex;
    position: relative;
    flex-direction: column;
    margin-bottom: 73.5px;
}
.intro_students_content h1{
    width:850px;
    font-family: 'Manrope-ExtraBold';
    font-size: 50px;
    line-height: 1.24;
    color: var(--dark-slate-blue);
    margin:156.3px 0px 22px 250px;
}
.intro_students_text_container{
    width: 1228.2px;
    background-color: var(--turquoise-blue-two);
    padding: 78.2px 52.2px 89.5px 249px;
    box-sizing: border-box;
    border-radius: 0px 12px 12px 0px;
}
.intro_students_text_container p{
    font-family: 'Manrope-Regular';
    font-size: 20px;
    line-height: 1.4;
    color: var(--tertiary-ultralight);
}
.intro_students_wrapper{
    display:grid;
    /* grid-template-columns: 413px 462px; */
    column-gap:52px;
}
.intro_students_wrapper:nth-of-type(1){
    margin-bottom: 30px;
}
.intro_students img {
    position: absolute;
    top:57px;
    right:0px;
    z-index: -1;
}

@media only screen and (max-width: 1800px){
    .intro_students_content h1{
        margin:156.3px 0px 22px 150px;
    }
    .intro_students_text_container{
        width: 1128.2px;
        padding: 78.2px 52.2px 89.5px 149px;
    }
}
@media only screen and (max-width: 1700px){
    .intro_students img {
        width: 509.6px;
    }
}
@media only screen and (max-width: 1580px){
    .intro_students_content h1{
        margin:102px 0px 22px 50px;
    }
    .intro_students_text_container{
        width: 1028.2px;
        padding: 78.2px 52.2px 89.5px 50px;
    }
}
@media only screen and (max-width: 1480px){
    .intro_students_wrapper{
        grid-template-columns: 385px 380px;
        column-gap:35px;
        row-gap:30px;
    }
    .intro_students_text_container{
        width: 910.2px;
    }
}
@media only screen and (max-width: 1380px){
    .intro_students img {
        height: 430px;
        width: auto;
        top:20px;
    }
}
@media only screen and (max-width: 1350px){
    .intro_students_content br{
        display:none;
    }
    .intro_students_content h1{
        margin:102px 0px 65px 32px;
        width:650px;
    }
    .intro_students_text_container{
        width: 850px;
        padding: 37.1px 53px 37.1px 32px;
    }
    .intro_students_wrapper{
        grid-template-columns: 100%;
        column-gap:0px;
        row-gap: 25px;
    }
    .intro_students_wrapper:nth-of-type(1){
        margin-bottom: 25px;
    }
}
@media only screen and (max-width: 1200px){
    .intro_students_text_container{
        width: 676.4px;
    }
    .intro_students_content h1{
        margin:102px 0px 65px 32px;
        width:600px;
    }
}
@media only screen and (max-width: 1100px){
    .intro_students img {
        right:-70px;
    }
}
@media only screen and (max-width: 999px){
    .intro_students{
        align-items:center;
        margin-bottom: 50px;
    }
    .intro_students img {
        position: relative;
        top: 0;
        right:0;
        margin-top:20px;
        height:339px;
    }
    .intro_students_content h1{
        font-size: 36px;
        margin:30px 0px 40px 50%;
        width:85%;
        text-align:center;
        transform:translateX(-50%);
    }
    .intro_students_content{
        width:95%;
    }
    .intro_students_text_container{
        width: 100%;
        border-radius: 12px 12px 12px 12px;
    }
    .intro_students_wrapper{
        width:100%;
        grid-template-columns: 47% 47%;
        justify-content: space-between;
    }
}
@media only screen and (max-width: 800px){
    .intro_students_wrapper{
        grid-template-columns: 100%;
    }
}
@media only screen and (max-width: 600px){

    .intro_students{
        margin-bottom: 40px;
    }
    .intro_students_content{
        width:100%;
    }
    .intro_students_content h1{
        font-family: 'Manrope-Bold';
        font-size: 26px;
        line-height: 1.24;
        margin:21px 0px 21px 50%;
    }
    .intro_students_text_container{
        display:flex;
        flex-direction:column;
        border-radius: 0px 0px 0px 0px;
        padding: 0 0 0 0 ;
        background-color: transparent;
    }
    .intro_students_wrapper{
        box-sizing: border-box;
        width: 95%;
        background-color: var(--turquoise-blue-two);
        row-gap: 20px;
    }
    .intro_students_wrapper:nth-of-type(1){
        padding: 18.8px 12px 17.3px 23px;
        margin-bottom: 15px;
        border-radius: 0px 8px 8px 0px;
    }
    .intro_students_wrapper:nth-of-type(2){
        align-self: flex-end;
        border-radius: 8px 0px 0px 8px;
        padding: 13.8px 12px 22.3px 21px;
    }
    .intro_students_wrapper p{
        font-size: 16px;
    }
}
@media only screen and (max-width: 450px){
    .intro_students img {
        margin-left: 35%;
    }
}