.logo {
    padding: 0;
    background-image: url("../../../assets/general/header/logo-hover-off.svg");
    background-size: cover;
    width: 140.1px;
    height: 140.1px;
    margin-top: 17px;
    margin-left: 54px;
    cursor: pointer;
    z-index: 1;
}


.logoActive,
.logo:hover {
    background-image: url("../../../assets/general/header/logo-hover-on.svg");
}

@media only screen and (max-width: 1460px) {
    .logo {
        margin-top: 17px;
        margin-left: 17.7px;
        width: 102.5px;
        height: 102.5px;
    }
}

@media only screen and (max-width: 860px) {
    .logo {
        background-image: url("../../../assets/general/header/logo-mobile.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-left: 24px;
        height: 25px !important;
        width: 105px;
        margin-top: 11.2px;
    }

    .logoActive,
    .logo:hover {
        background-image: url("../../../assets/general/header/logo-mobile.svg");
    }
}