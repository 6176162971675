.ministry_electronic{
    margin: 625px 0px 50px 0px;
    width:100%;
    display:flex;
    justify-content:flex-end;
}
.ministry_electronic_container{
    box-sizing: border-box;
    padding:55px 0px 63px 185px;
    position: relative;
    width:1668px;
    border-radius:20px 0px 0px 20px;
    background-color: var(--main-default);
}
.ministry_electronic_container img{
    z-index: -2;
    width: 785.4px;
    position:absolute;
    top:-525px;
    left:335px;
}
.ministry_electronic_content{
    width:1050px;
    display:flex;
    flex-direction: column;
    align-items: center;
}
.ministry_electronic_content h1{
    margin-bottom: 62px;
    width:768px;
    font-family: 'Manrope-Bold';
    font-size: 50px;
    line-height: 1.24;
    text-align: center;
    color: var(--tertiary-ultralight);
}
.ministry_electronic_content p{
    font-family: 'Manrope-Regular';
    font-size: 26px;
    line-height: 1.57;
    color: var(--tertiary-ultralight);
}
.ministry_electronic_content p:nth-of-type(1){
    margin-bottom: 50px;
}
.ministry_electronic_content p:nth-of-type(2){
    margin-bottom: 65px;
}

@media only screen and (max-width: 1900px){
    .ministry_electronic_container{
        width:1618px;
    }
}
@media only screen and (max-width: 1850px){
    .ministry_electronic_container{
        width:1568px;
    }
}
@media only screen and (max-width: 1750px){
    .ministry_electronic_container{
        width:1518px;
    }
}
@media only screen and (max-width: 1650px){
    .ministry_electronic_container{
        width:1468px;
    }
}
@media only screen and (max-width: 1550px){
    .ministry_electronic_container{
        width:1418px;
    }
}
@media only screen and (max-width: 1500px){
    .ministry_electronic{
        margin: 500px 0px -42px 0px;
    }
    .ministry_electronic_container img{
        width: 616.3px;
        top:-365px;
    }
    .ministry_electronic_container{
        width:1318px;
        padding:55px 0px 63px 0px;
        display:flex;
        justify-content:center;
    }
}
@media only screen and (max-width: 1400px){
    .ministry_electronic_container{
        width:1218px;
        padding:55px 0px 30px 0px;
    }
    .ministry_electronic_content{
        width:90%;
    }
    .ministry_electronic_content p:nth-of-type(2){
        margin-bottom: 38px;
    }
}
@media only screen and (max-width: 1300px){
    .ministry_electronic_container img{
        left:200px;
    }
    .ministry_electronic_container{
        width:1118px;
    }
}
@media only screen and (max-width: 1200px){
    .ministry_electronic_container img{
        left:170px;
    }
    .ministry_electronic_container{
        width:1058px;
    }
}
@media only screen and (max-width: 1100px){
    .ministry_electronic_container img{
        left:148px;
    }
    .ministry_electronic_container{
        width:956px;
    }
}
@media only screen and (max-width: 999px){
    .ministry_electronic_container{
        padding:40px 0px 50px 0px;
        width:100%;
        border-radius:0px 0px 0px 0px;
    }
    .ministry_electronic_container img{
        left:50%;
        transform:translateX(-50%);
    }
    .ministry_electronic_content h1{
        margin-bottom: 33px;
        width:85%;
        font-size: 34px;
    }
    .ministry_electronic_content p{
        font-size: 20px;
        line-height: 1.3;
    }
    .ministry_electronic_content p:nth-of-type(1){
        margin-bottom: 32px;
    }
    .ministry_electronic_content p:nth-of-type(2){
        margin-bottom: 32px;
    }
}
@media only screen and (max-width: 760px){
    .ministry_electronic{
        margin: 305px 0px 45px 0px;  
    }
    .ministry_electronic_container img{
        width: 333.1px;
        top:-260px;
    }
}
@media only screen and (max-width: 600px){
    .ministry_electronic_content h1{
        width:100%;
        font-size: 26px;
    }
}
