.reasons {
  margin: 0px 0px 128px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reasons_container {
  position: relative;
  width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reasons_container>img:nth-of-type(1) {
  position: absolute;
  top: 175px;
  left: 4px;
}

.reasons_container>img:nth-of-type(2) {
  transform: rotateY(180deg);
  position: absolute;
  top: 452px;
  right: 4px;
}

.reasons_container_inside {
  width: 1020px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reasons_container_inside h1 {
  margin-bottom: 78.3px;
  width: 100%;
  font-family: "Manrope-Bold";
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: var(--tertiary-heavy);
}

.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 240px);
  row-gap: 55.7px;
  justify-content: space-between;
}

.reason {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reason:nth-of-type(3)>.reason_box>img:nth-of-type(1) {
  width: 78px;
  height: 78px;
}

.reason_box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 174px;
  height: 174px;
}

.reason_box>img:nth-of-type(2) {
  position: absolute;
  top: 0px;
}

.reason>.reason-text {
  font-family: "Manrope-Regular";
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: var(--secondary-heavy);
}

.reason>.reason-text span {
  font-size: 16px;
  line-height: 1.5;
}

@media only screen and (max-width: 1300px) {
  .reasons_container_inside {
    width: 880px;
  }

  .reasons_container>img:nth-of-type(1) {
    left: 74px;
  }

  .reasons_container>img:nth-of-type(2) {
    right: 74px;
  }
}

@media only screen and (max-width: 1000px) {
  .wrapper {
    grid-template-columns: repeat(3, 200px);
  }

  .reasons_container {
    width: 1385px;
  }

  .reasons_container>img:nth-of-type(1) {
    width: 450px;
    top: 62px;
    left: 0px;
  }

  .reasons_container>img:nth-of-type(2) {
    width: 450px;
    top: 260px;
    right: 0px;
  }

  .reasons_container_inside {
    width: 650px;
  }

  .reasons_container_inside>h1 {
    margin-bottom: 25.9px;
    font-size: 26px;
    line-height: 1.23;
  }

  .reason_box {
    width: 104.5px;
    height: 104.5px;
  }

  .reason:nth-of-type(1)>.reason_box>img:nth-of-type(1) {
    width: 58.2px;
    height: auto;
  }

  .reason:nth-of-type(2)>.reason_box>img:nth-of-type(1) {
    width: 49.8px;
    height: auto;
  }

  .reason:nth-of-type(3)>.reason_box>img:nth-of-type(1) {
    width: 27.7px;
    height: auto;
  }

  .reason:nth-of-type(4)>.reason_box>img:nth-of-type(1) {
    width: 30.9px;
    height: auto;
  }

  .reason:nth-of-type(5)>.reason_box>img:nth-of-type(1) {
    width: 31px;
    height: auto;
  }

  .reason:nth-of-type(6)>.reason_box>img:nth-of-type(1) {
    width: 48.7px;
    height: auto;
  }

  .reason>.reason-text {
    margin-top: -5px;
    font-size: 16px;
    line-height: 1.25;
  }

  .reason>.reason-text span {
    font-size: 12px;
    line-height: 1.33;
  }
}

@media only screen and (max-width: 700px) {
  .reasons {
    margin: 0px 0px 76px 0px;
  }

  .wrapper {
    grid-template-columns: repeat(2, 150px);
  }

  .reasons_container {
    width: 1010px;
  }

  .reasons_container>img:nth-of-type(1) {
    width: 398px;
    top: 68px;
    left: 0px;
  }

  .reasons_container>img:nth-of-type(2) {
    width: 395px;
    top: 483px;
    right: 0px;
  }

  .reasons_container_inside {
    width: 330px;
  }
}