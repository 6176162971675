.parents_intro {
    position: relative;
    margin: 94.2px 0px 165px 0px;
}

.parents_intro br {
    display: none;
}

.parents_intro h1 {
    position: relative;
    margin: 0px 0px 39.2px 256.5px;
    width: 730px;
    font-family: 'Manrope-Bold';
    font-size: 50px;
    line-height: 1.24;
    color: var(--dark-slate-blue);
}

.parents_intro img {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    top: 0px;
    right: -90px;
}

.parents_intro p {
    font-family: 'Manrope-Regular';
    font-size: 20px;
    line-height: 0.96;
    color: var(--tertiary-ultralight);
}

.parents_intro_content_text>div {
    margin-bottom: 24px;
}

.parents_intro_content_text>div:nth-last-of-type(1) {
    margin-bottom: 41.4px;
}

.parents_intro_content_text>div>p:nth-of-type(1) {
    margin-right: 10px;
}

.parents_intro_content {
    border-radius: 0px 12px 12px 0px;
    box-sizing: border-box;
    padding: 49px 0px 47px 256.5px;
    width: 987.7px;
    background-color: var(--dark-slate-blue);
}

.parents_intro_content_text>div {
    display: flex;
}

.parents_intro_buttons button:nth-of-type(1) {
    margin-right: 20px;
}

p.parents_intro_packages-link {
    margin-top: 31.5px;
    font-family: 'Manrope-Regular';
    font-size: 16px;
    line-height: 1;
    font-weight: normal;
    color: var(--main-background);
    cursor: pointer;
}

p.parents_intro_packages-link:hover {
    text-decoration: underline;
}

@media only screen and (max-width:1800px) {
    .parents_intro h1 {
        margin: 0px 0px 39.2px 180px;
    }

    .parents_intro_content {
        padding: 49px 0px 47px 180px;
        width: 900px;
    }
}

@media only screen and (max-width:1650px) {
    .parents_intro h1 {
        margin: 0px 0px 39.2px 110px;
    }

    .parents_intro_content {
        padding: 49px 0px 47px 110px;
        width: 830px;
    }
}

@media only screen and (max-width:1550px) {
    .parents_intro h1 {
        margin: 0px 0px 39.2px 70px;
    }

    .parents_intro_content {
        padding: 49px 0px 47px 70px;
        width: 790px;
    }
}

@media only screen and (max-width:1450px) {
    .parents_intro h1 {
        margin: 0px 0px 39.2px 40px;
        width: 720px;
    }

    .parents_intro_content {
        padding: 49px 0px 47px 40px;
        width: 650px;
    }

}

@media only screen and (max-width:1350px) {
    .parents_intro_content {
        width: 620px;
    }

    .parents_intro img {
        height: 480px;
        top: 60px;
        right: -80px;
    }

    p.parents_intro_packages-link {
        margin-top: 25.9px;

    }
}

@media only screen and (max-width:1150px) {
    .parents_intro img {
        height: 393px;
        top: 140px;
        right: -85px;
    }
}

@media only screen and (max-width:999px) {
    .parents_intro {
        margin: 30px 0px 35px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .parents_intro img {
        margin-left: 80px;
        margin-bottom: 40px;
        position: relative;
        height: 319px;
        top: 0px;
        right: 0px;
    }

    .parents_intro br {
        display: block;
    }

    .parents_intro h1 {
        margin: 0px 0px 16px 0px;
        width: 94%;
        font-size: 26px;
        text-align: center;
    }

    .parents_intro_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 12px 12px 12px 12px;
        padding: 24.5px 0px 40px 0px;
        width: 80%;
    }

    .parents_intro p {
        font-family: 'Manrope-Regular';
        font-size: 16px;
        line-height: 1.5;
        color: var(--tertiary-ultralight);
    }

    .parents_intro_content_text>div {
        margin-bottom: 5px;
    }

    .parents_intro_content_text>div:nth-last-of-type(1) {
        margin-bottom: 29px;
    }

    .parents_intro_content_text>div>p:nth-of-type(1) {
        margin-right: 3px;
    }

    p.parents_intro_packages-link {
        margin-top: 25.9px;

    }

}

@media only screen and (max-width: 700px) {
    .parents_intro_content {
        padding: 24.5px 19px 40px 19px;
        border-radius: 0px 0px 0px 0px;
        width: 100%;
    }
}