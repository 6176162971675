.parents_recommend {
    margin: 0px 0px 205.5px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.parents_recommend_container {
    background-color: var(--main-ultralight);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1297px;
    height: auto;
    position: relative;
}

.parents_recommend_container h1 {
    margin: 49px 0px 12px 0px;
    font-family: "Manrope-Bold";
    font-size: 34px;
    line-height: 0.71;
    color: var(--main-heavy);
}

.parents_recommend_container h2 {
    font-family: "Manrope-Medium";
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
    color: var(--tertiary-heavy);
}

.parents_recommend_container h2:nth-of-type(2) {
    margin-bottom: 41px;
}

.parents_recommend_container p {
    width: 654px;
    font-family: "Manrope-Medium";
    font-size: 16px;
    line-height: 1.63;
    text-align: center;
    color: var(--tertiary-heavy);
}

.parents_recommend_container>button {
    margin: 41px 0px 45px 0px;
    color: var(--main-heavy);
    border: solid 1px var(--main-heavy);
}

.parents_recommend_container>button:hover {
    background-color: var(--main-heavy);
    color: var(--main-ultralight);
}

.parents_recommend_container img {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
}

.parents_recommend_container img:nth-of-type(1) {
    top: -130px;
    left: -150px;
}

.parents_recommend_container img:nth-of-type(2) {
    bottom: -80px;
    right: -100px;
}

@media only screen and (max-width: 1700px) {
    .parents_recommend_container {
        width: 1097px;
    }
}

@media only screen and (max-width: 1350px) {
    .parents_recommend {
        margin: 0px 0px 120px 0px;
    }
    .parents_recommend_container h2 {
        margin: 0;
        display: inline;
    }
    .parents_recommend_container>div {
        width: 610px;
        text-align: center;
        margin-bottom: 41px;
    }
    .parents_recommend_container h1 {
        width: 580px;
        line-height: 1.12;
        text-align: center;
    }
    .parents_recommend_container {
        width: 807px;
    }
}

@media only screen and (max-width: 999px) {
    .parents_recommend_container {
        width: 100%;
    }
    .parents_recommend_container img:nth-of-type(1) {
        height: 147px;
        top: -75px;
        left: 0;
    }
    .parents_recommend_container img:nth-of-type(2) {
        height: 219.1px;
        bottom: -85px;
        right: -20px;
    }
}

@media only screen and (max-width: 875px) {
    .parents_recommend_container img:nth-of-type(1) {
        left: -30px;
    }
    .parents_recommend_container img:nth-of-type(2) {
        right: -80px;
    }
}

@media only screen and (max-width: 800px) {
    .parents_recommend {
        margin: 0px 0px 85px 0px;
    }
    .parents_recommend_container h1 {
        width: 90%;
        margin: 107px 0px 22px 0px;
        font-size: 26px;
        line-height: 1.23;
        color: var(--tertiary-heavy);
        text-align: center;
    }
    .parents_recommend_container div {
        text-align: left !important;
    }
    .parents_recommend_container h2 {
        display: block !important;
        text-align: left;
    }
    .parents_recommend_container h2:nth-of-type(1) {
        margin-bottom: 40px;
    }
    .parents_recommend_container div {
        margin-bottom: 0px;
        width: 90%;
    }
    .parents_recommend_container p {
        width: 90%;
        text-align: left;
    }
    .parents_recommend_container>button {
        margin: 50px 0px 92px 0px;
    }
}