#digitalEducation {
    width: 100%;
    margin-bottom: 97.6px;
    display: flex;
    flex-direction: column;
}

#digitalEducation .banner {
    margin-left: 372px;
    background-color: var(--dark-slate-blue);
    border-radius: 12px 0 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 106px;
    padding-top: 39.6px;
    padding-bottom: 37.6px;
    padding-right: 22px;
    justify-content: space-between;
}

#digitalEducation .banner h2 {
    font-family: "Manrope";
    font-size: 34px;
    font-weight: bold;
    line-height: 1.24;
    text-align: left;
    color: var(--tertiary-ultralight);
    margin-right: 50px;
    max-width: 970px;
}

#digitalEducation .banner img {
    align-self: flex-end;
    width: 130px;
    margin-bottom: -122px;
}

#digitalEducation .textContent {
    margin-top: 45px;
    max-width: 1000px;
    margin-left: 450px;
    display: flex;
    justify-content: space-between;
}

#digitalEducation .textContent .col {
    width: calc(50% - 35px);
}

#digitalEducation .textContent .col p {
    font-family: "Manrope";
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: var(--tertiary-heavy);
}

#digitalEducation .textContent .col p span {
    font-weight: bold;
}

#digitalEducation .textContent .col p:last-child {
    margin-top: 30px;
}

@media screen and (max-width: 1650px) {
    #digitalEducation .banner {
        margin-left: 200px;
    }
    #digitalEducation .textContent {
        margin-left: 250px;
    }
}

@media screen and (max-width: 1450px) {
    #digitalEducation .banner {
        margin-left: 100px;
    }
    #digitalEducation .textContent {
        margin-left: 150px;
    }
}

@media screen and (max-width: 1300px) {
    #digitalEducation .banner {
        margin-left: 50px;
        padding-left: 47px;
        padding-top: 38.6px;
        padding-bottom: 36.6px;
    }
    #digitalEducation .textContent {
        margin-top: 90px;
        margin-left: 84px;
        width: fit-content;
        margin-right: 215px;
    }
    #digitalEducation .banner img {
        margin-bottom: -120px;
    }
}

@media screen and (max-width: 850px) {
    #digitalEducation .banner {
        margin-left: 20px;
        padding-left: 24px;
        padding-top: 21px;
        padding-bottom: 29px;
        padding-right: 0;
        position: relative;
    }
    #digitalEducation .banner h2 {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.3;
        text-align: left;
    }
    #digitalEducation .banner img {
        width: 71.6px;
        position: absolute;
        right: 0;
        bottom: 73.4px;
    }
    #digitalEducation .textContent {
        margin-top: 68.4px;
        margin-left: 22px;
        width: fit-content;
        margin-right: 45px;
        flex-direction: column;
    }
    #digitalEducation .textContent .col {
        width: 100%;
        margin-bottom: 16px;
    }
}