.newsletter {
  position: relative;
  margin: 125px 0px 0 0;
  display: flex;
  height: 282px;
  width: auto;
  flex-direction: column;
  align-items: center;
}

.newsletter_container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 24px;
}

.newsletter_container h2 {
  margin-top: 37.1px;
  font-family: "Manrope-Regular";
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-slate-blue);
}

.newsletter_container h3 {
  margin-top: 11px;
  font-family: "Manrope-Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.95;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-slate-blue);
}

.newsletter_container form {
  text-align: center;
}

.newsletter_container input.newsletter_container_email {
  box-sizing: border-box;
  width: 320px;
  height: 40px;
  border: solid 1px var(--gray-200);
  border-radius: 10px;
  padding: 10px 16px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-family: "Open Sans-Regular";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.23;
  letter-spacing: normal;
  text-align: left;
  color: var(--gray-700);
}

.newsletter_container input.newsletter_container_email::placeholder {
  color: var(--gray-200);
}

.newsletter_container input.newsletter_container_email:hover {
  border: 1px solid var(--blue-300);
  box-shadow: 0px 0px 0px 4px #E0F2FE, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.newsletter_container input.newsletter_container_email::focus {
  border: 1px solid var(--blue-500);
  box-shadow: 0px 0px 0px 4px #E0F2FE, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.newsletter_container .form__input-error {
  color: var(--destructive-accent);
  font-family: "Open Sans-Regular";
  font-size: 12px;
  margin-top: 5px;
  text-align: left;
}

.newsletter_container input.newsletter_container_submit {
  background-color: var(--warning-500);
  border-color: transparent;
  color: var(--tertiary-ultralight);
  padding: 8px 16px;
  border-radius: 8px;
  outline: none;
  height: 40px;
  margin-top: 28.9px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  z-index: 15;
  cursor: pointer !important;
  width: fit-content !important;
}

.newsletter_container .form__input-success {
  color: var(--confirmation-accent);
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;
}

.newsletter>img:nth-of-type(2) {
  display: none;
}

@media only screen and (max-width: 1400px) {
  .newsletter {
    margin: 85px 0px 20px 0px;
  }
}

@media only screen and (max-width: 760px) {
  .newsletter {
    margin: -145px 0px 40px 0px;
    height: 400px;
    width: 100%;
  }

  .newsletter_container {
    margin-top: 0px;
    width: 400px;
    height: 300px;
  }

  .newsletter_container>input {
    width: 329.2px;
    margin: 25.1px 0px 31.9px 0px;
  }

  .newsletter_container h2 {
    font-family: "Manrope-Bold";
    margin-top: 137.9px;
    font-size: 20px;
    line-height: 1.25;
  }

  .newsletter_container h3 {
    margin: 0px 0px 3px 0px;
  }

  .newsletter>img:nth-of-type(1) {
    display: none;
  }

  .newsletter>img:nth-of-type(2) {
    display: block;
    z-index: -1;
  }

  .newsletter_container input.newsletter_container_email {
    width: 329.2px;
  }
}