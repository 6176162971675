#csrContact {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 148.8px;
  margin-left: 50%;
  transform: translateX(-50%);
}

#csrContact h1 {
  font-family: "Manrope";
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: var(--tertiary-heavy);
}

#csrContact h2 {
  font-family: "Manrope";
  font-size: 26px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--dark-slate-blue);
  margin-top: 9px;
  text-align: center;
  margin-bottom: 55px;
}

@media screen and (max-width: 1200ox) {
  #csrContact {
    margin-bottom: 75.8px;
  }
}

@media screen and (max-width: 750px) {
  #csrContact {
    margin-bottom: 95px;
  }
  #csrContact h1 {
    font-family: "Manrope";
    font-size: 26px;
    font-weight: bold;
    line-height: 1.23;
    text-align: center;
    color: var(--tertiary-heavy);
  }
  #csrContact h2 {
    font-family: "Manrope";
    font-size: 20px;
    font-weight: bold;
    line-height: 1.3;
    text-align: center;
    color: var(--dark-slate-blue);
    margin-top: 20px;
    margin-bottom: 33px;
  }
}
