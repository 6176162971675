.solutions {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 105px 0px 130px 0px;
}

.solutions>img:nth-of-type(1) {
    position: absolute;
    top: 150px;
    left: 65px;
    z-index: -1;
}

.solutions_container {
    width: 910.7px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.solutions h1 {
    font-family: "Manrope-Bold";
    font-size: 50px;
    line-height: 1.2;
    text-align: center;
    color: var(--tertiary-heavy);
    margin-bottom: 75px;
}

.solutions h2 {
    width: 94%;
    font-family: "Manrope-SemiBold";
    font-size: 26px;
    line-height: 1.23;
    color: var(--main-heavy);
}

.solutions p {
    font-family: "Manrope-Regular";
    font-size: 16px;
    line-height: 1.5;
    color: var(--tertiary-heavy);
}

.solution_box {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    padding: 0px 0px 0px 0px;
    background-color: var(--main-ultralight);
    margin-bottom: 24px;
}

.question_container {
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    padding: 23px 4.5% 24px 4.8%;
}

.slide_down_content {
    box-sizing: border-box;
    padding: 0px 4.5% 24px 4.8%;
}

.solutions button {
    margin-top: 45.1px;
}

.solution_box img {
    position: absolute;
    top: 35px;
    right: 30px;
}

.openBox {
    transform: rotate(0deg);
    transition-property: transform;
    transition-duration: 0.5s;
}

.closeBox {
    transform: rotate(-180deg);
    transition-property: transform;
    transition-duration: 0.5s;
}

.solution_box_row {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
}

.paragraph_start p {
    width: 100%;
}

.paragraph_start:nth-of-type(1) {
    position: relative;
    width: 45%;
}

.paragraph_start:nth-of-type(2) {
    position: relative;
    width: 43%;
}

.paragraph_start .colored_rectangle {
    position: absolute;
    top: 6px;
    left: -13px;
    width: 6px;
    height: 30px;
    background-color: var(--main-heavy);
}

.solution_box:nth-of-type(4) {
    margin-bottom: 0px;
}

@media only screen and (max-width: 1180px) {
    .solutions {
        margin: 35.1px 0px 47.8px 0px;
    }
    .solutions_container {
        width: 80%;
    }
    .solutions>img:nth-of-type(1) {
        position: relative;
        top: 0px;
        left: 0px;
        z-index: -1;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 800px) {
    .solutions h1 {
        margin-bottom: 45px;
    }
    .solutions h2 {
        font-size: 20px;
    }
    .solutions>img:nth-of-type(1) {
        max-width: 383.768px;
        width: 70%;
    }
    .solutions h1 {
        font-size: 26px;
        margin-bottom: 33px;
    }
    .solution_box h2 {
        width: 82%;
    }
    .solutions_container {
        width: 100%;
    }
    .solutions button {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .paragraph_start .colored_rectangle {
        position: absolute;
        top: 9px;
        left: -20px;
        width: 13px;
        height: 6px;
    }
    .solution_box_row {
        flex-direction: column;
        justify-content: default;
        margin-top: 0px;
    }
    .paragraph_start {
        width: 100% !important;
        margin-top: 25.7px;
    }
    .question_container {
        padding: 16.5px 5% 16.5px 5% !important;
    }
    .slide_down_content {
        box-sizing: border-box;
        padding: 0px 5% 20px 5%;
    }
}