#benefits {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#benefits .rolling_ball_container {
    width: 100%;
    height: 50px;
}

#benefits .rolling_ball {
    height: 50px;
    width: 50px;
    background-color: var(--secondary-heavy);
    border-radius: 25px;
    position: absolute;
    transform: translateX(-50%);
}

#benefits .benefits_container {
    width: 89.5%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

#benefits .h1_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

#benefits .benefits_icons_container {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0px;
    width: 100%;
    height: fit-content;
    overflow: scroll !important;
}

#benefits .benefits_icons_container::-webkit-scrollbar {
    display: none;
}

#benefits .benefits_icons_container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* Firefox */
}

#benefits .h1_container>h1 {
    margin-bottom: 60px;
    width: 1012px;
}

#benefits h1 {
    font-family: "Manrope-Bold";
    font-size: 50px;
    line-height: 1.2;
    text-align: center;
    color: var(--tertiary-heavy);
}

#benefits .benefits_icon_w_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 175px;
}

#benefits .benefits_icon_w_title:not(:nth-of-type(1)) {
    margin-left: 50px;
}

#benefits .benefits_icon_w_title p {
    margin-top: 15px;
    font-family: "Manrope-Bold";
    text-align: center;
    font-size: 20px;
    line-height: 1.1;
    color: var(--main-default);
    line-break: none;
}

#benefits .benefits_icon_w_title p.active {
    color: var(--secondary-heavy);
}

#benefits .benefits_box h1 {
    font-size: 34px;
    color: var(--tertiary-ultralight);
}

#benefits .benefits_abstract {
    width: 100%;
    border-radius: 12px 12px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--main-default);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 91%);
}

#benefits .benefits_abstract h1 {
    width: 100%;
    margin: 55px 0px 20px 0px;
    text-align: center;
}

.benefits_abstract_text {
    width: 82%;
    text-align: center;
    margin-bottom: 91.8px;
}

.benefits_abstract_text p {
    display: inline;
    width: 100%;
    font-family: "Manrope-Medium";
    font-size: 20px;
    line-height: 1.3;
    color: var(--tertiary-ultralight);
}

#benefits .benefits_content {
    border-radius: 20px 20px 12px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--dark-slate-blue);
    padding-bottom: 60px;
}

#benefits .benefits_blue_icons_container h1 {
    margin: 49px 0px 67px 0px;
}

#benefits .benefits_blue_icons_container {
    width: 70%;
}

#benefits .blue_icon_w_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#benefits .blue_icon_w_info p {
    margin-top: 16px;
    font-family: "Manrope-Medium";
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: var(--tertiary-ultralight);
}

#benefits .blue_icon_w_info img {
    width: 80px;
    height: 80px;
}

#benefits .functionalities {
    margin-top: 106px;
    width: 753px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#benefits .video_container {
    width: 100%;
    height: 391px;
    background-color: white;
}

#benefits .functionalities>h1 {
    width: 85%;
    margin-bottom: 37.5px;
}

#benefits .func_buttons {
    margin-top: 50px;
    display: flex;
    position: relative;
    z-index: 1;
}

#benefits .func_buttons button:nth-of-type(1) {
    margin-right: 23px;
}

#benefits .benefits_packages-link {
    font-family: 'Manrope-Regular';
    font-size: 16px;
    color: var(--tertiary-ultralight);
    margin-top: 29px;
    margin-bottom: -45px;
    cursor: pointer;
    z-index: 3;
}

#benefits .benefits_packages-link:hover {
    text-decoration: underline;
}

#benefits .benefits_star_box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: -10px 0px 0px 0px;
    width: 1291.3px;
    height: 488px;
    background-image: url(../../assets/schools/benefits/star-box.svg);
    background-repeat: no-repeat;
}

#benefits .benefits_star_box>h4 {
    position: relative;
    margin: 156px 0px 43.7px 0px;
    font-family: "Manrope-Bold";
    font-size: 34px;
    line-height: 1.24;
    text-align: center;
    color: var(--main-background);
}

#benefits .benefits_star_box>button {
    position: absolute;
    bottom: 27.3px;
}

#benefits .benefits_star_box_flex {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 34.3px 0px 60px;
    margin-bottom: 41px;
}

#benefits .benefits_star_box_column {
    width: 47%;
}

#benefits .benefits_star_box_column>h2 {
    margin-bottom: 16px;
    font-family: "Manrope-Bold";
    font-size: 20px;
    line-height: 1.2;
    color: var(--main-default);
}

#benefits .benefits_star_box_column>p {
    display: inline;
    font-family: "Manrope-Regular";
    font-size: 16px;
    line-height: 1.5;
    color: var(--tertiary-ultralight);
}

#benefits .benefits_icon_w_title:hover {
    cursor: pointer;
}

#benefits .benefits_icon_w_title img {
    height: 70%;
    max-height: 100px;
    opacity: 0.4;
}

#benefits .benefits_icon_w_title:hover img,
#benefits .benefits_icon_w_title img.active-staff-img {
    opacity: 1 !important;
}

#benefits #schoolmaster,
#benefits #teacher,
#benefits #auxiliar {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#benefits .wrapper {
    display: grid;
    justify-content: space-between;
    row-gap: 51px;
    grid-template-columns: repeat(4, 20%);
}

#benefits #students>.wrapper>.blue_icon_w_info:nth-last-of-type(1) {
    grid-row: 3;
    grid-column: 3/5;
}

#benefits #students>.wrapper>.blue_icon_w_info:nth-last-of-type(2) {
    grid-row: 3;
    grid-column: 2/4;
}

#benefits #students>.wrapper>.blue_icon_w_info:nth-last-of-type(3) {
    grid-row: 3;
    grid-column: 1/3;
}

#benefits #students>.wrapper>.blue_icon_w_info:nth-last-of-type(1)>p,
#benefits #students>.wrapper>.blue_icon_w_info:nth-last-of-type(2)>p,
#benefits #students>.wrapper>.blue_icon_w_info:nth-last-of-type(3)>p {
    width: 45%;
}

#benefits #educator.benefits_blue_icons_container {
    width: 950px !important;
}

#benefits #educator>.wrapper {
    grid-template-columns: repeat(3, 20%);
}

#benefits #schoolmaster>.wrapper:nth-of-type(1),
#benefits #teacher>.wrapper:nth-of-type(1) {
    width: 90%;
    grid-template-columns: repeat(3, 30%);
    justify-content: space-around;
}

#benefits #schoolmaster>.wrapper:nth-of-type(1)>.blue_icon_w_info,
#benefits #teacher>.wrapper:nth-of-type(1)>.blue_icon_w_info {
    flex-direction: row;
}

#benefits #schoolmaster>.wrapper:nth-of-type(1)>.blue_icon_w_info>img,
#benefits #teacher>.wrapper:nth-of-type(1)>.blue_icon_w_info>img {
    margin-right: 23px;
}

#benefits #schoolmaster>.wrapper:nth-of-type(1)>.blue_icon_w_info>p,
#benefits #teacher>.wrapper:nth-of-type(1)>.blue_icon_w_info>p {
    margin-top: 0px;
    text-align: left;
}

#benefits #auxiliar>.wrapper {
    grid-template-columns: repeat(3, 25%);
    justify-content: space-between;
}

#benefits #auxiliar>.wrapper>.blue_icon_w_info:nth-last-of-type(1) {
    grid-row: 2;
    grid-column: 2/4;
}

#benefits #auxiliar>.wrapper>.blue_icon_w_info:nth-last-of-type(1)>p {
    width: 50%;
}

#benefits #auxiliar>.wrapper>.blue_icon_w_info:nth-last-of-type(2) {
    grid-row: 2;
    grid-column: 1/3;
}

#benefits #auxiliar>.wrapper>.blue_icon_w_info:nth-last-of-type(2)>p {
    width: 50%;
}

@media only screen and (max-width: 1550px) {
    #benefits .benefits_blue_icons_container {
        width: 85%;
    }
}

@media only screen and (max-width: 1500px) {
    #benefits .benefits_star_box {
        margin: 20px 0px 40px 0px;
        width: 869.3px;
        height: 666px;
        background-image: url(../../assets/schools/benefits/star-border-tablet.svg);
    }

    #benefits .benefits_star_box>h4 {
        margin: 174.3px 0px 45.7px 0px;
    }

    #benefits .benefits_star_box_flex {
        flex-direction: column;
        justify-content: default;
        padding: 0px 97px 0px 59px;
        margin-bottom: 54px;
    }

    #benefits .benefits_star_box_column {
        width: 100%;
    }

    #benefits .benefits_star_box_column:nth-of-type(1) {
        margin-bottom: 54px;
    }

    #benefits .benefits_star_box_column>h2 {
        margin-bottom: 18px;
    }
}

@media only screen and (max-width: 1250px) {
    #benefits .benefits_container>.h1_container:nth-of-type(1)>h1 {
        width: 82.5%;
        font-size: 38px;
        margin-bottom: 41px;
    }

    #benefits #educator.benefits_blue_icons_container {
        width: 750px !important;
    }
}

@media only screen and (max-width: 1050px) {
    #benefits .benefits_abstract {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 95%);
    }

    #benefits .benefits_container {
        width: 100%;
    }

    .rolling_ball_container {
        height: 40px !important;
        display: flex;
        justify-content: center;
    }

    #benefits .rolling_ball {
        height: 40px;
        width: 40px;
        border-radius: 20px;
    }

    #benefits #schoolmaster>.wrapper:nth-of-type(1),
    #benefits #teacher>.wrapper:nth-of-type(1) {
        justify-content: space-between;
        row-gap: 31px;
        grid-template-columns: repeat(2, 45%) !important;
    }

    #benefits #schoolmaster>.wrapper:nth-of-type(1)>.blue_icon_w_info:nth-last-of-type(1),
    #benefits #teacher>.wrapper:nth-of-type(1)>.blue_icon_w_info:nth-last-of-type(1) {
        justify-content: center;
        grid-row: 2;
        grid-column: 1/3;
    }

    #benefits #schoolmaster>.wrapper:nth-of-type(1)>.blue_icon_w_info:nth-last-of-type(1)>p,
    #benefits #teacher>.wrapper:nth-of-type(1)>.blue_icon_w_info:nth-last-of-type(1)>p {
        width: 30% !important;
    }
}

@media only screen and (max-width: 900px) {
    #benefits .functionalities {
        margin-top: 56px !important;
        width: 481.45px !important;
    }

    #benefits .func_buttons button:nth-of-type(1) {
        margin-right: 10px !important;
    }

    #benefits .functionalities h1 {
        margin-bottom: 24px !important;
    }

    #benefits .video_container {
        height: 250px !important;
    }

    #benefits .benefits_star_box {
        margin: 20px 0px 35px 0px;
        width: 630px;
        height: 630px;
        background-image: url(../../assets/schools/benefits/star-border-tablet-2.svg);
    }

    #benefits .benefits_star_box>h4 {
        margin: 150px 0px 29px 0px;
        width: 430px;
    }

    #benefits .benefits_star_box_flex {
        flex-direction: column;
        justify-content: default;
        padding: 0px 40px 0px 30px;
        margin-bottom: 31px;
    }

    #benefits .benefits_star_box>button {
        bottom: 30px;
    }

    #benefits .benefits_star_box_column:nth-of-type(1) {
        margin-bottom: 34px;
    }

    #benefits .benefits_star_box_column>h2 {
        margin-bottom: 10px;
    }

    #benefits .benefits_star_box_column>p:nth-of-type(2) {
        margin-top: 17.3px;
    }
}

@media only screen and (max-width: 850px) {
    .benefits_abstract_text {
        margin-bottom: 50px;
        width: 80%;
        text-align: left;
    }

    .benefits_abstract_text p {
        margin-bottom: 20px;
        display: inline-block;
    }

    .benefits_abstract_text p:nth-last-of-type(1) {
        margin-bottom: 0px;
    }

    #benefits .benefits_abstract>h1 {
        margin: 26px 0px 18px 0px;
    }

    #benefits .wrapper {
        row-gap: 31px;
        grid-template-columns: repeat(2, 45%) !important;
    }


    #benefits #students>.wrapper>.blue_icon_w_info:nth-last-of-type(1) {
        grid-row: 6;
        grid-column: 1/3;
    }

    #benefits #students>.wrapper>.blue_icon_w_info:nth-last-of-type(2) {
        grid-row: 5;
        grid-column: 2/3;
    }

    #benefits #students>.wrapper>.blue_icon_w_info:nth-last-of-type(3) {
        grid-row: 5;
        grid-column: 1/2;
    }

    #benefits #educator>.wrapper>.blue_icon_w_info:nth-last-of-type(1) {
        grid-row: 5;
        grid-column: 1/3;
    }

    #benefits .wrapper>.blue_icon_w_info>p {
        width: 80% !important;
    }

    #benefits .wrapper>.blue_icon_w_info:nth-last-of-type(1)>p {
        width: 40% !important;
    }

    #benefits #headmaster>.wrapper>.blue_icon_w_info:nth-last-of-type(1)>p,
    #benefits #secretary>.wrapper>.blue_icon_w_info:nth-last-of-type(1)>p,
    #benefits #headteacher>.wrapper:nth-of-type(1)>.blue_icon_w_info:nth-last-of-type(1)>p,
    #benefits #private>.wrapper>.blue_icon_w_info:nth-last-of-type(1)>p,
    #benefits #private_chain>.wrapper>.blue_icon_w_info:nth-last-of-type(1)>p,
    #benefits #schoolmaster>.wrapper:nth-of-type(2)>.blue_icon_w_info:nth-last-of-type(1)>p,
    #benefits #teacher>.wrapper:nth-of-type(2)>.blue_icon_w_info:nth-last-of-type(1)>p {
        width: 80% !important;
    }


    #benefits #auxiliar>.wrapper>.blue_icon_w_info:nth-last-of-type(1) {
        grid-row: 3;
        grid-column: 1/3;
    }

    #benefits #auxiliar>.wrapper>.blue_icon_w_info:nth-last-of-type(1)>p {
        width: 100%;
    }

    #benefits #auxiliar>.wrapper>.blue_icon_w_info:nth-last-of-type(2) {
        grid-row: auto;
        grid-column: auto;
    }

    #benefits #auxiliar>.wrapper>.blue_icon_w_info:nth-last-of-type(2)>p {
        width: 100%;
    }

    #benefits #educator.benefits_blue_icons_container {
        width: 90% !important;
    }
}

@media only screen and (max-width: 650px) {
    #benefits .benefits_star_box {
        margin: 56px 0px 23px 0px;
        width: 334.9px;
        height: 753.6px;
        background-image: url(../../assets/schools/benefits/star-outline-mobile.svg);
    }

    #benefits .benefits_star_box>h4 {
        width: 300px;
        margin: 87.7px 0px 29px 0px;
        font-size: 20px !important;
    }

    #benefits .benefits_star_box_flex {
        padding: 0px 24px 0px 22px;
    }

    #benefits .benefits_star_box_column>p {
        display: inline-block;
    }

    #benefits .benefits_star_box>button {
        bottom: 30px;
    }
}

@media only screen and (max-width: 620px) {
    #benefits .benefits_blue_icons_container>h1 {
        margin: 30px 0px 36px 0px;
    }

    #benefits .benefits_container h1 {
        font-size: 26px !important;
        line-height: 1.23 !important;
    }

    #benefits .benefits_abstract p {
        font-size: 16px !important;
        line-height: 1.5 !important;
    }

    #benefits .blue_icon_w_info p {
        font-size: 14px !important;
        line-height: 1.43 !important;
    }

    #benefits .blue_icon_w_info img {
        height: 60px !important;
        width: 60px !important;
    }

    #benefits .wrapper>.blue_icon_w_info>p {
        width: 100% !important;
    }

    #benefits .wrapper>.blue_icon_w_info:nth-last-of-type(1)>p {
        width: 60% !important;
    }

    #benefits #headmaster>.wrapper>.blue_icon_w_info:nth-last-of-type(1)>p,
    #benefits #secretary>.wrapper>.blue_icon_w_info:nth-last-of-type(1)>p,
    #benefits #headteacher>.wrapper:nth-of-type(1)>.blue_icon_w_info:nth-last-of-type(1)>p,
    #benefits #private>.wrapper>.blue_icon_w_info:nth-last-of-type(1)>p,
    #benefits #private_chain>.wrapper>.blue_icon_w_info:nth-last-of-type(1)>p,
    #benefits #schoolmaster>.wrapper:nth-of-type(2)>.blue_icon_w_info:nth-last-of-type(1)>p,
    #benefits #teacher>.wrapper:nth-of-type(2)>.blue_icon_w_info:nth-last-of-type(1)>p {
        width: 100% !important;
    }
}

@media only screen and (max-width: 520px) {
    #benefits .functionalities {
        width: 350px !important;
    }

    #benefits .video_container {
        height: 175px !important;
    }

    #benefits #schoolmaster>.wrapper:nth-of-type(1),
    #benefits #teacher>.wrapper:nth-of-type(1) {
        justify-content: center;
        row-gap: 31px;
        grid-template-columns: repeat(1, 70%) !important;
    }

    #benefits #schoolmaster>.wrapper:nth-of-type(1)>.blue_icon_w_info:nth-last-of-type(1)>p,
    #benefits #teacher>.wrapper:nth-of-type(1)>.blue_icon_w_info:nth-last-of-type(1)>p {
        width: 100% !important;
    }

    #benefits #schoolmaster>.wrapper:nth-of-type(1)>.blue_icon_w_info:nth-last-of-type(1),
    #benefits #teacher>.wrapper:nth-of-type(1)>.blue_icon_w_info:nth-last-of-type(1) {
        grid-row: auto;
        grid-column: auto;
    }
}

@media only screen and (max-width: 370px) {
    #benefits .benefits_blue_icons_container {
        width: 95%;
    }
}

@media only screen and (max-width: 1300px) {
    #benefits .benefits_icon_w_title:not(:nth-of-type(1)) {
        margin-left: 25px;
    }

    #benefits .benefits_icons_container {
        height: fit-content;
    }

    #benefits .benefits_icon_w_title img {
        height: 55%;
        min-width: 100px;
        opacity: 0.4;
    }
}

@media only screen and (max-width: 949px) {
    #benefits .benefits_icons_container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 0px;
        width: 100%;
        height: fit-content;
        overflow-x: scroll !important;
    }

    #benefits .benefits_icons_container::-webkit-scrollbar {
        display: none;
    }

    #benefits .benefits_icons_container {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    #benefits .benefits_icon_w_title {
        margin-left: 50px !important;
        width: 200px !important;
    }

    #benefits .benefits_icon_w_title:nth-of-type(1) {
        margin-left: calc(50% - 50px) !important;
    }

    #benefits .benefits_icon_w_title:last-child {
        border-right: calc(50vw - 50px) solid transparent;
    }

    .rolling_ball_container {
        height: 40px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;
    }

    #benefits .rolling_ball {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        transform: translateX(0);
    }
}