header {
    height: 139px;
    background-color: var(--tertiary-ultralight);
    display: flex;
}

main {
    min-height: calc(100vh - 139px);
}

.authContainer {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 32px;
}


@media only screen and (max-width: 1100px) {
    header {
        height: 129px;
    }

    main {
        min-height: calc(100vh - 129px);
    }

    header .authContainer {
        margin-right: 0px !important;

    }

    header .authContainer .loginMenuBtn {
        margin-right: 0px !important;
    }


}

@media only screen and (max-width: 925px) {
    header {
        height: 109px;
    }

    main {
        min-height: calc(100vh - 109px);
    }


}

@media only screen and (max-width: 1460px) {
    header {
        min-height: 50px;
        height: fit-content;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    main {
        min-height: calc(100vh - 50px);
    }


}

@media only screen and (max-width: 860px) {

    header .authContainer {
        top: 8px !important;
        right: 60px !important;
    }

    header .authContainer .language-selector {
        display: none;
    }

    header nav .language-selector {
        top: 0;
        right: 0;
    }

    header .authContainer .loginMenuBtn {
        top: 0px;
        z-index: 3;
    }

    header .language-selector {
        display: none;
    }

    .authContainer {
        margin-top: 0px
    }
}