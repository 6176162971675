#contact {
  width: 85%;
  margin-top: 120px;
  margin-bottom: 253px;
  margin-left: 15%;
  display: flex;
}

#contact .contact-form {
  width: 440px;
}

#contact .contact-form h1 {
  font-family: "Manrope";
  font-size: 34px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--tertiary-heavy);
}

#contact .contact-form p {
  margin-top: 9px;
  font-family: "Manrope";
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  color: var(--tertiary-heavy);
}

#contact .contact-form form {
  width: 380px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

#contact .contact-form form label {
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--secondary-heavy);
  display: block;
}

#contact .contact-form form input {
  width: 100%;
  box-sizing: border-box;
  border: solid 1px var(--secondary-light);
  padding: 0 8px;
}

#contact .contact-form form .PhoneInputInput {
  border-left: none;
}

#contact .col {
  margin-left: 140px;
  flex: 1;
}

#contact .col .dataRecover h1 {
  font-family: "Manrope";
  font-size: 34px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: var(--tertiary-heavy);
}

#contact .col .dataRecover .content {
  margin-top: 16px;
  background-color: var(--secondary-ultralight);
  height: 195px;
  display: flex;
}

#contact .col .dataRecover .content img {
  width: 80px;
  height: 80px;
  margin-top: 31.5px;
  margin-left: 35px;
  margin-right: 20px;
}

#contact .col .dataRecover .content .textContainer h2 {
  margin-top: 59px;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--tertiary-heavy);
}

#contact .col .dataRecover .content .textContainer {
  flex: 1;
}

#contact .col .dataRecover .content > img:last-child {
  width: 195px;
  height: 198px;
  margin-top: 0;
  margin-right: 0;
  background-color: white;
}

#contact .col .dataRecover .content .textContainer > p {
  font-family: "Manrope";
  font-size: 12px;
  line-height: 1.2;
  text-align: left;
  color: var(--tertiary-heavy);
  margin-top: 5px;
}

#contact .col .dataRecover .content .textContainer div {
  display: flex;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
}

#contact .col .dataRecover .content .textContainer div img {
  height: 22px;
  width: 22px;
  margin: 0;
  margin-right: 7.6px;
}

#contact .col .dataRecover .content .textContainer div a {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  color: var(--main-default);
}

#contact .contact2 {
  margin-top: 56.6px;
}

#contact .contact2 h1 {
  font-family: "Manrope";
  font-size: 34px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--tertiary-heavy);
}

#contact .contact2 .row {
  display: flex;
  margin-top: 15px;
}

#contact .contact2 .container:first-child {
  background-color: var(--main-ultralight);
}

#contact .contact2 .container:nth-of-type(2) {
  background-color: var(--action-background);
}

#contact .contact2 .container {
  width: 300px;
  height: 280px;
}

#contact .contact2 .container .top {
  display: flex;
  align-items: center;
  margin-top: 14px;
}

#contact .contact2 .container .top img {
  width: 80px;
  height: 80px;
  margin-left: 11px;
  margin-right: 25px;
}

#contact .contact2 .container .top h3 {
  font-family: "Manrope";
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--tertiary-heavy);
}

#contact .contact2 .container p:nth-of-type(1) {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  color: var(--tertiary-heavy);
  margin-top: 17px;
  margin-left: 50px;
}

#contact .contact2 .container p:nth-of-type(2) {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: var(--tertiary-heavy);
  margin-top: 25px;
  margin-left: 50px;
}

#contact .contact2 .container p:nth-of-type(2) span {
  font-family: "Manrope";
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--tertiary-heavy);
}

img#bottomImg {
  position: absolute;
  height: 285.6px;
  width: 194.5px;
  bottom: 0.1px;
  margin-left: 0.1px;
}

br.br-mobile {
  display: none;
}

.captcha {
  height: 100px;
  background-color: chocolate;
  margin-top: 30px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 1750px) {
  #contact {
    width: 90%;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 1650px) {
  #contact .col {
    margin-left: 40px;
  }
  #contact {
    width: 95%;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 1300px) {
  #contact {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    margin-bottom: 116px;
    margin-top: 70px;
  }

  #contact .col {
    margin-left: 0px !important;
    margin-top: 60px;
  }
  #contact .col .dataRecover h1 {
    widows: 100%;
    text-align: center;
  }
  #contact .col .dataRecover .content {
    width: max-content;
    margin-left: auto;
  }
  #contact .contact2 {
    margin-top: 118px;
  }
  #contact .contact2 h1 {
    text-align: center;
  }
  #contact .contact2 .row {
    justify-content: center;
  }
}

@media only screen and (max-width: 925px) {
  img#bottomImg {
    display: none;
  }
}

@media only screen and (max-width: 770px) {
  #contact .contact-form {
    width: 337px;
  }
  #contact .contact-form form {
    width: 337px;
  }
  #contact .col {
    margin-left: 140px;
    flex: 1;
  }
  #contact .col .dataRecover h1 {
    width: calc(100% - 60px);
    font-family: "Manrope";
    font-size: 26px;
    font-weight: bold;
    line-height: 1.23;
    text-align: center;
    color: var(--tertiary-heavy);
    margin-left: auto;
    margin-right: auto;
  }
  #contact .col .dataRecover .content {
    margin-top: 35px;
    background-color: var(--secondary-ultralight);
    width: 100%;
    height: 180px;
  }
  #contact .col .dataRecover .content img {
    width: 60px;
    height: 60px;
    margin-top: 18px;
    margin-left: 21px;
    margin-right: 13px;
  }
  #contact .col .dataRecover .content .textContainer h2 {
    margin-top: 30px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    text-align: left;
    color: var(--tertiary-heavy);
    align-self: center;
    max-width: 350px;
  }
  #contact .col .dataRecover .content .textContainer > p {
    font-family: "Manrope";
    font-size: 12px;
    line-height: 1.2;
    text-align: left;
    color: var(--tertiary-heavy);
    margin-top: 40px;
    position: absolute;
    left: 21px;
  }
  #contact .col .dataRecover .content .textContainer div {
    position: absolute;
    left: 22px;
    margin-top: 75px;
  }
  #contact .col .dataRecover .content > img:last-child {
    position: absolute;
    /* margin-top: 180px; */
    right: 0;
    width: 180px;
    height: 180px;
  }
  #contact .contact2 {
    margin-top: 150px;
  }
  #contact .contact2 h1 {
    font-family: "Manrope";
    font-size: 26px;
    font-weight: bold;
    line-height: 1.23;
    text-align: center;
    color: var(--tertiary-heavy);
  }
  #contact .contact2 .row {
    display: flex;
    margin-top: 33px;
  }
}
@media only screen and (max-width: 610px) {
  #contact .col .dataRecover .content > img:last-child {
    position: absolute;
    margin-top: 180px;
    right: 0;
    width: 112px;
    height: 112px;
  }
}
@media only screen and (max-width: 600px) {
  #contact .contact2 .container {
    width: calc(100vw / 2);
    height: 200px;
  }
  #contact .contact2 .container .top img {
    width: 50px;
    height: 50px;
    margin-left: 22px;
    margin-right: 15px;
  }
  #contact .contact2 .container .top h3 {
    font-size: 16px;
    margin-right: 15px;
  }
  #contact .contact2 .container p:nth-of-type(1) {
    font-family: "Manrope";
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
    color: var(--tertiary-heavy);
    margin-top: 13px;
    margin-left: 22px;
  }
  #contact .contact2 .container p:nth-of-type(2) {
    font-family: "Manrope";
    font-size: 14px;
    line-height: 1.14;
    text-align: left;
    color: var(--tertiary-heavy);
    margin-top: 7px;
    margin-left: 22px;
  }
  #contact .contact2 .container p:nth-of-type(2) span {
    font-family: Manrope;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
    color: var(--tertiary-heavy);
  }
}
@media only screen and (max-width: 450px) {
  br.br-mobile {
    display: block;
  }
  #contact .col .dataRecover .content .textContainer h2 {
    max-width: fit-content;
  }
}
