.btn2 {
  box-sizing: border-box;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  z-index: 15;
  animation: all 0.3s ease;
  border: 1px solid transparent;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.left_icon {
  width: 16px;
  height: 16px;
}

.btn2:disabled {
  opacity: 0.5;
}


.btn2.secondary.solid {
  background-color: var(--gray-700);
  color: var(--tertiary-ultralight);
}

.btn2.primary.solid:active {
  background-color: var(--warning-700);
}

.primary.solid {
  background-color: var(--warning-500);
  color: var(--tertiary-ultralight);
}

.primary.solid:hover {
  background-color: var(--warning-600);
}

.outlined.secondary {
  background-color: transparent;
  border-color: var(--blue-500);
  color: var(--blue-500);
}


.outlined.secondary:hover {
  background-color: var(--blue-500);
  color: var(--tertiary-ultralight);
}

.ghost {
  background-color: transparent;
  border-color: var(--tertiary-ultralight);
  color: var(--tertiary-ultralight);
}

.ghost.dark {
  background-color: transparent;
  border-color: var(--gray-700);
  color: var(--gray-700);
}

.ghost.dark:hover {
  background-color: var(--gray-700);
  color: var(--tertiary-ultralight);
  mix-blend-mode: normal;
}

.ghost:hover {
  background-color: var(--tertiary-ultralight);
  color: #000;
  mix-blend-mode: screen;
}



@media screen and (max-width: 1026px) {
  .btn2 {
    padding: 6px 12px;
    font-size: 14px;
  }

  .left_icon {
    width: 14px;
    height: 14px;
  }

}