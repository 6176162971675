.btn {
  padding: 8.5px 16px;
  border-radius: 20px;
  outline: none;
  color: var(--tertiary-ultralight);
  background-color: transparent;
  border: 1px solid #fff;
  height: 40px;
  cursor: pointer !important;
  font-family: "Open Sans", "sans-serif";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  z-index: 15;
  width: fit-content !important;
}

.btn.ghost:hover {
  background-color: var(--tertiary-ultralight);
  color: #000;
  mix-blend-mode: screen;
}

.btn:disabled {
  background-color: var(--secondary-light);
}

.btn.primary {
  background-color: var(--action-accent);
  border-color: transparent;
}

.btn.primary:hover {
  background-color: var(--pumpkin-orange);
}

.btn.primary-invert {
  color: var(--action-accent);
  border-color: var(--action-accent);
}

.btn.primary-invert:hover {
  background-color: var(--action-accent);
  color: var(--tertiary-ultralight);
  border-color: var(--tertiary-ultralight);
}

.btn.secodaryHeavy {
  background-color: var(--secondary-heavy);
  border-color: transparent;
}

.btn.secodaryHeavy:hover {
  background-color: #000;
}

.btn.actionAccent {
  background-color: var(--tertiary-ultralight);
  border-color: var(--action-accent);
  color: var(--action-accent);
}

.btn.actionAccent:hover {
  background-color: var(--action-accent);
  color: var(--tertiary-ultralight);
}

.btn.secodaryHeavy-invert {
  background-color: transparent;
  border-color: var(--secondary-heavy);
  color: var(--secondary-heavy);
}

.btn.secodaryHeavy-invert:hover {
  color: var(--macaroni-and-cheese);
  background-color: var(--secondary-heavy) !important;
}

.btn.blue-btn {
  background-color: transparent;
  border-color: var(--main-default);
  color: var(--main-default);
}

.btn.blue-btn:hover {
  color: var(--tertiary-ultralight);
  background-color: var(--main-default) !important;
}