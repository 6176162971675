#intro_public {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}

#intro_public>img:nth-of-type(1) {
    z-index: -1;
    width: 100%;
    margin-top: -510px;
}

#intro_public h1 {
    margin-bottom: 20px;
}

#intro_public .intro_buttons_container {
    margin-bottom: 160px;
    margin-top: 55px;
}

#intro_public .intro_buttons_container>div {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
    min-width: 300px;
}

#intro_public .advantages_public {
    display: flex;
    height: 290px;
    align-self: center;
    margin-bottom: 80px;
}

#intro_public .advantages_public h2 {
    font-family: "Manrope-Bold";
    font-size: 26px;
    text-align: center;
    color: var(--action-accent);
    margin-bottom: 17.4px;
}

#intro_public .advantages_public p {
    font-family: "Manrope-Medium";
    font-size: 16px;
    line-height: 1.25;
    text-align: center;
    color: var(--secondary-heavy);
    width: 310px;
}

#intro_public .advantages_box_public {
    background-image: url(../../../assets/schools/intro/circle.svg);
    background-position: top;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 400px;
    height: 290px;
    margin-right: 68px;
    box-sizing: border-box;
}

#intro_public .advantages_box_public:nth-of-type(3) {
    margin-right: 0px !important;
}

#intro_public .advantages_box_public>img:nth-of-type(1) {
    position: absolute;
    z-index: -2;
    top: 0px;
    left: 0px;
}

#intro_public .advantages_box_img {
    margin: 40px 0px 11px 0px;
    height: 97.5px;
    display: flex;
    align-items: flex-end;
}

#intro_public .advantages_box_public:nth-of-type(1) .advantages_box_img img {
    height: 97.5px;
}

#intro_public .advantages_box_public:nth-of-type(2) .advantages_box_img img {
    height: 82.3px;
}

#intro_public .advantages_box_public:nth-of-type(3) .advantages_box_img img {
    height: 92.9px;
}

@media only screen and (max-width: 1900px) {
    #intro_public .intro_container {
        margin-left: 11.5%;
    }
}

@media only screen and (max-width: 1850px) {
    #intro_public .intro_container {
        margin-left: 9.5%;
    }
}

@media only screen and (max-width: 1790px) {
    #intro_public .intro_container {
        margin-left: 7.5%;
    }
}

@media only screen and (max-width: 1730px) {
    #intro_public .intro_container {
        margin-left: 5.5%;
    }
}

@media only screen and (max-width: 1690px) {
    #intro_public .intro_container {
        margin-left: 3.7%;
    }
}

@media only screen and (max-width: 1480px) {
    #intro_public>img:nth-of-type(1) {
        margin-top: -460px;
    }
}

@media only screen and (max-width: 1450px) {
    #intro_public .advantages_public {
        margin-bottom: 60px;
        height: 260px;
    }

    #intro_public .advantages_public h2 {
        font-size: 22px;
        margin-bottom: 17.4px;
    }

    #intro_public .advantages_public p {
        font-size: 14px;
        width: 270px;
    }

    #intro_public .advantages_box_public {
        width: 350px;
        height: 260px;
        margin-right: 50px;
    }

    #intro_public .advantages_box_public>.advantages_box_img {
        margin: 30px 0px 25px 0px;
        height: 74.4px;
    }

    #intro_public .advantages_box_public:nth-of-type(1)>.advantages_box_img>img {
        height: 74.4px;
    }

    #intro_public .advantages_box_public:nth-of-type(2)>.advantages_box_img>img {
        height: 62.9px;
    }

    #intro_public .advantages_box_public:nth-of-type(3)>.advantages_box_img>img {
        height: 60.5px;
    }
}

@media only screen and (max-width: 1400px) {
    #intro_public .intro_container {
        width: 865px;
    }
}

@media only screen and (max-width: 1350px) {
    #intro_public {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 1230px) {
    #intro_public .intro_container {
        width: 765px;
    }

    #intro_public h1 {
        font-size: 44px;
    }

    #intro_public>img:nth-of-type(1) {
        margin-top: -410px;
    }

    #intro_public .advantages_public {
        margin-bottom: 40px;
        height: 230px;
    }

    #intro_public .advantages_public h2 {
        font-size: 20px;
        margin-bottom: 14px;
    }

    #intro_public .advantages_public p {
        font-size: 12px;
        width: 235px;
    }

    #intro_public .advantages_box_public {
        min-width: 305px;
        max-width: 305px;
        height: 230px;
        margin-right: 15px;
    }

    #intro_public .advantages_box_public>.advantages_box_img {
        margin: 30px 0px 20px 0px;
    }
}

@media only screen and (max-width: 1070px) {
    #intro_public .intro_container {
        width: 665px;
    }

    #intro_public h1 {
        font-size: 36px;
    }

    #intro_public .intro_buttons_container {
        margin-top: 35px;
    }
}

@media only screen and (max-width: 999px) {
    #intro_public .advantages_box_public:nth-of-type(1) {
        margin-left: 15px;
    }

    #intro_public .advantages_box_public:nth-of-type(3) {
        border-right: 15px solid transparent;
    }

    #intro_public .advantages_public {
        overflow-x: scroll;
        width: 100vw;
        justify-content: flex-start;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    #intro_public .advantages_public::-webkit-scrollbar {
        display: none;
    }

    #intro_public>img:nth-of-type(1) {
        margin-top: -370px;
    }
}

@media only screen and (max-width: 900px) {
    #intro_public {
        margin-top: 25px;
    }

    #intro_public .intro_container {
        width: 600px;
    }

    #intro_public>img:nth-of-type(1) {
        margin-top: -340px;
    }
}

@media only screen and (max-width: 820px) {
    #intro_public .intro_container {
        margin-left: 32px;
        width: 670px;
    }

    #intro_public .intro_container p {
        display: inline-block !important;
    }

    #intro_public .intro_content p:nth-of-type(1) {
        margin-bottom: 25px;
    }

    #intro_public .intro_content p:nth-of-type(2) {
        width: 80%;
    }
}

@media only screen and (max-width: 780px) {
    #intro_public>img:nth-of-type(1) {
        margin-top: -320px;
    }
}

@media only screen and (max-width: 720px) {
    #intro_public .intro_container {
        width: 90%;
    }
}

@media only screen and (max-width: 700px) {
    #intro_public>img:nth-of-type(1) {
        margin-top: -300px;
    }
}

@media only screen and (max-width: 670px) {
    #intro_public .intro_content>h1 {
        font-size: 32px;
        line-height: 1.23;
    }

    #intro_public .intro_content>p {
        font-size: 16px;
        line-height: 1.5;
    }

    #intro_public .intro_buttons_container>div {
        gap: 10px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 630px) {
    #intro_public>img:nth-of-type(1) {
        margin-top: -280px;
    }
}

@media only screen and (max-width: 580px) {
    #intro_public .intro_content>h1 {
        font-size: 26px;
    }
}

@media only screen and (max-width: 560px) {
    #intro_public>img {
        display: none;
    }

    #intro_public .intro_content p:nth-of-type(2) {
        width: 100%;
    }

    #intro_public>img:nth-of-type(1) {
        margin-top: -160px;
        margin-bottom: 40px;
    }

    #intro_public .advantages_public {
        margin-bottom: 64px;
    }

    #intro_public .intro_buttons_container {
        margin-top: 34px;
        margin-bottom: 64px;
    }

    #intro_public .intro_buttons_container>button:nth-of-type(2) {
        margin-left: 11px;
    }
}

@media only screen and (max-width: 500px) {
    #intro_public .intro_container {
        width: 87%;
    }

    #intro_public>img:nth-of-type(1) {
        margin-top: -150px;
    }
}