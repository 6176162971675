#steps {
  margin-bottom: 140px;
  display: flex;
  flex-direction: column;
}

#steps h1 {
  font-family: "Manrope";
  font-size: 50px;
  font-weight: bold;
  line-height: 0.84;
  text-align: center;
  color: var(--dark-slate-blue);
}

#steps .containerCards {
  margin-top: 50px;
  margin-bottom: 40px;
  display: flex;
  width: 100%;
  justify-content: center;
}

#steps .containerCards .card {
  box-sizing: border-box;
  padding: 32.8px 40px 0px 40px;
  width: 420px;
  height: 318px;
  border-radius: 12px;
  box-shadow: 0 0 36px 0 rgba(0, 76, 255, 0.16);
  background-color: var(--tertiary-ultralight);
  display: flex;
  flex-direction: column;
  align-items: center;
}
#steps .card_image_container {
  height: 90.3px;
  display: flex;
  align-items: flex-end;
}
#steps .containerCards .card:not(:first-child) {
  margin-left: 36px;
}

#steps .containerCards .card:nth-of-type(1) img {
  width: 86.2px;
  height: 85.9px;
}

#steps .containerCards .card:nth-of-type(2) img {
  width: 88.1px;
  height: 90.3px;
}

#steps .containerCards .card:nth-of-type(3) img {
  width: 76.2px;
  height: 76.2px;
}

#steps .containerCards .card h3 {
  font-family: "Manrope";
  font-size: 26px;
  font-weight: bold;
  line-height: 0.92;
  text-align: center;
  color: var(--dark-slate-blue);
  margin-top: 28.8px;
  margin-bottom: 25px;
}

#steps .containerCards .card p {
  font-family: "Manrope";
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  color: var(--dark-slate-blue);
}

#steps button {
  align-self: center;
}
#steps .containerCards .emptyCard {
  display: none;
}
@media screen and (max-width: 1350px) {
  #steps {
    margin-bottom: 130px;
  }
  #steps .containerCards {
    margin-bottom: 46px;
  }
  #steps .containerCards .card {
    padding: 32.8px 30px 0px 30px;
    width: 300px;
    height: 318px;
  }
  #steps .containerCards .card:not(:first-child) {
    margin-left: 24px;
  }
  #steps .containerCards .card h3 {
    font-family: "Manrope";
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    margin-top: 35.8px;
    margin-bottom: 16px;
  }
  #steps .containerCards .card p {
    font-size: 16px;
    line-height: 1.38;
  }
}

@media screen and (max-width: 970px) {
  #steps {
    margin-bottom: 182px;
  }
  #steps h1 {
    font-family: "Manrope";
    font-size: 26px;
    font-weight: bold;
    line-height: 1.23;
    text-align: center;
    margin: 0 66px;
  }
  #steps .containerCards {
    margin-top: 15px;
    margin-bottom: 20px;
    width: 100%;
    height: 290px;
    overflow-x: scroll;
    align-items: center;
    justify-content: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  #steps .containerCards::-webkit-scrollbar {
    display: none;
  }
  #steps .card_image_container {
    height: 70.3px;
  }
  #steps .containerCards .card {
    box-shadow: 0 0 36px 0 rgba(0, 76, 255, 0.16);
    padding: 24.3px 20px 0px 20px;
    min-width: 221.6px;
    width: 221.6px;
    height: 234.8px;
  }

  #steps .containerCards .card:not(:first-child) {
    margin-left: 24px;
  }
  #steps .containerCards .card:nth-of-type(1) img {
    width: 63.6px;
    height: 63.4px;
  }
  #steps .containerCards .card:nth-of-type(2) img {
    width: 68.1px;
    height: 70.3px;
  }
  #steps .containerCards .card:nth-of-type(3) img {
    width: 56.2px;
    height: 56.2px;
  }
  #steps .containerCards .card:first-child {
    margin-left: 25px !important;
  }
  #steps .containerCards .card:last-child {
    margin-right: 25px !important;
  }
  #steps .containerCards .card h3 {
    font-size: 16px;
    line-height: 1.6;
    margin-top: 25px;
    margin-bottom: 12px;
  }
  #steps .containerCards .card p {
    font-size: 12px;
    line-height: 1.25;
  }
}
@media screen and (max-width: 760px) {
  #steps .containerCards {
    justify-content: flex-start;
  }
  #steps .containerCards .emptyCard {
    display: block;
    width: 250px !important;
    background: transparent;
    color: transparent;
    content: "";
    box-sizing: border-box;
    height: 100%;
  }
}
