#container_benefits {
    background-color: var(--dark-slate-blue);
    width: 1366px;
    margin-left: 277px;
    margin-top: 85.8px;
    display: flex;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
}

#container_benefits h1 {
    font-family: "Manrope";
    font-size: 48px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: normal;
    color: var(--tertiary-ultralight);
    text-align: left;
    width: 100%;
    padding: 0;
    margin-bottom: 48px;
}

#container_benefits .beefits_content {
    display: flex;
    flex-direction: row;
}

#container_benefits .beefits_content>img {
    height: 565.3px;
    margin-left: -282.7px;
}

#container_benefits .beefits_content .textContainer {
    margin-top: 48px;
    margin-left: 125.3px;
    margin-right: 100px;
}

#container_benefits .textContainer .benefits .benefit {
    margin-bottom: 48px;
    display: flex;
}

#container_benefits .textContainer .benefits .benefit span {
    font-family: "Manrope";
    font-size: 48px;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
    color: var(--blue-200);
    margin-right: 16px;
    margin-top: -12px;
}


#container_benefits .textContainer .benefits .benefit section h4 {
    font-family: "Manrope";
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
    color: var(--blue-200);
}

#container_benefits .textContainer .benefits .benefit section p {
    font-family: "Manrope";
    font-size: 16px;
    line-height: 1.2;
    text-align: left;
    color: var(--tertiary-ultralight);
    margin-top: 8px;
}

#container_benefits .textContainer button {
    z-index: 10;
    margin-left: 50px;
    margin-bottom: 80px;
}

@media only screen and (max-width: 1650px) {
    #container_benefits {
        width: 1000px;
        margin-top: 75px;
        margin-top: 50px;
    }

    #container_benefits .beefits_content .textContainer {
        margin-left: 75.3px;
        margin-right: 123px;
    }
}

@media only screen and (max-width: 1290px) {
    #container_benefits {
        width: 900px;
        margin-top: 45px;
    }

    #container_benefits .beefits_content .textContainer {
        margin-left: 50px;
        margin-right: 50px;
    }
}

@media only screen and (max-width: 1200px) {
    #container_benefits {
        width: 100%;
        margin-left: 0;
        border-radius: 12px;
    }

    #container_benefits .beefits_content>img {
        display: none;
    }

    #container_benefits .textContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
    }

    #container_benefits .textContainer .benefits .benefit span {
        margin-top: 0;
    }

    #container_benefits .textContainer h1 {
        text-align: center;
        width: 100%;
    }

    #container_benefits .textContainer button {
        margin-bottom: 32px;
        margin-left: 0;
        margin-right: 0;
        align-self: center;
    }
}

@media only screen and (max-width: 750px) {
    #container_benefits {
        margin-top: 44px;
    }

    #container_benefits h1 {
        font-size: 26px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.23;
    }

    #container_benefits .beefits_content {
        margin-top: 16px;
    }

    #container_benefits .beefits_content .textContainer {
        margin: 0 auto;
        max-width: 90%;
    }

    #container_benefits .textContainer .benefits .benefit {
        margin-bottom: 27px;
    }

    #container_benefits .textContainer .benefits .benefit span {
        font-size: 45px;
        line-height: 1.33;
        margin-right: 10px;
    }

    #container_benefits .textContainer .benefits .benefit section h4 {
        font-size: 20px;
        padding-top: 10px;
        line-height: 1.2;
    }

    #container_benefits .textContainer .benefits .benefit section p {
        line-height: 1.38;
    }
}

@media only screen and (max-width: 450px) {
    #container_benefits .beefits_content .textContainer {
        max-width: 300px;
    }

    #container_benefits h1 {
        width: 250px;
    }
}