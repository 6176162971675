#registerError {
  margin-top: 175px;
  display: flex;
  flex-direction: column;
  margin-bottom: 142px;
}

#registerError img:nth-of-type(1) {
  height: 146px;
  align-self: center;
}

#registerError h1 {
  font-family: 'Manrope';
  font-size: 34px;
  line-height: 1.2;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 100px;
}

#registerError > div {
  display: flex;
  width: 1200px;
  margin: 0 auto;
}

#registerError > div > section {
  box-sizing: border-box;
  height: 250px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
}

#registerError > div > section:nth-of-type(2) {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

#registerError > div > section:last-child {
  border-right: none !important;
}

#registerError > div > section > h3 {
  font-family: 'Manrope';
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  color: var(--tertiary-heavy);
}

#registerError > div > section > button {
  margin-top: 75px;
}

#registerError > div > section > div {
  margin-top: 27px;
  height: 175px;
  width: 336px;
}

.subscription-error {
  margin-top: 175px;
  padding: 10px;
  background-color: salmon;
  color: white;
  margin: 175px auto 0 auto;
}

@media only screen and (max-width: 1200px) {
  #registerError {
    margin-top: 75px;
    margin-bottom: 100px;
  }
  #registerError h1 {
    width: 90%;
    margin-left: 5%;
  }
  #registerError h1 br {
    display: none;
  }
  #registerError > div {
    width: 100vw;
    flex-wrap: wrap;
    justify-content: center;
  }
  #registerError > div > section {
    min-width: 50vw;
    width: 50vw !important;
    max-width: 50vw !important;
    order: 0;
    height: 150px;
    justify-content: space-between;
  }
  #registerError > div > section > button {
    margin-top: 25px;
  }
  #registerError > div > section.registerVideo {
    border-left: none;
    border-right: none;
    order: 1;
    height: 250px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 760px) {
  #registerError h1 {
    font-size: 30px;
  }
  #registerError > div {
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: max-content;
  }
  #registerError > div > section {
    min-width: 375px;
    order: 0 !important;
    height: 100px;
    justify-content: space-between;
    margin-top: 25px;
    min-width: 375px !important;
    width: 375px !important;
    max-width: 375px !important;
    border: none !important;
  }
}
