.cityhall_intro{
    margin: 115px 0px 150px 0px;
    width: 100%;
    position: relative;
}
.cityhall_intro h1{
    width:1155px;
    margin-bottom: 17px;
    font-family: 'Manrope-Bold';
    font-size: 50px;
    line-height: 1.2;
    color: var(--dark-slate-blue);
}
.cityhall_intro h2{
    margin-bottom: 46px;
    font-family: 'Manrope-Regular';
    font-size: 26px;
    line-height: 1.23;
    color: var(--dark-slate-blue);
}
.cityhall_intro p{
    font-family: 'Manrope-Regular';
    font-size: 20px;
    line-height: 1.3;
    color: var(--dark-slate-blue);
}
.cityhall_intro h3{
    width:800px;
    margin-bottom: 18px;
    font-family: 'Manrope-Regular';
    font-size: 16px;
    line-height: 1.63;
    color: var(--tertiary-heavy);
}
.cityhall_intro_content {
    width:1000px;
    margin: 0px 0px 0px 256.5px;
}
.cityhall_intro_paragraphs{
    margin-bottom: 80px;
    display:flex;
    justify-content: space-between;
}
.cityhall_intro_paragraphs>p{
    width:46%;
}
.cityhall_intro_parahraph_1{
    width:46%;
    display:flex;
    flex-direction: column;
}
.cityhall_intro_parahraph_1 p:nth-of-type(1){
    margin-bottom: 30px;
}
.cityhall_intro img{
    position:absolute;
    top:-110px;
    right:-170px;
}

@media only screen and (max-width:1860px){
    .cityhall_intro_content {
        margin: 0px 0px 0px 200px;
    }
}
@media only screen and (max-width:1780px){
    .cityhall_intro_content {
        margin: 0px 0px 0px 100px;
    }
}
@media only screen and (max-width:1680px){
    .cityhall_intro_content {
        margin: 0px 0px 0px 32px;
    }
    .cityhall_intro{
        margin: 115px 0px 125.9px 0px;
    }
}
@media only screen and (max-width:1650px){
    .cityhall_intro img{
        height: 548.6px;
        top:-60px;
        right:-170px;
    }
}
@media only screen and (max-width:1500px){
    .cityhall_intro h1{
        width:905px;
    }
    .cityhall_intro_content {
        width:955px;
    }
}
@media only screen and (max-width:1400px){
    .cityhall_intro{
        margin: 115px 0px 110px 0px;
    }
    .cityhall_intro_content {
        width:850px;
    }
    .cityhall_intro p{
        font-size: 16px;
        line-height: 1.38;
    }
    .cityhall_intro h2{
        margin-bottom: 30px;
    }
    .cityhall_intro h3{
        margin-bottom: 26px;
    }
    .cityhall_intro_paragraphs{
        margin-bottom: 30px;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .cityhall_intro_paragraphs>p{
        width:100%;
    }
    .cityhall_intro_parahraph_1{
        margin-bottom: 30px;
        width:100%;
        display:flex;
        flex-direction: column;
    }
    .cityhall_intro_parahraph_1 p:nth-of-type(1){
        margin-bottom: 0px;
    }
}
@media only screen and (max-width:1270px){
    .cityhall_intro img{
        height: 451px;
        top:25px;
        right:-130px;
    }
    .cityhall_intro h1{
        width: 620px;
    }
    .cityhall_intro_content {
        width:720px;
    }
    .cityhall_intro h3{
        width:720px;
    }
}
@media only screen and (max-width:1120px){
    .cityhall_intro img{
        top:90px;
    }
    .cityhall_intro_content {
        width:640px;
    }
    .cityhall_intro h2{
        width: 670px;
    }
    .cityhall_intro h3{
        width: 630px;
    }
}
@media only screen and (max-width:999px){
    .cityhall_intro{
        margin: 45px 0px 35px 0px;
        width: 100%;
        position: relative;
        display:flex;
        flex-direction: column;
        align-items: center;
    }
    .cityhall_intro_content {
        display:flex;
        flex-direction: column;
        align-items: center;
        width:80%;
        margin:0px;
    }
    .cityhall_intro img{
        height:400px;
        margin-bottom: 30px;
        position:relative;
        top:0px;
        right:0px;
    }
    .cityhall_intro h3{
        width:100%;
        align-self: start;
        margin-bottom: 23px;
    }
    .cityhall_intro h1{
        text-align: center;
        width:100%;
        font-size: 34px;
    }
    .cityhall_intro h2{
        text-align: center;
        width:100%;
        font-size: 20px;
        margin-bottom: 19px;
    }
    .cityhall_intro_paragraphs{
        align-self: start;
        margin-bottom: 47px;
    }
    .cityhall_intro_parahraph_1 p:nth-of-type(1),
    .cityhall_intro_parahraph_1{
        margin-bottom: 20px;
    }
}
@media only screen and (max-width:860px){
    .cityhall_intro{
        margin: 20px 0px 35px 0px;
    }
    .cityhall_intro img{
        height: 331.5px;
        margin-bottom: 10px;
    }
}
@media only screen and (max-width:700px){
    .cityhall_intro_content {
        width:87%;
    }
}
@media only screen and (max-width:530px){
    .cityhall_intro h1{
        line-height: 1.23;
        font-size: 26px;    
    }
    .cityhall_intro_content {
        width:94%;
    }
}
@media only screen and (max-width:450px){
    .cityhall_intro img{
        margin-bottom: 10px;
        margin-left:50%;
    }
}
