.parents_benefits{
    position: relative;
    margin: 0px 0px 100px 0px;
}

.parents_benefits_content{
    width: 1050px;
    margin: 0px 0px 0px 625px;
}
.parents_benefits img{
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    top:-70px;
    left: -118px;
}
.parents_benefits h1{
    margin-bottom: 16px;
    font-family: 'Manrope-Bold';
    font-size: 50px;
    line-height: 1.24;
    color: var(--dark-slate-blue);
}
.parents_benefits h2{
    margin-bottom: 28px;
    font-family: 'Manrope-Bold';
    font-size: 34px;
    line-height: 1.24;
    color: var(--dark-slate-blue);
}
.parents_benefits h3{
    width: 1000px;
    margin-bottom: 90px;
    font-family: 'Manrope-Regular';
    font-size: 20px;
    line-height: 1.3;
    color: var(--secondary-heavy);
}
.parents_benefits_wrapper p{
    font-family: 'Manrope-Medium';
    font-size: 16px;
    line-height: 1.5;
    color: var(--secondary-heavy);
}
.parents_benefits_wrapper {
    display:grid;
    grid-template-columns: repeat(3,32%);
    row-gap:20px;
    justify-content:space-between;
}
.parents_benefits_wrapper>div{
    display:flex;
}
.parents_benefits_wrapper>div>p:nth-of-type(1){
    margin-right:10px;
}
.parents_benefits button{
    margin-top:75px;
}

@media only screen and (max-width: 1750px){
    .parents_benefits_content{
        margin: 0px 0px 0px 518px;
    }
    .parents_benefits img{
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        position: absolute;
        top:-70px;
        left: -218px;
    }
}
@media only screen and (max-width: 1620px){
    .parents_benefits_content{
        margin: 0px 0px 0px 468px;
    }
}
@media only screen and (max-width: 1560px){
    .parents_benefits{
        margin: 0px 0px 125px 0px;
    }
    .parents_benefits_content{
        margin: 0px 0px 0px 598px;
        width: 850px;
    }
    .parents_benefits img{
        height: 666.8px;
        top:-60px;
        left: -118px;
    }
    .parents_benefits h3{
        width: auto;
    }
    .parents_benefits h1{
        width:590px;
    }
}
@media only screen and (max-width: 1530px){
    .parents_benefits_content{
        margin: 0px 0px 0px 498px;
    }
    .parents_benefits img{
        height: 666.8px;
        top:-60px;
        left: -218px;
    }

}
@media only screen and (max-width: 1400px){
    .parents_benefits_content{
        margin: 0px 0px 0px 398px;
    }

}
@media only screen and (max-width: 1300px){
    .parents_benefits button{
        margin-top:45px;
    }
    .parents_benefits_content{
        width:100%;
        margin: 0px 0px 0px 0px;
        display:flex;
        flex-direction: column;
        align-items: center;
    }
    .parents_benefits img{
        height: 610px;
        top:-45px;
        left: -198px;
    }
    .parents_benefits h1{
        margin-top:135px;
        margin-bottom: 30px;
    }
    .parents_benefits_upper_text{
        margin-left:80px;
        width:590px;
    }
    .parents_benefits_wrapper{
        width:90%;
    }
    .parents_benefits{
        margin: 0px 0px 60px 0px;
    }
}
@media only screen and (max-width: 1150px){
    .parents_benefits img{
        left: -218px;
    }
    .parents_benefits h1{
        width:auto;
    }
    .parents_benefits_upper_text{
        margin-left:130px;
        width:533px;
    }
}
@media only screen and (max-width: 999px){
    .parents_benefits h1{
        text-align: center;
        margin-top:0px;
        margin-bottom: 30px;
    }
    .parents_benefits_upper_text{
        margin-left:0px;
        width:90%;
    }
    .parents_benefits_upper_text h3{
        width:80%;
        margin-bottom: 50px;
    }
    .parents_benefits img{
        height:220px;
        top:100px;
        left:auto;
        right:20px;
    }
}
@media only screen and (max-width: 820px){
    .parents_benefits img{
        right:0px;
    }
}
@media only screen and (max-width: 770px){
    .parents_benefits_wrapper {
        grid-template-columns: repeat(2,47%);
    }
}
@media only screen and (max-width: 740px){
    .parents_benefits img{
        right:-45px;
    }
}
@media only screen and (max-width: 650px){
    .parents_benefits{
        margin: 0px 0px 65px 0px;
    }
    .parents_benefits h1{
        margin-bottom: 32px;
        font-size: 26px;
        color: var(--tertiary-heavy);      
    }
    .parents_benefits h2{
        margin-bottom: 18px;
        font-size: 20px;
        line-height: 2.1;
        text-align: center;
    }
    .parents_benefits h3{
        margin-bottom: 65px;
        font-size: 16px;
        line-height: 1.5;
        color: var(--secondary-heavy);
    }
    .parents_benefits img{
        top:40px;
    }
}
@media only screen and (max-width: 550px){
    .parents_benefits img{
        top:76px;
    }
}
@media only screen and (max-width: 540px){
    .parents_benefits_upper_text h3{
        width:70%;
    }
}
@media only screen and (max-width: 500px){
    .parents_benefits_wrapper {
        grid-template-columns: repeat(1,100%);
    }
    .parents_benefits{
        margin: 0px 0px 40px 0px;
    }
}
@media only screen and (max-width: 430px){
    .parents_benefits_upper_text h3{
        width:65%;
    }
}


