#MentionsContainer {
  margin: 0px 0px 133px 0px;
}

#MentionsContainer h1 {
  font-family: "Manrope-Bold";
  font-size: 50px;
  color: var(--tertiary-heavy);
  width: 100%;
  text-align: center;
  padding: 0px 50px;
  box-sizing: border-box;
}

#MentionsContainer .mentionsSlider img {
  margin-right: 72px;
}

#MentionsContainer .mentionsSlider img.RRA {
  width: 148px;
  height: 59px;
}

#MentionsContainer .mentionsSlider img.ZF {
  width: 242px;
  height: 40px;
}

#MentionsContainer .mentionsSlider img.Hotnews {
  width: 202px;
  height: 49px;
}

#MentionsContainer .mentionsSlider img.Edupedu {
  width: 222px;
  height: 80px;
}

#MentionsContainer .mentionsSlider img.Adevarul {
  width: 191px;
  height: 43px;
}

#MentionsContainer .mentionsSlider img.Mediafax {
  width: 235px;
  height: 34px;
}

#MentionsContainer .mentionsSlider img.Guerilla {
  width: 125px;
  height: 67px;
}

#MentionsContainer .mentionsSlider img.digi {
  width: 91px;
  height: 91px;
}

#MentionsContainer .mentionsSlider img.protv {
  width: 208px;
  height: 43px;
}

#MentionsContainer .mentionsSlider img.forbes {
  width: 191px;
  height: 50px;
}

#MentionsContainer .mentionsSlider img.aLive {
  width: 171px;
  height: 74px;
}

#MentionsContainer .mentionsSlider img.Biz {
  width: auto;
  height: 79px;
  object-fit: contain;
}

#MentionsContainer .mentionsSlider img.br {
  width: 128px;
  height: 63px;
}

#MentionsContainer .mentionsSlider img.startUp {
  width: 170px;
  height: 64px;
}

#MentionsContainer div.mentionsSlider {
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

@keyframes RightLeft {

  0%,
  100% {
    margin-left: 100vw;
  }

  50% {
    margin-left: -250px;
  }
}

@keyframes LeftRight {

  0%,
  100% {
    margin-right: 100vw;
  }

  50% {
    margin-right: -250px;
  }
}

@media screen and (max-width: 800px) {
  #MentionsContainer div.left-to-right {
    margin-top: 50px;
  }

  #MentionsContainer div.right-to-left {
    margin-top: 30px;
  }

  #MentionsContainer div.mentionsSlider img {
    height: 25px;
    width: fit-content;
    max-width: 250px;
  }
}

@media screen and (min-width: 1201px) {
  #MentionsContainer div.mentionsSlider {
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  #MentionsContainer div.mentionsSlider::-webkit-scrollbar {
    display: none;
  }

  #MentionsContainer div.left-to-right {
    margin-top: 100px;
  }

  #MentionsContainer div.left-to-right img:nth-of-type(1) {
    margin-left: 50vw;
  }

  #MentionsContainer div.left-to-right img:last-child {
    margin-right: 50vw;
  }

  #MentionsContainer div.right-to-left {
    margin-top: 60px;
  }

  #MentionsContainer div.right-to-left img:nth-of-type(1) {
    margin-left: 50vw;
  }

  #MentionsContainer div.right-to-left img:last-child {
    margin-right: 50vw;
  }
}

@media screen and (max-width: 1200px) {
  #MentionsContainer div.mentionsSlider {
    width: 200vw;
    transform: translatex(-50vw);
  }

  #MentionsContainer div.left-to-right {
    margin-top: 100px;
    justify-content: flex-end;
  }

  #MentionsContainer div.left-to-right img {
    margin-right: 50px;
  }

  #MentionsContainer div.left-to-right img:last-child {
    margin-right: 100vw;
    animation-name: LeftRight;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  #MentionsContainer div.right-to-left {
    margin-top: 60px;
    justify-content: flex-start;
  }

  #MentionsContainer div.right-to-left img {
    margin-right: 50px;
  }

  #MentionsContainer div.right-to-left img:first-child {
    margin-left: 100vw;
    animation-name: RightLeft;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  #MentionsContainer div.right-to-left img:last-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1200px) {
  #MentionsContainer {
    margin: 100px 0px 133px 0px;
  }
}

@media only screen and (max-width: 760px) {
  #MentionsContainer {
    margin: 33.2px 0px 62.5px 0px;
  }

  #MentionsContainer h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 600px) {}

@media screen and (max-width: 1100px) {
  @keyframes RightLeft {

    0%,
    100% {
      margin-left: 100vw;
    }

    50% {
      margin-left: -350px;
    }
  }

  @keyframes LeftRight {

    0%,
    100% {
      margin-right: 100vw;
    }

    50% {
      margin-right: -350px;
    }
  }
}

@media screen and (max-width: 1000px) {
  @keyframes RightLeft {

    0%,
    100% {
      margin-left: 100vw;
    }

    50% {
      margin-left: -450px;
    }
  }

  @keyframes LeftRight {

    0%,
    100% {
      margin-right: 100vw;
    }

    50% {
      margin-right: -450px;
    }
  }
}

@media screen and (max-width: 900px) {
  @keyframes RightLeft {

    0%,
    100% {
      margin-left: 100vw;
    }

    50% {
      margin-left: -550px;
    }
  }

  @keyframes LeftRight {

    0%,
    100% {
      margin-right: 100vw;
    }

    50% {
      margin-right: -550px;
    }
  }
}

@media screen and (max-width: 800px) {
  #MentionsContainer {
    margin-bottom: 50px;
  }

  #MentionsContainer img {
    transform: scale(0.6);
  }

  #MentionsContainer div.left-to-right img {
    margin-right: -50px;
  }

  #MentionsContainer div.right-to-left img {
    margin-right: -50px;
  }

  #MentionsContainer h1 {
    font-size: 26px;
  }

  #MentionsContainer div.left-to-right {
    margin-top: 25px !important;
  }

  #MentionsContainer div.right-to-left {
    margin-top: 30px !important;
  }

  @keyframes RightLeft {

    0%,
    100% {
      margin-left: 100vw;
    }

    50% {
      margin-left: -100px;
    }
  }

  @keyframes LeftRight {

    0%,
    100% {
      margin-right: 100vw;
    }

    50% {
      margin-right: -100px;
    }
  }
}

@media screen and (max-width: 700px) {
  @keyframes RightLeft {

    0%,
    100% {
      margin-left: 100vw;
    }

    50% {
      margin-left: -150px;
    }
  }

  @keyframes LeftRight {

    0%,
    100% {
      margin-right: 100vw;
    }

    50% {
      margin-right: -150px;
    }
  }
}

@media screen and (max-width: 600px) {
  @keyframes RightLeft {

    0%,
    100% {
      margin-left: 100vw;
    }

    50% {
      margin-left: -250px;
    }
  }

  @keyframes LeftRight {

    0%,
    100% {
      margin-right: 100vw;
    }

    50% {
      margin-right: -250px;
    }
  }
}

@media screen and (max-width: 500px) {
  @keyframes RightLeft {

    0%,
    100% {
      margin-left: 100vw;
    }

    50% {
      margin-left: -350px;
    }
  }

  @keyframes LeftRight {

    0%,
    100% {
      margin-right: 100vw;
    }

    50% {
      margin-right: -350px;
    }
  }
}

@media screen and (max-width: 400px) {
  @keyframes RightLeft {

    0%,
    100% {
      margin-left: 100vw;
    }

    50% {
      margin-left: -500px;
    }
  }

  @keyframes LeftRight {

    0%,
    100% {
      margin-right: 100vw;
    }

    50% {
      margin-right: -500px;
    }
  }
}