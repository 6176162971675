.institution {
  height: 638.6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.institution_container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 1762.2px;
}

.institution_container>img:nth-of-type(2) {
  z-index: -1;
  position: absolute;
}

.institution_container>img:nth-of-type(1) {
  z-index: -2;
  position: absolute;
  width: 31.05%;
}

.institution h1 {
  margin-bottom: 40.6px;
  font-family: "Manrope-Bold";
  font-size: 48px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: var(--tertiary-heavy);
}

.institution_info_1_container {
  width: 27.4%;
  position: absolute;
  top: 156.9px;
  left: 6.2%;
}

.institution_info_1_container h2,
.institution_info_2_container h2 {
  font-family: "Manrope-Bold";
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-heavy);
}

.institution_info_1_container p,
.institution_info_2_container p {
  margin: 29px 0px 24.1px 0px;
  font-family: "Manrope";
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-heavy);
}

.institution_info_1_container p:nth-of-type(2) {
  margin-top: 0;
}

.institution_info_2_container {
  width: 27.4%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 255.9px;
  right: 5%;
}

.institution_info_2_container h2 {
  text-align: right;
  color: var(--tertiary-ultralight);
}

.institution_info_2_container p {
  text-align: right;
  color: var(--tertiary-ultralight);
}

.buildings {
  display: none;
}

@media only screen and (max-width: 1762.2px) {
  .institution_container {
    width: 100%;
  }
}

@media only screen and (max-width: 1735px) {
  .institution_info_1_container {
    top: 140px;
  }

  .institution_info_2_container {
    top: 235.9px;
    right: 3%;
  }

  .institution {
    height: 618.6px;
  }
}

@media only screen and (max-width: 1670px) {

  .institution_info_1_container p,
  .institution_info_2_container p {
    margin: 15px 0px 10px 0px;
  }

  .institution {
    height: 588.6px;
  }
}

@media only screen and (max-width: 1550px) {
  .institution_info_1_container {
    top: 126.9px;
    left: 4.2%;
  }

  .institution_info_2_container {
    top: 215.9px;
    right: 2%;
  }

  .institution_container>svg:nth-of-type(1) {
    margin-top: -50px;
  }

  .institution_info_1_container,
  .institution_info_2_container {
    width: 35%;
  }

  .institution_info_1_container h2,
  .institution_info_2_container h2 {
    width: 90%;
  }

  .institution {
    height: 558.6px;
  }
}

@media only screen and (max-width: 1470px) {
  .institution_info_1_container {
    top: 106.9px;
    left: 4.2%;
  }

  .institution {
    height: 538.6px;
  }
}

@media only screen and (max-width: 1400px) {
  .institution {
    height: max-content;
    margin-bottom: 41px;
  }

  .institution_container>img:nth-of-type(1),
  .institution_container>img:nth-of-type(2) {
    display: none;
  }

  .institution_container {
    display: flex;
    flex-direction: column;
  }

  .institution h1 {
    width: 90%;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    font-size: 40px;
    line-height: 1.23;
  }

  .buildings {
    display: block;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .buildings img {
    position: absolute;
  }

  .buildings img:nth-of-type(1) {
    width: 350px;
    top: -20px;
  }

  .buildings img:nth-of-type(2) {
    z-index: 3;
    width: 190px;
    height: auto;
    top: 70px;
    transform: translateX(-65px);
  }

  .buildings img:nth-of-type(3) {
    z-index: 2;
    width: 150px;
    top: 42px;
    transform: translateX(55px);
  }

  .institution_info_1_container,
  .institution_info_2_container {
    position: static;
    background-color: #17da21;
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .institution_info_1_container h2,
  .institution_info_2_container h2 {
    font-size: 20px;
    line-height: 1.2;
    width: calc(100% - 100px);
    text-align: center;
    margin: 0 0 20px 0px;
  }

  .institution_info_1_container p,
  .institution_info_2_container p {
    width: calc(100% - 100px);
    text-align: left;
    margin: 0 0 20px 0;
    font-size: 16px;
    line-height: 1.5;
  }

  .institution_info_1_container h2 {
    margin-top: 30px;
  }

  .institution_info_1_container h2 br,
  .institution_info_2_container h2 br {
    display: none;
  }

  .institution_info_1_container {
    clip-path: polygon(0 0, 100% 19%, 100% 100%, 0% 100%);
    background-color: var(--action-background);
    padding-top: 125px;
    padding-bottom: 125px;
  }

  .institution_info_2_container {
    clip-path: polygon(0 19%, 100% 0, 100% 100%, 0% 100%);
    background-color: var(--main-heavy);
    margin-top: -120px;
    padding-top: 120px;
    padding-bottom: 35px;
  }
}

@media only screen and (max-width: 600px) {
  .institution h1 {
    font-size: 26px;
  }

  .institution_info_1_container p,
  .institution_info_2_container p {
    width: calc(100% - 40px);
  }

  .institution_info_1_container h2,
  .institution_info_2_container h2 {
    width: calc(100% - 40px);
  }
}