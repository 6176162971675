.cityhall_digitalization{
    margin:181px 0px -30px 0px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.cityhall_digitalization>img{
    position:absolute;
    width: 262.3px;
    top:241px;
    left:272px;
}
.cityhall_digitalization button{
    margin-left:706px;
}
.cityhall_digitalization .study_case{
    margin-left:720px;
    background-color:var(--secondary-heavy);
    width:fit-content;
    padding: 12px 10px;
    margin-bottom: 16px;
}
.cityhall_digitalization .study_case p{
    font-family: 'Manrope-Bold';
    font-size: 20px;
    line-height: 1.27;
    color: var(--tertiary-ultralight);
}
.cityhall_digitalization h1{
    margin: 0px 0px 31px 716px;
    font-family: 'Manrope-Bold';
    font-size: 50px;
    line-height: 1.24;
    color: var(--tertiary-heavy);
}
.cityhall_digitalization_cards{
    margin-left:706px;
}
.cityhall_digitalization_card{
    position: relative;
    box-sizing: border-box;
    background-color: var(--dark-slate-blue);
    padding: 30px 0px 40px 40px;
    border-radius: 12px 0px 0px 12px;
    margin-bottom: 25px;
}
.cityhall_digitalization_card:nth-last-of-type(1){
    margin-bottom: 40px;
    border-radius: 12px 12px 12px 12px;
    width:524px;
    padding: 40px 32px 42.5px 38px;
}
.cityhall_digitalization_card h2{
    font-family: 'Manrope-Bold';
    font-size: 34px;
    line-height: 1;
    color: var(--tertiary-ultralight);
}
.cityhall_digitalization_card:nth-of-type(1) h2{
    margin-bottom: 15px;
}
.cityhall_digitalization_card:nth-of-type(2) h2{
    margin-bottom: 33px;
}
.cityhall_digitalization_card:nth-of-type(3) h2{
    margin-bottom: 26px;
}
.cityhall_digitalization_card p{
    width:1000px;
    font-family: 'Manrope-Regular';
    font-size: 14px;
    line-height: 1.43;
    color: var(--tertiary-ultralight);
    margin-bottom: 20px;
}
.cityhall_digitalization_card:nth-last-of-type(1)>p{
    width: auto;
    font-family: 'Manrope-Regular';
    font-size: 16px;
    line-height: 1.5;
    color: var(--tertiary-ultralight);
    margin-bottom: 40px !important;
}
.cityhall_digitalization_card:nth-last-of-type(1)>img{
    position: absolute;
    width: 140px;
    height: 140px;
    bottom:-38px ;
    right:-42px ;
    border: solid 6px var(--macaroni-and-cheese);
    border-radius:50%;
    background-color: var(--macaroni-and-cheese);
}
.cityhall_digitalization_card p:nth-last-of-type(1){
    margin-bottom: 0px;
}

.cityhall_digitalization_card:nth-last-of-type(1)>h2{
    font-size: 26px;
    margin-bottom: 12px;
}
.cityhall_digitalization_card:nth-last-of-type(1)>h3{
    font-family: 'Manrope-Regular';
    font-size: 16px;
    line-height: 1.25;
    color: var(--main-light);
}

@media only screen and (max-width:1850px){
    .cityhall_digitalization .study_case{
        margin-left:690px;
    }
    .cityhall_digitalization h1{
        margin: 0px 0px 31px 686px;
    }
    .cityhall_digitalization_cards,
    .cityhall_digitalization button{
        margin-left:676px;
    }
    .cityhall_digitalization>img{
        left:242px;
    }
}
@media only screen and (max-width:1800px){
    .cityhall_digitalization .study_case{
        margin-left:660px;
    }
    .cityhall_digitalization h1{
        margin: 0px 0px 31px 656px;
    }
    .cityhall_digitalization_cards,
    .cityhall_digitalization button{
        margin-left:646px;
    }
    .cityhall_digitalization>img{
        left:212px;
    }
}
@media only screen and (max-width:1750px){
    
    .cityhall_digitalization .study_case{
        margin-left:610px;
    }
    .cityhall_digitalization h1{
        margin: 0px 0px 31px 606px;
    }
    .cityhall_digitalization_cards,
    .cityhall_digitalization button{
        margin-left:596px;
    }
    .cityhall_digitalization>img{
        left:162px;
    }
}
@media only screen and (max-width:1700px){
    .cityhall_digitalization .study_case{
        margin-left:560px;
    }
    .cityhall_digitalization h1{
        margin: 0px 0px 31px 556px;
    }
    .cityhall_digitalization_cards,
    .cityhall_digitalization button{
        margin-left:546px;
    }
    .cityhall_digitalization>img{
        left:112px;
    }
}
@media only screen and (max-width:1650px){
    .cityhall_digitalization .study_case{
        margin-left:510px;
    }
    .cityhall_digitalization h1{
        margin: 0px 0px 31px 506px;
    }
    .cityhall_digitalization_cards,
    .cityhall_digitalization button{
        margin-left:496px;
    }
    .cityhall_digitalization>img{
        left:62px;
    }
}
@media only screen and (max-width:1600px){
    .cityhall_digitalization .study_case{
        margin-left:460px;
    }
    .cityhall_digitalization h1{
        margin: 0px 0px 31px 456px;
    }
    .cityhall_digitalization_cards,
    .cityhall_digitalization button{
        margin-left:446px;
    }
}
@media only screen and (max-width:1550px){
    .cityhall_digitalization .study_case{
        margin-left:410px;
    }
    .cityhall_digitalization h1{
        margin: 0px 0px 31px 406px;
    }
    .cityhall_digitalization_cards,
    .cityhall_digitalization button{
        margin-left:396px;
    }
    
}
@media only screen and (max-width:1500px){
    .cityhall_digitalization_card p{
        width:90%;
    }
}
@media only screen and (max-width:1450px){
    .cityhall_digitalization .study_case{
        margin-left:50%;
        transform:translateX(-50%);
    }
    .cityhall_digitalization h1{
        width: 710px;
        margin: 0px 0px 62px 50%;
        transform:translateX(-50%);
        text-align:center;
    }
    .cityhall_digitalization>img{
        top:251px;
    }
}
@media only screen and (max-width:1300px){
    .cityhall_digitalization_cards,
    .cityhall_digitalization button{
        margin-left:346px;
    }
    .cityhall_digitalization>img{
        top:250px;
        width: 190.9px;
    }
}
@media only screen and (max-width:1200px){
    .cityhall_digitalization_cards,
    .cityhall_digitalization button{
        margin-left:273px;
    }
    .cityhall_digitalization>img{
        left:34.5px;
    }
}
@media only screen and (max-width:900px){
    .cityhall_digitalization .study_case p{
        font-size: 16px;
    }
    .cityhall_digitalization h1{
        margin-bottom:30px;
        font-size: 34px;
        width:500px;
    }
    .cityhall_digitalization_card h2{
        font-size: 26px;
    }
    .cityhall_digitalization{
        margin:70px 0px -30px 0px;
    }
    .cityhall_digitalization_cards,
    .cityhall_digitalization button{
        margin-left:40px;
    }
    .cityhall_digitalization>img{
        margin-left:50%;
        transform:translateX(-50%);
        margin-bottom: 30px;
        width: 130.6px;
        position:relative;
        top:0px;
        left:0px;
    }
    .cityhall_digitalization>button{
        width: fit-content !important;
        align-self: center;
        margin:0px;
    }
    .cityhall_digitalization_card:nth-last-of-type(1){
        margin-left:50%;
        transform:translateX(calc(-50% - 20px));
        margin-bottom: 80px;
    }
}
@media only screen and (max-width:760px){
    .cityhall_digitalization{
        margin:70px 0px 60px 0px;
    }
}
@media only screen and (max-width:700px){
    .cityhall_digitalization_card:nth-last-of-type(1){
        padding-bottom: 94px;
        margin-bottom: 121px;
    }
    .cityhall_digitalization_card:nth-last-of-type(1)>img{
        bottom:-90px;
        left:50%;
        transform:translateX(-50%);
    }
}
@media only screen and (max-width:600px){
    .cityhall_digitalization_card:nth-last-of-type(1){
        width: 354.5px;
        padding: 30px 19px 94px 31px;
    }
    .cityhall_digitalization_card:nth-last-of-type(1)>h2{
        font-size: 20px;
        margin-bottom: 6px;
    }
    .cityhall_digitalization_card{
        padding: 25px 0px 30px 23px;
        margin-bottom: 13px;
    }
    .cityhall_digitalization_card:nth-last-of-type(1)>p{
        font-size: 14px;
        line-height: 1.43;
        color: var(--tertiary-ultralight);
        margin-bottom: 10px !important;
    }
}
@media only screen and (max-width:500px){
    .cityhall_digitalization_card p{
        width:97%;
    }
    .cityhall_digitalization h1{
        font-size: 26px;
        width:83%;
    }
    .cityhall_digitalization_cards{
        margin-left: 13px;
    }
    .cityhall_digitalization_card:nth-last-of-type(1){
        transform:translateX(calc(-50% - 6.5px));
    }
}


