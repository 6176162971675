.support_contact {
    margin-top: 170px;
    width: 100%;
    display: flex;
    margin-bottom: 1px !important;
}

.support_wrapper {
    box-sizing: border-box;
    border: 1px solid transparent;
    display: grid;
    grid-template-columns: repeat(3, 400px);
}

.square_container {
    cursor: pointer;
    position: relative;
    width: 400px;
    height: 400px;
    background-color: var(--main-background);
}
.square_container:nth-of-type(2){
    background-color:#fafbff;
    z-index: 2;
}
.support_icon_w_text {
    position: absolute;
    width: 68%;
    top: 10.2%;
    left: 10.2%;
}

.support_icon_w_text h2 {
    width:120%;
    font-family: "Manrope-SemiBold";
    font-size: 26px;
    line-height: 1.2;
    color: var(--tertiary-heavy);
    margin: 11px 0px 6px 0px;
}

.support_icon_w_text p {
    font-family: "Manrope-Medium";
    font-size: 16px;
    line-height: 1.2;
    color: var(--secondary-default);
}

.support_arrows {
    margin-left: 37.6px;
}
.support_colored_rectangle {
    background-color: var(--action-background);
    width: 100%;
    height: 38px;
    margin-top: 28px;
}
.support_arrows h1 {
    width: 95%;
    font-family: "Manrope-Bold";
    font-size: 34px;
    line-height: 1.21;
    color: var(--tertiary-heavy);
}
.support_arrows img {
    margin-top: -29px;
}
.support_button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius: 50%;
    width: 74px;
    height: 74px;
    bottom: 23px;
    right: 21px;
    box-shadow: none;
    background-color: none;
}
.support_button img:nth-of-type(2),
.support_icon_w_text img:nth-of-type(2){
    display:none;
}

.square_container:hover h2{
    color: var(--tertiary-ultralight);
}
.square_container:hover p{
    color: var(--secondary-ultralight);
}
.square_container:hover .support_button img:nth-of-type(1),
.square_container:hover .support_icon_w_text img:nth-of-type(1){
    display:none;
}
.square_container:hover .support_button img:nth-of-type(2),
.square_container:hover .support_icon_w_text img:nth-of-type(2){
    display:block;
}

.square_container:hover {
    transition-duration: 0.4s;
    transform-origin: bottom;
    transform: scale(1.025);
    z-index: 10;
    background-color: var(--dark-slate-blue);
    -webkit-box-shadow: -1px 1px 5px 9px rgba(0,0,0,0.44);
    -moz-box-shadow: -1px 1px 5px 9px rgba(0,0,0,0.44);
    box-shadow: -1px 1px 8px 2px rgba(0,0,0,0.44);

}
.square_container:hover .support_button {
    transition-duration: 0.6s;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: var(--main-default);
}
.square_container:nth-of-type(1):hover {
    transform-origin: bottom left;
    background-color: var(--confirmation-accent);
}
.square_container:nth-of-type(3):hover {
    transform-origin: bottom right;
    background-color: var(--main-heavy);
}


@media only screen and (max-width: 1660px) {
    .support_contact {
        margin-top: 90px;
        flex-direction: column;
    }
    .support_arrows {
        order: 1;
        margin-left: 0px;
    }
    .support_wrapper {
        order: 2;
    }
    .support_colored_rectangle,
    .support_arrows img {
        display: none;
    }
    .support_arrows h1 {
        margin-left: 50%;
        transform: translateX(-50%);
        width: 70%;
        margin-bottom: 80px;
        text-align: center;
    }
}

@media only screen and (max-width: 1200px) {
    .square_container:nth-of-type(2):hover {
        transform-origin: bottom right;
    }
    .support_contact {
        margin-top: 60px;
    }
    .support_arrows h1 {
        margin-bottom: 50px;
    }
    .support_wrapper {
        grid-template-columns: repeat(2, 400px);
    }
    .square_container:nth-of-type(3) {
        grid-column: 2/3;
    }
}

@media only screen and (max-width: 850px) {
    .support_arrows h1 {
        font-size: 26px;
    }
}

@media only screen and (max-width: 800px) {
    .support_wrapper {
        grid-template-columns: repeat(2, 350px);
    }
    .square_container {
        height: 350px;
        width: 350px;
    }
}

@media only screen and (max-width: 700px) {
    .support_wrapper {
        grid-template-columns: repeat(2, 300px);
    }
    .square_container {
        height: 300px;
        width: 300px;
    }
    .support_icon_w_text h2 {
        font-family: "Manrope-SemiBold";
        font-size: 20px;
        line-height: 0.9;
    }
    .support_icon_w_text p {
        font-family: "Manrope-Regular";
        font-size: 12px;
        line-height: 1.33;
        color: var(--tertiary-heavy);
    }
    .support_icon_w_text>img {
        width: 74px;
        height: 74px;
    }
    .support_arrows h1 {
        width: 85%;
        margin-bottom: 40px;
    }
    .support_contact {
        margin-top: 55px;
    }
    .support_button {
        width: 68px;
        height: 68px;
        bottom: 18px;
        right: 16.5px;
    }
    .support_button img {
        width: 18.4px;
        height: 36.8px;
    }
}

@media only screen and (max-width: 600px) {
    .support_wrapper {
        grid-template-columns: repeat(2, 250px);
    }
    .square_container {
        height: 250px;
        width: 250px;
    }
    .support_button {
        width: 54px;
        height: 54px;
        bottom: 16px;
        right: 14.5px;
    }
    .support_button img {
        width: 16.4px;
        height: 32.8px;
    }
    .support_icon_w_text>p {
        width: 80%;
    }
}

@media only screen and (max-width: 500px) {
    .support_icon_w_text>h2{
        margin: 7px 0px 6px 0px;
    }
    .support_icon_w_text>img {
        width: 47px;
        height: 47px;
    }
    .support_wrapper {
        grid-template-columns: repeat(2, 200px);
    }
    .square_container {
        height: 200px;
        width: 200px;
    }
    .support_button {
        width: 36px;
        height: 36px;
        bottom: 10px;
        right: 9px;
    }
    .support_button img {
        width: 9.6px;
        height: 19.1px;
    }
}

@media only screen and (max-width: 400px) {
    .support_wrapper {
        grid-template-columns: repeat(2, 188px);
    }
    .square_container {
        height: 188px;
        width: 188px;
    }
}