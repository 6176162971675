#selectUser {
  margin-top: 32px;
}
#selectUser > h2 {
  font-family: "Manrope";
  font-size: 34px;
  font-weight: bold;

  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: var(--tertiary-heavy);
}
#selectUser > p {
  font-family: "Manrope";
  font-size: 20px;
  line-height: 1.27;
  text-align: center;
  color: var(--tertiary-heavy);
  margin-top: 6px;
}

.userManager {
  margin-bottom: 95px;
  margin-top: 65px;
  position: relative;
}
.userManager button {
  display: none;
}
.userManager > div:first-child {
  font-family: "Manrope";
  font-size: 172px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: var(--main-background);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
  width: 1426px;
  z-index: -1;
}
.userManager > img {
  height: 600px;
  margin: 0 auto;
  margin-left: 50%;
  transform: translateX(-50%);
  position: absolute;
}
.userManager .users {
  height: 600px;
  width: 600px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.userManager .users .user {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--tertiary-ultralight);
  width: fit-content;
  height: fit-content;
  position: absolute;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 50%;
}
.userManager .users .user img {
  height: 100px;
}
.userManager .users .user:first-child img {
  height: 83px;
}
.userManager .users .user:first-child img:first-child {
  margin-bottom: -58px;
}
.userManager .users .user:first-child img:nth-child(2) {
  margin-left: 100px;
}

.userManager .users .user span {
  font-family: "Manrope";
  font-size: 16px;
  margin-top: 8px;
  line-height: 1.2;

  text-align: center;
  color: var(--tertiary-heavy);
}
.userManager .users .user:nth-of-type(1) {
  left: -40px;
  top: 30px;
}
.userManager .users .user:nth-of-type(2) {
  right: -10px;
  top: 30px;
}

.userManager .users .user:nth-of-type(3) {
  left: -40px;
  bottom: 80px;
}

.userManager .users .user:nth-of-type(4) {
  right: -35px;
  bottom: 80px;
}

.userManager .users .user:nth-of-type(5) {
  left: 35%;
  bottom: -40px;
}

.userManager .users .user img:last-child {
  opacity: 0;
  position: absolute;
}
.userManager .users .user:nth-of-type(1) img:last-child {
  width: 90% !important;
  height: auto;
  left: 32px;
  top: -5px;
}
.userManager .users .user:nth-of-type(2) img:last-child {
  width: 125% !important;
  height: auto;
  left: -15px;
  top: -5px;
}
.userManager .users .user:nth-of-type(3) img:last-child {
  width: 125% !important;
  height: auto;
  left: -15px;
  top: -5px;
}
.userManager .users .user:nth-of-type(4) img:last-child {
  width: 130% !important;
  height: auto;
  left: -15px;
  top: 12px;
}
.userManager .users .user:nth-of-type(5) img:last-child {
  width: 115% !important;
  height: auto;
  left: -15px;
  top: -5px;
}
.userManager .users .user:hover {
  cursor: pointer;
}
.userManager .users .user:hover img:last-child {
  opacity: 1;
  position: absolute;
  transition-property: opacity;
  transition-duration: 0.5s;
}
@media only screen and (max-width: 1400px) {
  .userManager > div:first-child {
    font-size: 140px;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -75%);
    width: 1426px;
  }
}
@media only screen and (max-width: 1100px) {
  #selectUser {
    margin-top: 62px;
  }
  #selectUser > p:first-child {
    margin-top: 22px;
  }
  #selectUser > p {
    margin-top: 6px;
  }

  .userManager > div:first-child {
    font-size: 115px;
    font-weight: bold;
    line-height: 2.59;
    color: var(--main-background);
    transform: translate(-50%, -60%);
    width: 954px;
  }
  .userManager > img {
    height: 500px;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
  .userManager .users {
    height: 500px;
    width: 500px;
  }

  .userManager .users .user:nth-of-type(1) {
    left: -50px;
    top: 30px;
  }
  .userManager .users .user:nth-of-type(2) {
    right: -20px;
    top: 30px;
  }

  .userManager .users .user:nth-of-type(3) {
    left: -50px;
    bottom: 70px;
  }

  .userManager .users .user:nth-of-type(4) {
    right: -45px;
    bottom: 70px;
  }

  .userManager .users .user:nth-of-type(5) {
    left: 35%;
    bottom: -40px;
  }
}
@media only screen and (max-width: 900px) {
  #selectUser {
    margin-top: 70px;
  }
  #selectUser > h2 {
    font-size: 26px;
    line-height: 1.23;
  }
  #selectUser > p {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: var(--tertiary-heavy);
    margin-top: 6px;
    box-sizing: border-box;
    padding-left: 25px;
    padding-right: 25px;
  }
  #selectUser > p > br {
    display: none;
  }

  .userManager {
    margin-bottom: 200px;
    margin-top: 75px;
  }
  .userManager > div:first-child {
    font-size: 26px;
    color: var(--secondary-heavy);
    line-height: 1.23;
    position: relative;
    left: 0;
    top: 0;
    transform: translate(0%, 0%);
    width: 100%;
  }
  .userManager > img {
    display: none;
  }
  .userManager .users {
    margin: 0 auto;
    margin-top: 60px;
    height: 220px;
    width: 100%;
    max-width: 100%;
    position: relative;
    left: 0;
    transform: translateX(0%);
    display: flex;

    overflow-x: visible;
    overflow-y: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  .userManager .users::-webkit-scrollbar {
    display: none;
  }
  .userManager .users .user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: 130px;
    position: static;
    box-sizing: border-box;
    padding: 0;
    border-radius: 0;
    height: 100%;
    margin-left: 30px;
    margin-right: 30px;
  }
  .userManager .users .user img {
    height: 100px;
  }
  .userManager .users .user span {
    /* white-space: nowrap; */
    overflow-wrap: break-word;
  }

  .userManager .users .user:first-child img:first-child {
    margin-bottom: -58px;
    margin-left: -50px;
  }
  .userManager .users .user:first-child img:nth-child(2) {
    margin-left: 50px;
  }

  .userManager .users .user:first-child {
    margin-left: calc(50% - (100px / 2));
  }
  .userManager .users .user:last-child {
    border-right: calc(50vw - (100px / 2)) solid transparent;
    margin-right: 0;
    box-sizing: border-box;
  }

  .userManager .users .user {
    left: auto !important;
    top: auto !important;
    right: auto !important;
    position: relative;
    bottom: 0 !important;
    transform: translateY(-25px);
  }

  .userManager .users .user:nth-of-type(1n + 0) img:last-child {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 30px !important;
    z-index: 100;
    width: 185px !important;
    overflow-y: visible;
  }
  .userManager .users .user img.active:last-child {
    opacity: 1;
  }
  .userManager .users .user:nth-of-type(1) img:last-child {
    left: -25px !important;
  }
  .userManager .users .user:nth-of-type(2) img:last-child {
    left: -40px !important;
  }
  .userManager .users .user:nth-of-type(3) img:last-child {
    left: -35px !important;
  }
  .userManager .users .user:nth-of-type(4) img:last-child {
    left: -47.5px !important;
  }
  .userManager .users .user:nth-of-type(5) img:last-child {
    left: -35px !important;
  }
  .userManager .users .user img.active:last-child {
    display: block;
  }
  .userManager button {
    display: block;
    margin-top: 0px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
