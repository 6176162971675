.digitalization {
    width: 100vw;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 55px;
}

.digitalization h1 {
    width: 80%;
    font-family: "Manrope-Bold";
    font-size: 34px;
    line-height: 1;
    text-align: center;
    color: var(--tertiary-heavy);
    margin-bottom: 45px;
}

.digitalization_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.videos_container {
    display: flex;
    align-items: center;
    min-width: 95vw;
    width: 95vw;
    height: 350px;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.videos_container::-webkit-scrollbar {
    display: none;
}

.video_w_description {
    min-width: 400px;
    width: 400px !important;
    height: 100%;
    margin-right: 54px;
}

.video_w_description:nth-last-of-type(1) {
    margin-right: 0px;
}

.video {
    height: 225px !important;
    position: relative;
}

.video img {
    width: 100%;
    cursor: pointer;
}

.video img.active {
    opacity: 0.5;
}

.video .videoAnimation {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    cursor: pointer;
}

.video .videoAnimation .playBtn {
    background-color: #fff;
    width: 62px;
    height: 72px;
    margin: 0 auto;
    margin-top: 76.5px;
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    z-index: 2;
    overflow: none;
    opacity: 0.5;
}

.video .videoAnimation .overflowContainer {
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: relative;
    overflow: hidden;
}

.video .videoAnimation .overflowContainer:hover .wave1 {
    animation-name: wave1;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    display: initial;
}

.video .videoAnimation .overflowContainer:hover .wave2 {
    animation-name: wave2;
    animation-duration: 1s;
    /* animation-delay: 1s; */
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    display: initial;
}

.video .videoAnimation .overflowContainer:hover .wave3 {
    animation-name: wave3;
    animation-duration: 1s;
    /* animation-delay: 2s; */
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    display: initial;
}

.video .videoAnimation .overflowContainer:hover .playBtn {
    opacity: 1;
}

@keyframes wave1 {
    from {
        width: 150px;
        height: 150px;
        margin-top: -111px;
        margin-left: 120px;
        border-width: 3px;
    }
    to {
        width: 230px;
        height: 230px;
        margin-top: -150px;
        margin-left: 85px;
        border-width: 5px;
    }
}

@keyframes wave2 {
    from {
        width: 230px;
        height: 230px;
        margin-top: -150px;
        margin-left: 85px;
        border-width: 5px;
    }
    to {
        width: 300px;
        height: 300px;
        margin-top: -180px;
        margin-left: 50px;
        border-width: 10px;
    }
}

@keyframes wave3 {
    from {
        width: 300px;
        height: 300px;
        margin-top: -180px;
        margin-left: 50px;
        border-width: 10px;
    }
    to {
        width: 350px;
        height: 350px;
        margin-top: -205px;
        margin-left: 25px;
        border-width: 13px;
    }
}

.video .videoAnimation .wave1,
.video .videoAnimation .wave2,
.video .videoAnimation .wave3 {
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 50%;
    border-color: var(--main-background);
    border-style: solid;
    opacity: 0.5;
    position: absolute;
    display: none;
}

.video .videoAnimation .wave1 {
    width: 150px;
    height: 150px;
    margin-top: -111px;
    margin-left: 120px;
    border-width: 3px;
}

.video .videoAnimation .wave2 {
    width: 230px;
    height: 230px;
    margin-top: -150px;
    margin-left: 85px;
    border-width: 5px;
}

.video .videoAnimation .wave3 {
    width: 300px;
    height: 300px;
    margin-top: -180px;
    margin-left: 50px;
    border-width: 10px;
}

.digitalization p {
    font-family: "Manrope-Regular";
    font-size: 16px;
    line-height: 1.38;
    color: var(--secondary-heavy);
    margin: 8px 0px;
}

.digitalization h3 {
    font-family: "Manrope-Regular";
    font-size: 12px;
    line-height: 1.83;
    color: var(--main-default);
}

.digitalization span:nth-of-type(1) {
    font-family: "Manrope-Bold";
}

.video_w_description br {
    display: none;
}

@media only screen and (max-width: 1350px) {
    .video_w_description:first-child {
        margin-left: 50px;
    }
    .video_w_description:last-child {
        margin-right: 50px;
    }
}

@media only screen and (max-width: 800px) {
    #schools_digitalization_margin{
        height:30px;
    }
    .videos_container {
        height: 320px;
    }
    .video_w_description br {
        display: block;
    }
    .video_w_description span:nth-of-type(2) {
        display: none;
    }
    .video_w_description {
        align-self: flex-start;
        min-width: 225px;
        width: 225px !important;
        height: 125.4px;
        margin-right: 30px;
    }
    .video {
        height: 125px !important;
        margin-bottom: 5px;
    }
    .digitalization h1 {
        margin-bottom: 50px;
        font-size: 26px;
        line-height: 1.23;
    }
    .digitalization {
        margin-bottom: 0px;
    }
    .digitalization p {
        margin: 4px 0px 8px 0px;
        font-size: 14px;
        line-height: 1.43;
    }
    .digitalization h3 {
        font-size: 14px;
        line-height: 1.29;
    }
    .video .videoAnimation .playBtn {
        width: 30px;
        height: 40px;
        margin-top: 47.5px;
    }
    .video .videoAnimation .wave1,
    .video .videoAnimation .wave2,
    .video .videoAnimation .wave3 {
        background-color: transparent;
        box-sizing: border-box;
        border-radius: 50%;
        border-color: var(--main-background);
        border-style: solid;
        opacity: 0.5;
        position: absolute;
    }
    .video .videoAnimation .wave1 {
        width: 60px;
        height: 60px;
        margin-top: -50px;
        margin-left: 80px;
    }
    .video .videoAnimation .wave2 {
        width: 90px;
        height: 90px;
        margin-top: -65px;
        margin-left: 65px;
    }
    .video .videoAnimation .wave3 {
        width: 150px;
        height: 150px;
        margin-top: -100px;
        margin-left: 35px;
    }
    @keyframes wave1 {
        from {
            width: 60px;
            height: 60px;
            margin-top: -50px;
            margin-left: 80px;
            border-width: 3px;
        }
        to {
            width: 90px;
            height: 90px;
            margin-top: -65px;
            margin-left: 65px;
            border-width: 5px;
        }
    }
    @keyframes wave2 {
        from {
            width: 90px;
            height: 90px;
            margin-top: -65px;
            margin-left: 65px;
            border-width: 5px;
        }
        to {
            width: 150px;
            height: 150px;
            margin-top: -100px;
            margin-left: 35px;
            border-width: 10px;
        }
    }
    @keyframes wave3 {
        from {
            width: 150px;
            height: 150px;
            margin-top: -100px;
            margin-left: 35px;
            border-width: 10px;
        }
        to {
            width: 230px;
            height: 230px;
            margin-top: -120px;
            margin-left: 0px;
            border-width: 13px;
        }
    }
}