.ministry_intro{
    margin: 115px 0px 195.9px 0px;
    width: 100%;
    position: relative;
}
.ministry_intro h1{
    margin-bottom: 30px;
    font-family: 'Manrope-Bold';
    font-size: 50px;
    line-height: 1.2;
    color: var(--dark-slate-blue);
}
.ministry_intro p{
    font-family: 'Manrope-Regular';
    font-size: 20px;
    line-height: 1.3;
    color: var(--dark-slate-blue);
}
.ministry_intro h3{
    margin-bottom: 30px;
    font-family: 'Manrope-Regular';
    font-size: 16px;
    line-height: 1.25;
    color: var(--dark-slate-blue);
}
.ministry_intro_content {
    width:1100px;
    margin: 0px 0px 0px 256.5px;
}
.ministry_intro_paragraphs{
    margin-bottom: 50px;
    display:flex;
    justify-content: space-between;
}
.ministry_intro_paragraphs>p{
    width:46%;
}
.ministry_intro_parahraph_1{
    width:46%;
    display:flex;
    flex-direction: column;
}
.ministry_intro_parahraph_1 p:nth-of-type(1){
    margin-bottom: 30px;
}
.ministry_intro img{
    position:absolute;
    top:40px;
    right:0px;
}

@media only screen and (max-width:1860px){
    .ministry_intro_content {
        margin: 0px 0px 0px 200px;
    }
}
@media only screen and (max-width:1780px){
    .ministry_intro_content {
        margin: 0px 0px 0px 100px;
    }
}
@media only screen and (max-width:1680px){
    .ministry_intro_content {
        margin: 0px 0px 0px 32px;
    }
    .ministry_intro{
        margin: 115px 0px 125.9px 0px;
    }
}
@media only screen and (max-width:1600px){
    .ministry_intro_content{
        width:900px;
    }
    .ministry_intro_paragraphs{
        flex-direction: column;
        margin-bottom: 30px;
    }
    .ministry_intro_parahraph_1 p:nth-of-type(2){
        margin-bottom: 30px;
    }
    .ministry_intro_paragraphs p{
        width:100%;
    }
    .ministry_intro_parahraph_1{
        width:100%;
    }
}
@media only screen and (max-width:1450px){
    .ministry_intro img{
        height:400px;
        top:20px;
        right:0px;
    }
}
@media only screen and (max-width:1350px){
    .ministry_intro_content{
        width:800px;
    }
}
@media only screen and (max-width:1270px){
    .ministry_intro img{
        height:300px;
        top:20px;
        right:0px;
    }
    .ministry_intro{
        margin: 115px 0px 77px 0px;
    }
}
@media only screen and (max-width:1150px){
    .ministry_intro_content{
        width:651px;
    }
    .ministry_intro_content p{
        font-family: 'Manrope-Regular';
        font-size: 16px;
        line-height: 1.38;
        text-align: left;
        color: var(--dark-slate-blue);
    }
    .ministry_intro_content>h3>br{
        display: none;
    }
}
@media only screen and (max-width:999px){
    .ministry_intro_content{
        margin:0;
        width:80%;
        display:flex;
        flex-direction: column;
        align-items: center;
    }
    .ministry_intro{
        display:flex;
        justify-content:center;
        margin: 45px 0px 77px 0px;
    }
    .ministry_intro img{
        margin-bottom: 30px;
        position:relative;
        top:0px;
        right:0px;
    }
    .ministry_intro h1{
        font-size: 34px;
        text-align: center;
    }
    .ministry_intro p{
        font-family: 'Manrope-Regular';
        font-size: 20px;
        line-height: 1.3;
        color: var(--dark-slate-blue);
    }
    .ministry_intro h3{
        margin-bottom: 30px;
        font-family: 'Manrope-Regular';
        font-size: 16px;
        line-height: 1.25;
        color: var(--dark-slate-blue);
    }
}
@media only screen and (max-width:550px){
    .ministry_intro h1{
        font-size: 26px;
    }
    .ministry_intro_parahraph_1 p{
        margin-bottom: 24px;
    }
    .ministry_intro_paragraphs{
        margin-bottom: 60px !important;
    }
}
@media only screen and (max-width:450px){
    .ministry_intro_content{
        width:92%;
    }
}
@media only screen and (max-width:400px){
    .ministry_intro img{
        margin-left: 26%;
    }
}