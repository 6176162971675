.cityhall_benefits{
    margin-bottom: 105px;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
}
.cityhall_benefits h1{
    margin-bottom: 60px;
    width:1122px;
    font-family: 'Manrope-Bold';
    font-size: 50px;
    line-height: 1.24;
    text-align: center;
    color: var(--dark-slate-blue);
}
.cityhall_benefits_box{
    display:flex;
    flex-direction:column;
    align-items:center;
    width:1200px;
    background-color: var(--dark-slate-blue);
    border-radius: 12px 12px 12px 12px;
}
.cityhall_benefits h2{
    margin:60px 0px 85px 0px;
    font-family: 'Manrope-Bold';
    font-size: 34px;
    line-height: 1.2;
    text-align: center;
    color: var(--tertiary-ultralight);
}
.cityhall_benefits_wrapper{
    margin-bottom: 80px;
    width: 920px;
    display:grid;
    grid-template-columns: repeat(3,27%);
    justify-content: space-between;
    row-gap: 85px;
}
.cityhall_benefits .blue_icon_w_info {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cityhall_benefits .blue_icon_w_info img{
    width: 80px;
    height: 80px;
}
.cityhall_benefits .blue_icon_w_info p {
    margin-top: 16px;
    font-family: "Manrope-Medium";
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: var(--tertiary-ultralight);
}

.cityhall_benefits_wrapper div:nth-last-of-type(2){
    grid-row: 2;
    grid-column: 1/3;
}
.cityhall_benefits_wrapper div:nth-last-of-type(1){
    grid-row: 2;
    grid-column: 2/4;
}
.cityhall_benefits_wrapper div:nth-last-of-type(1) p,
.cityhall_benefits_wrapper div:nth-last-of-type(2) p{
    width: 45%;
}

@media only screen and (max-width:1300px){
    .cityhall_benefits_box{
        width:1100px;
    }
    .cityhall_benefits{
        margin-bottom: 65px;
    }
}
@media only screen and (max-width:1200px){
    .cityhall_benefits_box{
        width:1000px;
    }
    .cityhall_benefits h1{
        width:820px;
    }
}
@media only screen and (max-width:1100px){
    .cityhall_benefits_box{
        width:950px;
    }
    .cityhall_benefits h1{
        width:820px;
    }
    .cityhall_benefits_wrapper{
        width: 780px;
        row-gap: 85px;
    }
}
@media only screen and (max-width:999px){
    .cityhall_benefits_box{
        width:100%;
        border-radius: 0px 0px 0px 0px;
    }
}
@media only screen and (max-width:880px){
    .cityhall_benefits h1{
        margin-bottom: 33px;
        width:93%;
        font-size: 34px;
    }
}
@media only screen and (max-width:830px){
    .cityhall_benefits_wrapper{
        width: 650px;
        grid-template-columns: repeat(2,40%);
        justify-content: space-between;
        row-gap: 30px;
    }
    .cityhall_benefits_wrapper div:nth-last-of-type(2){
        grid-row: auto;
        grid-column: auto;
    }
    .cityhall_benefits_wrapper div:nth-last-of-type(1){
        grid-row: 3;
        grid-column: 1/3;
    }
    .cityhall_benefits_wrapper div:nth-last-of-type(1) p{
        width: 45%;
    }
    .cityhall_benefits_wrapper div:nth-last-of-type(2) p{
        width: 100%;
    }
}
@media only screen and (max-width:700px){
    .cityhall_benefits h1{
        font-size: 26px ;
    }
    .cityhall_benefits h2{
        font-size: 26px;
        margin : 33.5px 0px 66.5px 0px;
    }
    .cityhall_benefits .blue_icon_w_info p {
        font-size: 14px;
    }
    .cityhall_benefits_wrapper{
        width: 550px;
        grid-template-columns: repeat(2,40%);
        justify-content: space-between;
        row-gap: 30px;
    }
}
@media only screen and (max-width:600px){
    .cityhall_benefits .blue_icon_w_info img{
        width: 60px;
        height: 60px;
    }
    .cityhall_benefits_wrapper{
        width: 450px;
        grid-template-columns: repeat(2,45%);
        justify-content: space-between;
        row-gap: 30px;
    }
}
@media only screen and (max-width:480px){
    .cityhall_benefits{
        margin-bottom: 58px;
    }
    .cityhall_benefits_wrapper{
        width: 330px;
        grid-template-columns: repeat(2,48%);
        justify-content: space-between;
        row-gap: 30px;
    }
    .cityhall_benefits_wrapper>div:nth-last-of-type(1){
        grid-row: 2;
        grid-column: 2/3;
    }
    .cityhall_benefits_wrapper>div:nth-last-of-type(1) p{
        width:100%;
    }
    .cityhall_benefits_wrapper>div:nth-last-of-type(2){
        grid-row: 3;
        grid-column: 1/3;
    }
    .cityhall_benefits_wrapper>div:nth-last-of-type(2) p{
        width:64%;
    }
}
@media only screen and (max-width:420px){
    .cityhall_benefits h2{
        width:230px;
    }
}

