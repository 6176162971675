
#studentsID .feedback_wrapper{
    grid-template-columns: repeat(4,240px);
    width: 1200px;
}
#studentsID .feedback_wrapper_item:nth-of-type(5){
    display:none;
}

@media only screen and (max-width: 1400px){
    #studentsID .feedback_wrapper{
        grid-template-columns: repeat(4,220px);
        width: 1020px;
    }
}
@media only screen and (max-width: 1240px){
    #studentsID .feedback_wrapper{
        width: 950px;
    }
}
@media only screen and (max-width: 999px){
    #studentsID .feedback_wrapper{
        grid-template-columns: repeat(2,240px);
        width:600px;
        row-gap: 60px;
    }
}
@media only screen and (max-width: 800px){
    #studentsID .feedback_wrapper{
        width:550px;
        row-gap: 32px;
    }
}
@media only screen and (max-width: 650px){
    #studentsID .feedback_wrapper{
        grid-template-columns: repeat(4,120px);
        width:550px;
    }
    #studentsID .feedback_wrapper h2{
        font-size: 11px;
    }
}
@media only screen and (max-width: 580px){
    #studentsID .feedback_wrapper{
        grid-template-columns: repeat(2,140px);
        width:350px;
    }

}
@media only screen and (max-width: 420px){
    #studentsID .feedback_wrapper{
        grid-template-columns: repeat(2,140px);
        width:320px;
    }
}