.feedback{
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-bottom: 70px;
}
.feedback h1{
    font-family: 'Manrope-Bold';
    font-size:50px;
    line-height: 1.2;
    color: var(--tertiary-heavy);
    text-align: center;
    margin-bottom: 75px;
}
.feedback_wrapper{
    display:grid;
    justify-content: space-between;
}
#parentsID .feedback_wrapper{
    grid-template-columns: repeat(5,240px);
    width: 1480px;
}
.feedback_wrapper h2{
    font-family: 'Manrope-Regular';
    font-size: 20px;
    line-height: 1.27;
    text-align: center;
    color: var(--secondary-heavy);
}
.feedback_wrapper_item_logo{
    height: 98.8px;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}
.feedback_wrapper_item{
    display:flex;
    flex-direction: column;
    align-items: center;
}
.feedback_wrapper_item img{
    cursor:pointer;
}
.feedback_wrapper_item:nth-of-type(1) img{
    width: 199.2px;
    height: 65.3px;
}
.feedback_wrapper_item:nth-of-type(2) img{
    width: 85.3px;
    height: 95px;
}
.feedback_wrapper_item:nth-of-type(3) img{
    width: 113.2px;
     height: 79.9px;
}
.feedback_wrapper_item:nth-of-type(4) img{
    width: 98.9px;
     height: 98.8px;
}
#parentsID .feedback_wrapper_item:nth-of-type(5) img{
    width: 197.2px;
     height: 39px;
}

@media only screen and (max-width: 1600px){
    #parentsID .feedback_wrapper{
        grid-template-columns: repeat(3,240px);
        width: 1220px;
        row-gap: 65px;
    }
    #parentsID .feedback_wrapper_item:nth-of-type(4){
        grid-column: 1/3;
        grid-row:2;
    }
    #parentsID .feedback_wrapper_item:nth-of-type(5){
        grid-column: 2/4;
        grid-row: 2;
    }
    #parentsID .feedback_wrapper h2{
        width:240px;
    }
    .feedback h1{
        width: 930px;
    }
}
@media only screen and (max-width: 1400px){
    #parentsID .feedback_wrapper{
        width: 1020px;
    }
}
@media only screen and (max-width: 1240px){
    #parentsID .feedback_wrapper{
        width: 900px;
    }
    .feedback h1{
        width: 720px;
    }
    .feedback{
        margin-bottom: 90px;
    }
}
@media only screen and (max-width: 999px){
    #parentsID .feedback_wrapper{
        grid-template-columns: repeat(2,240px);
        width:600px;
    }
    #parentsID .feedback_wrapper_item:nth-of-type(4){
        grid-column: auto;
        grid-row: auto;
    }
    #parentsID .feedback_wrapper_item:nth-of-type(5){
        grid-column: 1/3;
        grid-row: auto;
    }
}
@media only screen and (max-width: 800px){
    .feedback h1{
        width: 620px;
        font-size: 36px;
    }
    #parentsID .feedback_wrapper{
        width:550px;
        row-gap: 32px;
    }
    .feedback{
        margin-bottom: 70px;
    }
}
@media only screen and (max-width: 650px){
    #parentsID .feedback_wrapper{
        grid-template-columns: repeat(3,140px);
        width:480px;
    }
    #parentsID .feedback_wrapper_item:nth-of-type(4){
        grid-column: 1/3;
        grid-row:2;
    }
    #parentsID .feedback_wrapper_item:nth-of-type(5){
        grid-column: 2/4;
        grid-row: 2;
    }
    #parentsID .feedback_wrapper h2{
        width:140px;
        font-size: 11px;
    }
    .feedback h1{
        width: 450px;
        font-size: 26px;
        margin-bottom: 50px;
    }
    .feedback_wrapper_item:nth-of-type(1) img{
        width: 110.3px;
        height: 36.2px;
    }
    .feedback_wrapper_item:nth-of-type(2) img{
        width: 47.2px;
        height:auto;
    }
    .feedback_wrapper_item:nth-of-type(3) img{
        width: 62.7px;
        height: 44.3px;
    }
    .feedback_wrapper_item:nth-of-type(4) img{
        width: 54.8px;
        height: 54.7px;
    }
    #parentsID .feedback_wrapper_item:nth-of-type(5) img{
        width: 131.8px;
        height: 26.1px;
    }
    .feedback_wrapper_item_logo{
        height: 54.7px;
        margin-bottom: 13px;
    }
}
@media only screen and (max-width: 520px){
    #parentsID .feedback_wrapper{
        grid-template-columns: repeat(2,140px);
        width:350px;
    }
    #parentsID .feedback_wrapper_item:nth-of-type(4){
        grid-column: auto;
        grid-row:auto;
    }
    #parentsID .feedback_wrapper_item:nth-of-type(5){
        grid-column: 1/3;
        grid-row: 3;
    }
}
@media only screen and (max-width: 420px){
    .feedback h1{
        width: 310px;
    }
    #parentsID .feedback_wrapper{
        grid-template-columns: repeat(2,140px);
        width:320px;
    }
}