.csr_digitalization {
    margin: 0px 0px 108px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.csr_digitalization_container {
    background-color: var(--main-ultralight);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1297px;
    height: auto;
    position: relative;
}

.csr_digitalization_container h1 {
    font-family: "Manrope-Bold";
    font-size: 34px;
    line-height: 1.24;
    text-align: center;
    color: var(--dark-slate-blue);
}

.csr_digitalization_container>div:nth-of-type(1) {
    margin: 45px 0px 46px 0px;
}

.csr_digitalization_container>div:nth-of-type(2) {
    position: relative;
}

.csr_digitalization_container p {
    width: 1045px;
    margin-bottom: 25px;
    font-family: "Manrope-Bold";
    font-size: 16px;
    line-height: 1.25;
    color: var(--dark-slate-blue);
}

.csr_digitalization_container p:nth-last-of-type(1) {
    margin-bottom: 71px;
}

.csr_digitalization_container img {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
}

.csr_digitalization_container img:nth-of-type(1) {
    top: -130px;
    left: -150px;
}

.csr_digitalization_container img:nth-of-type(2) {
    bottom: -80px;
    right: -100px;
}

@media only screen and (max-width: 1700px) {
    .csr_digitalization_container {
        width: 1097px;
    }
    .csr_digitalization_container h1 {
        font-size: 26px;
    }
}

@media only screen and (max-width: 1350px) {
    .csr_digitalization {
        margin: 0px 0px 94px 0px;
    }
    .csr_digitalization_container {
        width: 912px;
    }
    .csr_digitalization_container img:nth-of-type(1) {
        height: 220px;
        top: -90px;
        left: -95px;
    }
    .csr_digitalization_container img:nth-of-type(2) {
        height: 136px;
        bottom: -60px;
        right: -65px;
    }
    .csr_digitalization_container>div:nth-of-type(1) {
        margin: 102px 0px 25px 0px;
    }
    .csr_digitalization_container p {
        width: 831px;
    }
}

@media only screen and (max-width: 999px) {
    .csr_digitalization_container {
        width: 100%;
    }
    .csr_digitalization_container>div:nth-of-type(1) {
        width: 92%;
        margin: 90px 0px 27.5px 0px;
    }
    .csr_digitalization_container img:nth-of-type(1) {
        height: 147px;
        top: -75px;
        left: -30px;
    }
    .csr_digitalization_container img:nth-of-type(2) {
        height: 147px;
        bottom: -55px;
        right: -40px;
    }
}

@media only screen and (max-width: 900px) {
    .csr_digitalization {
        margin: 0px 0px 127.5px 0px;
    }
    .csr_digitalization_container>div:nth-of-type(2) {
        width: 90%;
        position: relative;
    }
    .csr_digitalization_container p {
        width: 100%;
        margin-bottom: 25px;
        font-family: "Manrope-Medium";
        font-size: 16px;
        line-height: 1.63;
        color: var(--tertiary-heavy);
    }
    .csr_digitalization_container p:nth-last-of-type(1) {
        width: 90%;
    }
    .csr_digitalization_container h1 {
        font-size: 20px;
        line-height: 1.6;
        text-align: left;
    }
    .csr_digitalization_container p:nth-last-of-type(1) {
        margin-bottom: 43px;
    }
}