#parteners {
  margin: 120px 0px 100px 0px;
  width: 100vw;
  height: fit-content;
  font-family: "Manrope-Bold";
}

#parteners h1 {
  width: 90%;
  text-align: center;
  font-size: 48px;
  line-height: 1.2;
  color: var(--gray-700);
  font-family: "Manrope-Bold";
}

#parteners div:nth-of-type(1) {
  display: flex;
  justify-content: center;
}

#parteners .parteners {
  margin-top: 27px;
  display: flex;
  align-items: center;
  min-width: 100%;
  height: 150px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

#parteners .parteners::-webkit-scrollbar {
  display: none;
}

#parteners .parteners img:nth-of-type(1) {
  margin-left: 25%;
}

#parteners .parteners img:last-child {
  margin-right: 25%;
}

#parteners .parteners img {
  margin-left: 88px;
}

@media only screen and (max-width: 760px) {
  #parteners {
    margin: 0px 0px 60px 0px;
  }

  #parteners .parteners {
    margin-top: 5px;
  }

  #parteners h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 600px) {
  #parteners h1 {
    font-size: 26px;
  }

  #parteners .parteners img:nth-of-type(1) {
    margin-left: 10px;
  }

  #parteners .parteners img:last-child {
    margin-right: 10px;
  }
}