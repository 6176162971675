.language-selector .comp-select {
  border: 0;
  vertical-align: middle;
  width: 110px;
  text-transform: capitalize;
  font-family: "Manrope";
}

.language-selector .comp-select>div {
  margin-left: 0;
  background: transparent;
}

.language-selector .comp-select svg {
  height: 16px;
  margin-right: 5px;
  margin-bottom: 2px;
  max-width: 22px;
  vertical-align: middle;
  width: 100%;
}

.language-selector .comp-select svg:last-child {
  height: 14px;
  margin-left: 6px;
  margin-bottom: 2px;
  max-width: 11px;
  vertical-align: middle;
  width: 100%;
  stroke: #999;
}



.language-selector .comp-select .light {
  stroke: #fff;
  color: #fff;
}

.language-selector .comp-select .dark {
  stroke: #000;
  color: #999;
}



.language-selector .comp-select div[class$="-menu"] {
  font-size: 14px;
  max-width: 110px;
  background: #fff;
  color: #000;
}

.language-selector .comp-select div[class$="-indicatorContainer"],
.language-selector .comp-select div[class$="-ValueContainer"] {
  padding: 0;
}

.language-selector .comp-select div[class$="-indicatorContainer"],
.language-selector .comp-select div[class$="-IndicatorsContainer"] {
  display: none;
}