.parents_communication{
    position: relative;
    margin: 0px 0px 102px 0px;
}
.parents_communication_background{
    width: 1691px;
    margin: 0px 0px 0px 229px;
    padding: 81px 0px 69px 84px;
    box-sizing: border-box;
    background-color:var(--main-background);
}
.parents_communication img{
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    top:-100px;
    right: -118px;
}
.parents_communication h1{
    width:763px;
    margin-bottom: 15px;
    font-family: 'Manrope-Bold';
    font-size: 50px;
    line-height: 1.24;
    color: var(--dark-slate-blue);
}
.parents_communication h2{
    margin-bottom: 24px;
    font-family: 'Manrope-Bold';
    font-size: 34px;
    line-height: 1.24;
    color: var(--dark-slate-blue);
}
.parents_communication h3{
    width:763px;
    margin-bottom: 63px;
    font-family: 'Manrope-Regular';
    font-size: 20px;
    line-height: 1.3;
    color: var(--tertiary-heavy);
}
.parents_communication_wrapper p{
    font-family: 'Manrope-Medium';
    font-size: 16px;
    line-height: 1.63;
    color: var(--tertiary-heavy);
}
.parents_communication_wrapper {
    width:960px;
    display:grid;
    grid-template-columns: repeat(3,300px);
    justify-content:space-between;
    row-gap:20px;
}
.parents_communication_wrapper>div{
    display:flex;
}
.parents_communication_wrapper>div>p:nth-of-type(1){
    margin-right:10px;
}

@media only screen and (max-width:1780px){
    .parents_communication_background{
        margin: 0px 0px 0px 129px;
    }
}
@media only screen and (max-width:1600px){
    .parents_communication_background{
        margin: 0px 0px 0px 70px;
    }
}
@media only screen and (max-width:1530px){
    .parents_communication img{
        right: -218px;
    }
}
@media only screen and (max-width:1440px){
    .parents_communication img{
        right: -268px;
    }
}
@media only screen and (max-width:1400px){
    .parents_communication img{
        right: -308px;
    }
}
@media only screen and (max-width:1340px){
    .parents_communication_background{
        margin: 0px 0px 0px 0px;
    }
}
@media only screen and (max-width:1300px){
    .parents_communication{
        margin: 0px 0px 50px 0px;
    }
}
@media only screen and (max-width:1290px){
    .parents_communication_wrapper {
        width:640px;
        grid-template-columns: repeat(2,300px);
    }
}
@media only screen and (max-width:1130px){
    .parents_communication_wrapper {
        width:640px;
        grid-template-columns: repeat(2,300px);
    }
    .parents_communication_background{
        padding: 94px 0px 80px 49px;
    }
}
@media only screen and (max-width:999px){
    .parents_communication h1{
        width:500px;
        font-size: 34px;
    }
    .parents_communication h3{
        width:600px;
        margin-bottom: 63px;
    }
    .parents_communication_background{
        width: 100%;
    }
}
@media only screen and (max-width:910px){
    .parents_communication img{
        height:168px;
        top:-90px;
        right: 70px;
    }
}

@media only screen and (max-width:730px){
    .parents_communication h1{
        width:88.5%;
        margin-bottom: 21px;
        font-size: 26px;
        text-align: center;
        color: var(--tertiary-heavy);
    }
    .parents_communication h2{
        margin-bottom: 31px;
        font-size: 20px;
        line-height: 2.1;
        text-align: center;
    }
    .parents_communication h3{
        width:100%;
        margin-bottom: 49px;
        font-size: 16px;
        line-height: 1.5;
        color: var(--secondary-heavy);
    }
    .parents_communication_content{
        display:flex;
        flex-direction: column;
        align-items: center;
        width:90%;
    }
    .parents_communication_background{
        padding: 120px 0px 24px 0px;
        display:flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width:700px){
    .parents_communication_background{
        padding: 0px 0px 24px 0px !important;
    }
    .parents_communication_wrapper {
        width:auto;
        grid-template-columns: repeat(2,48%);
        gap:20px;
    }
    .parents_communication img{
        position:relative;
        height:168px;
        margin-top:0px;
        right:0;
    }
    .parents_communication_content{
        margin-top: -48px;
    }
}
@media only screen and (max-width:580px){
    .parents_communication_wrapper {
        grid-template-columns: repeat(1,100%);
    }
}