.packageChosen {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    min-height: calc(100vh - 198px);
    align-items: flex-start;
}
.packageChosen__flex{
    display:flex;
}
.packageChosen__wave {
    position: absolute;
    z-index: -1;
}

.packageChosen__hero {
    width: 390.8px;
    margin-left: 145px;
    margin-top: 85.8px;
}


.packageChosen__benefits{
    margin-left: 90px;
    margin-top: 172px;
    width:310px;
    display:flex;
    flex-direction: column;
    gap:20px;
}
.packageChosen__benefits h2{
    margin-bottom: 18px;
    font-family: "Manrope-Bold";
    font-size: 25px;
    line-height: normal;
    text-align: left;
 
    color: var(--tertiary-heavy);
}
.packageChosen__benefit{
    display:flex;
    gap:16.5px;
}
.packageChosen__benefit p{
    font-family: "Manrope";
    font-size: 18px;
    color: var(--tertiary-heavy);
    line-height: normal;
}
.packageChosen__benefit img{
    margin-top: 7px;
    width: 14.7px;
    height: 12.8px;
}

.packageChosen__box {
    box-sizing: border-box;
    margin-left: 280px;
    margin-top: 157px;
    min-height: 317px;
    width: 409px;
    padding: 28px 42px 44.4px 42px;
    border-radius: 15px;
    box-sizing: border-box;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    background-color: var(--tertiary-ultralight);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.packageChosen__box h1{
    margin-bottom: 8px;
    font-family:"Manrope-Bold";
    font-size: 34px;
    text-align: center;
    line-height: normal;
    color: var(--tertiary-heavy);
}
.packageChosen__box p {
    margin-bottom: 24.5px;
    font-family: "Manrope";
    font-size: 16px;
    text-align: center;
    line-height: normal;
    color: var(--tertiary-heavy);
}
.packageChosen__box span{
    margin: 10.5px 0px 12px 0px;
    font-family: "Manrope";
    font-size: 14px;
    text-align: center;
    line-height: normal;
    color: #9b9b9b;
}

.packageChosen__box button:nth-of-type(1){
    box-sizing: border-box;
    background-color: var(--pumpkin-orange);
    border: 1px solid var(--pumpkin-orange);
    border-radius: 24px ;
    padding: 9px 51.5px 9px 51px;
    cursor: pointer;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    color: var(--tertiary-ultralight);
    line-height: normal;
    transition: all .3s ease;
}

.packageChosen__box button:nth-of-type(1):hover{
    background-color: var(--action-accent);
    border: 1px solid var(--action-accent);
}

.packageChosen__box button:nth-of-type(2){
    box-sizing: border-box;
    background-color: var(--tertiary-ultralight);
    border: solid 1px #707070;
    border-radius: 24px ;
    padding: 9px 23.1px 9.6px 20px;
    cursor: pointer;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    color: var(--tertiary-heavy);
    line-height: normal;
    transition: all .3s ease;
}
.packageChosen__box button:nth-of-type(2):hover{
    background-color: #707070;
    color: var(--tertiary-ultralight);
}

@media only screen and (max-width:1750px){
    .packageChosen__box{
        margin-left: 180px;
    }
}
@media only screen and (max-width:1650px){
    .packageChosen__hero {
        margin-left: 100px;
    }
    .packageChosen__box{
        margin-left: 180px;
    }
}
@media only screen and (max-width:1550px){
    .packageChosen__wave {
        width:1700px;
    }
    .packageChosen__hero {
        margin-left: 80px;
    }
    .packageChosen__benefits{
        margin-left:60px;
    }
    .packageChosen__box{
        margin-left: 160px;
    }
}

@media only screen and (max-width:1460px){

    .packageChosen__box{
        margin-left: 80px;
    }
}
@media only screen and (max-width:1390px){
    .packageChosen__hero {
        margin-left: 40px;
    }
    .packageChosen__benefits{
        margin-left:50px;
    }
    .packageChosen__box{
        margin-left: 60px;
    }
}
@media only screen and (max-width:1300px){
    .packageChosen__wave {
        width:1324px;
    }
    .packageChosen__hero{
        width: 337.9px;
    }
    .packageChosen{
        flex-direction: column;
        align-items: center;
        gap:98.4px;
    }
    .packageChosen__hero {
        margin-top: 0px;
        margin-left: 0px;
    }
    .packageChosen__benefits{
        margin-top: 47.9px;
        margin-left:112px;
    }
    .packageChosen__box{
        margin-top: 82.8px;
        margin-left: 0px;
    }
    .packageChosen__flex{
        margin-bottom: 61.7px;
        order:2;
    }
}
@media only screen and (max-width:1100px){
    .packageChosen__wave {
        width:auto;
        height: 410px;
    }

}

@media only screen and (max-width:850px){
    .packageChosen__benefits{
        margin-left:60px;
    }
}
@media only screen and (max-width:750px){
    .packageChosen__hero {
        display:none;
    }
    .packageChosen__benefits{
        margin:0px;
    }
    .packageChosen__benefits h2{
        margin:0px;
    }
    .packageChosen{
        gap:35px;
    }
    .packageChosen__box{
        margin-top: 44.8px;
        margin-left: 0px;
    }
}

@media only screen and (max-width:650px){
    .packageChosen__wave {
        height: 236.1px;
    }
    .packageChosen__benefits{
        gap:12px;
        width:280px;
    }
    .packageChosen__benefits h2{
        font-size: 18px;
        margin-bottom: 5px;
        text-align: center;
    }
    .packageChosen__benefit{
        gap:14.5px;
    }
    .packageChosen__benefit p{
        font-size: 16px;
    }
    .packageChosen__benefit img {
        width: 14.7px;
        height: 12.8px;
        margin-top: 4px;
    }

    .packageChosen__box {
        margin-top: 36.6px;
        margin-left: 0px;
        width: 306.3px;
        min-height: 237.4px;
        padding: 20px 25px 33.7px 25px;
    }
    .packageChosen__box h1{
        font-size: 26px;
        margin-bottom: 5px;
    }
    .packageChosen__box p{
        font-size: 14px;
        margin-bottom: 13.5px;
    }
    .packageChosen__box span{
        font-size: 10px;
        margin: 7.5px 0px 9.5px 0px;
    }
    .packageChosen__box button:nth-of-type(1){
        padding: 5.8px 38.9px 7.2px 37.8px;
        font-size: 12px;
    }
    .packageChosen__box button:nth-of-type(2){
        padding: 6.5px 27.1px 6.5px 28px;
        font-size: 12px;
    }
}