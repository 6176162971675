#intro_private{
    position: relative;
    margin-top: 100px;
}
#intro_private img{
    position: absolute;
    z-index: -1;
    bottom: -60px;
    right:-170px;
}

#intro_private h1{
    margin-bottom: 70.7px;
}
#intro_private .intro_buttons_container{
    margin-bottom: 206.5px;
}

#intro_private .intro_buttons_container>div{
    display:flex;
    gap:20px;
    margin-bottom: 30px;
}

#intro_private .advantages{
    margin-bottom: 58px;
}

@media only screen and (max-width: 1900px) {
    #intro_private .intro_container {
        margin-left: 11.5%;
    }
}
@media only screen and (max-width: 1850px) {
    #intro_private .intro_container {
        margin-left: 9.5%;
    }
}
@media only screen and (max-width: 1790px) {
    #intro_private .intro_container {
        margin-left: 7.5%;
    }
}
@media only screen and (max-width: 1730px) {
    #intro_private .intro_container {
        margin-left: 5.5%;
    }
}
@media only screen and (max-width: 1690px) {
    #intro_private .intro_container {
        margin-left: 3.7%;
    }
}
@media only screen and (max-width: 1650px) {
    #intro_private img{
        height: 650px;
        top:-50px;
        right:-110px;
    }
}
@media only screen and (max-width: 1560px) {
    #intro_private img{
        height: 650px;
        top:-50px;
        right:-110px;
    }
    #intro_private .intro_container{
        width: 850px;
    }
}
@media only screen and (max-width: 1450px) {
    #intro_private img{
        height: 550px;
        top:-10px;
        right:-110px;
    }
}
@media only screen and (max-width: 1350px) {
    #intro_private .intro_container{
        width: 750px;
    }
    #intro_private .advantages {
        flex-direction: column;
    }
    #intro_private .advantages_box {
        margin-bottom: 24px;
        width: 100%;
    }
    #intro_private .advantages_box:nth-last-of-type(1) {
        margin-bottom: 0px;
    }
    #intro_private .advantages_box br{
        display:none;
    }
    #intro_private .advantages{
        margin-bottom: 38px;
    }
    #intro_private h1{
        margin-bottom: 40px;
    }
    #intro_private{
        position: relative;
        margin-top: 50px;
    }
    #intro_private .intro_buttons_container{
        margin-bottom: 106.5px;
    }
}
@media only screen and (max-width: 1250px) {
    
    #intro_private h1{
        font-size: 34px;
    }
    #intro_private .intro_container{
        width: 690px;
    }
}
@media only screen and (max-width: 1200px) {
    
    #intro_private .intro_container{
        width: 590px;
    }
}
@media only screen and (max-width: 1100px) {
    #intro_private img{
        height: 450px;
        top:15px;
    }
}
@media only screen and (max-width: 1030px) {
    #intro_private .intro_container{
        width: 490px;
    }
}
@media only screen and (max-width: 900px) {
    #intro_private{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }
    #intro_private img{
        height: 450px;
        position: relative;
        top:0px;
        left:0px;
        margin-bottom: 30px;
    }
    #intro_private .intro_container{
        margin:0px 0px 0px 0px;
        width:90%;
    }
    #intro_private h1{
        text-align: center;
    }
    #intro_private .intro_buttons_container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 45px;
    }
    #intro_private .intro_buttons_container>div{
        display:flex;
        gap:10px;
        margin-bottom: 15px;
    }
    
    #intro_private .advantages{
        margin-bottom: 47px;
    }
    #intro_private .advantages_box{
        width: 80%;
        margin-bottom: 47px;
    }
    #intro_private .advantages_box:nth-of-type(2)>h2 { 
        text-align: right;
    }
    #intro_private .advantages_box:nth-of-type(2)>p {
        display: block;
        text-align: right;
    }
    #intro_private .advantages_box:nth-of-type(2) {
        margin-left: 20%;
    }
}
@media only screen and (max-width: 480px) {
    #intro_private .advantages_box{
        width: 87.25%;
    }
    #intro_private .advantages_box:nth-of-type(2) {
        margin-left: 12.75%;
    }
    #intro_private img{
        width: 355px !important;
        position: relative;
        margin:-40px 0px 0px 0px;
    }
}
