#parentsID .support_contact {
    margin-top: 0px !important;
    margin-bottom: 135px !important;
}
#parentsID .digitalization>h1,
#studentsID .digitalization>h1{
    width: 892px;
    margin-bottom: 80px;
    font-family:'Manrope-Bold';
    font-size: 34px;
    line-height: 1.2;
    text-align: center;
    color: var(--dark-slate-blue);
}
#parentsID #feedback,
#studentsID #feedback {
    margin-bottom: 68px;
}
#parentsID #parteners{
    margin:0px 0px 196.1px 0px;
}
#parentsID #adservioMobile,
#studentsID .digitalization {
    margin-bottom: 161px;
}
#parentsID .digitalization,
#studentsID .digitalization{
    margin: 0px 0px 229px 0px !important;
}

#studentsID .benefits_content{
margin-bottom: 76px ;
}
@media only screen and (max-width:1350px){
    #parentsID #parteners{
        margin:0px 0px 158.7px 0px;
    }
}
@media only screen and (max-width:1200px){
    #parentsID .digitalization,
    #studentsID .digitalization{
        margin: 0px 0px 304px 0px !important;
    }
    #parentsID .support_contact{
        margin-bottom: 70px !important;
    }
    #parentsID .digitalization>h1,
    #studentsID .digitalization>h1{
        margin-bottom: 60px;
    }
    #studentsID .benefits_content{
        margin-bottom: 40px ;
    }
}
@media only screen and (max-width:1100px){
    #parentsID #adservioMobile,
    #studentsID .digitalization {
        margin-bottom: 110px;
    }
}
@media only screen and (max-width:999px){
    #parentsID #parteners{
        margin:0px 0px 70px 0px;
    }
    #parentsID .digitalization>h1{
        width: 700px;
    }
    #studentsID .digitalization>h1{
        width:500px;
    }
}
@media only screen and (max-width:800px){
    #parentsID .digitalization,
    #studentsID .digitalization{
        margin-bottom: 245.6px !important;
    }
    #parentsID .digitalization>h1,
    #studentsID .digitalization>h1{
        width:90%;
        margin-bottom: 41px;
        font-size: 26px;
        color: var(--tertiary-heavy);
    }
}
@media only screen and (max-width:650px){
    #parentsID .support_contact{
        margin-bottom: 50px !important;
    }
}

@media only screen and (max-width:600px){
    #parentsID #adservioMobile,
    #studentsID .digitalization {
        margin-bottom: 46.2px;
    }
}
