.admission_section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: white;
  margin-bottom: 185px;
  gap: 30px;
  padding: 0px 40px;
}

.admission_section_illustration {
  height: 680px;
}

.admission_card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: 805px;
  z-index: 1;
  max-width: 805px;
  padding: 45px 55px;
  border-radius: 16px;
  background: transparent linear-gradient(to left, #7A5AF8 0%, #00A4D0 100%);
}

.admission_card_top {
  display: flex;
  align-items: flex-end;
  gap: 25px;
  margin-bottom: 40px;
}

.adservio_logo {
  height: 53px;
}

.admission_card_badge {
  font-family: 'Manrope-Bold';
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 3px 15px;
  color: #417DE5;
  background-color: white;
  border-radius: 32px;
  font-size: 24px;
  line-height: 1.4;
  font-weight: bold;
}

.admission_card_title {
  font-family: 'Manrope-Bold';
  font-size: 34px;
  line-height: 1.3;
  margin-bottom: 16px;
}

.admission_card_description {
  font-family: 'Manrope-Regular';
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 32px;
}

@media only screen and (max-width: 1800px) {
  .admission_section_illustration {
    margin-left: -200px;
  }
}

@media only screen and (max-width: 1600px) {
  .admission_section_illustration {
    margin-left: -400px;
  }
}

@media only screen and (max-width: 1400px) {
  .admission_section_illustration {
    margin-left: -500px;
  }
}

@media only screen and (max-width: 1300px) {
  .admission_section {
    padding: 0px 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .admission_section {
    margin-bottom: 80px;
  }

  .admission_card {
    margin-left: -100px;
  }
}


@media only screen and (max-width: 1000px) {
  .admission_section {
    flex-direction: column;
  }

  .admission_section_illustration {
    margin-left: 0px;
    height: 350px;
  }

  .admission_card {
    margin-left: 0px;
    max-width: max-content;
  }

  .admission_card_title {
    font-size: 26px;
  }

  .admission_card_description {
    font-size: 16px;
  }
}

@media only screen and (max-width: 800px) {
  .admission_card {
    padding: 25px 30px;
  }

  .admission_card_top {
    gap: 15px;
  }

  .adservio_logo {
    height: 40px;
  }

  .admission_card_badge {
    padding: 3px 12px;
    border-radius: 26px;
    font-size: 18px;
    line-height: 1.4;
  }
}

@media only screen and (max-width: 700px) {
  .admission_section {
    padding: 0px;
  }

  .admission_card {
    border-radius: 0px;
  }

  .admission_card button {
    align-self: center;
  }
}

@media only screen and (max-width: 450px) {

  .admission_section_illustration {
    width: 100%;
    height: auto;
  }
}